import { Component, OnInit } from '@angular/core';
import {ILeader} from "../../shared/models/leader.model";

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  leaders: ILeader[] = [
    { img: '1.png', name: 'Thomas Jefferson', experience: '3350' },
    { img: '2.png', name: 'Thomas Jefferson', experience: '1220' },
    { img: '3.png', name: 'Thomas Jefferson', experience: '900' },
    { img: '4.png', name: 'Thomas Jefferson', experience: '550' },
    { img: '5.png', name: 'Thomas Jefferson', experience: '350' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
