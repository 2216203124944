import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Router} from "@angular/router";
import {IUser} from "../../shared/models/user.model";
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('onboardingIframe') onboardingIframe!: ElementRef;

  user!: IUser;
  body = document.querySelector('body')!;
  constructor(
      public afs: AngularFirestore,
      private router: Router,
      private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.body.classList.add('black');
    this.authService.getUser().subscribe(u => {
      this.user = u;
    })
  }

  ngAfterViewInit() {
   setTimeout(() => {
     if (this.onboardingIframe && this.onboardingIframe.nativeElement) {
       const iframeWindow = this.onboardingIframe.nativeElement.contentWindow;

       window.addEventListener('message', event => {
         if (event.source === iframeWindow) {
           const receivedData = event.data;
           if (receivedData.isCompleted) {
             this.afs.collection('users')
                 .doc(this.user.uid)
                 .update({isOnboardingCompleted: true})
                 .then(() => {
                   this.router.navigate(['home']).then();
                 })
           }
         }
       });
     }
   }, 2000);
 }

 ngOnDestroy() {
   this.body.classList.remove('black');
 }


}
