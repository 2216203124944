import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @Output() fileDropped = new EventEmitter;
  @HostBinding('class.fileover') fileover!: boolean;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileover = true;
  }
// Dragleave listener
  @HostListener ('dragleave', ['$event ']) onDragLeave(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileover = false;
  }
// Drop listener
  @HostListener ('drop', ['$event ']) public ondrop(evt: any) {
          evt.preventDefault();
          evt.stopPropagation();
          const files = evt.dataTransfer.files;
          this.fileover = false;
          if (files.length > 0) {
              this.fileDropped.emit(files)
          }
      }

  constructor() { }

}
