import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {INavigationItem} from '../../../shared/models/navigation.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AuthService} from "../../../shared/services/auth.service";
import {IUser} from "../../../shared/models/user.model";
import {ReplaySubject, takeUntil} from "rxjs";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isEditorAvailable!: boolean;
  @Output() onOpenPricingModal = new EventEmitter();

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isLoading = true;
  navigation: INavigationItem[] = [
    {title: 'Home', view: 'home', img: 'home', isVisible: true},
    {title: 'Story Editor', view: 'editor', img: 'story-editor', isVisible: true},
    {title: 'Imagine Wizard', view: 'wizard', img: 'imagine-wizard', isVisible: true},
    {title: 'Projects', view: 'projects', img: 'projects', isVisible: false},
    {title: 'Achievements', view: 'achievements', img: 'achievements', isVisible: true},
    {title: 'Leaderboard', view: 'leaderboard', img: 'leaderboard', isVisible: true},
    {title: 'Settings', view: 'settings', img: 'settings', isVisible: true},
    {title: 'Pricing', view: 'pricing', img: 'pricing', isVisible: true},
    {title: 'Billing', view: 'billing', img: 'billing', isVisible: true},
    {title: 'Contact us', view: 'help', img: 'contact', isVisible: true}
  ];

  activeView = 'home';
  user!: IUser;

  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute : ActivatedRoute
  ) {
    this.activatedRoute.url
        .pipe(takeUntil(this.destroyed$))
        .subscribe(url =>{
      this.activeView = url[0].path;
    });
  }

  ngOnInit(): void {
    this.authService.getUser()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
      this.user = res;
    })
    this.authService.features$.subscribe(resp => {
      this.onUpdateAvailability(resp)
    })
  }

  onUpdateAvailability(features: any) {
    if(features) {
      Object.keys(features).forEach(f => {
        if (f !== 'isLessonFullscreen' && f !== 'isFirebase' && f !== 'isQuestionBased') {
          // @ts-ignore
          const v = this.navigation.find(i => i.view === f);
          if(v) { v.isVisible = features[f]}
        }
      })
    }
    this.isLoading = false;
  }

  onChangeView(view: string) {
    if(view === 'help') {
      window.open('https://homi.school/contact/', "_blank");
    } else if(view === 'how-to-use') {
      this.router.navigate(['/onboarding']).then()
    } else if (view === 'editor' || view === 'wizard') {
      this.isEditorAvailable
          ? this.router.navigate(['/editor'], { queryParams: {openAiOpened: view === 'wizard'} }).then()
          : this.onOpenPricingModal.emit();
    } else {
      this.router.navigate(['/' + view]).then()
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
