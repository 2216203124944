import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit, Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {INavigationItem} from "../../shared/models/navigation.model";
import {IUser} from "../../shared/models/user.model";
import {ReplaySubject, Subject, take, takeUntil, combineLatest} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";
import {DOCUMENT} from "@angular/common";
import {fabric} from 'fabric';
import {NgxFileDropEntry} from "ngx-file-drop";
import {ImageApiService} from "../../shared/services/image-api.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {ActivatedRoute} from "@angular/router";
import {IProject} from "../../shared/models/projects.model";
import {OpenAiService} from "../../shared/services/open-ai.service";
import {environment} from "../../../environments/environment.prod";
import {S3ServiceService} from "../../shared/services/s3-service.service";
import * as dayjs from "dayjs";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";
import {document} from "ngx-bootstrap/utils";
import {log} from "fabric/fabric-impl";

interface IImage {
  preview: string;
  image: string;
  info: any;
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('htmlCanvas') htmlCanvas!: ElementRef;
  @ViewChild('editorSidebarRight') editorSidebarRight!: ElementRef;
  @ViewChild('tabsContent') tabsContent!: ElementRef;
  @ViewChild('saveProjectModal') saveProjectModal!: TemplateRef<any>;
  @ViewChild('canvasWrapper', {static: true}) canvasWrapper!: ElementRef;
  @ViewChild('deletePageModal') deletePageModal!: TemplateRef<any>;
  @ViewChild('conversationDeletionConfirm') conversationDeletionConfirm!: TemplateRef<any>;
  @ViewChild('leavePageModal') leavePageModal!: TemplateRef<any>;
  @ViewChild('sidebarWrapper') sidebarWrapper!: ElementRef;
  @ViewChild('askHomi') askHomi!: TemplateRef<any>;

  modalRef?: BsModalRef;
  public canvas!: fabric.Canvas;
  public props = {
    canvasFill: '#797979',
    canvasImage: '',
    id: null,
    opacity: 100,
    fill: '#000000',
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };

  snippetHeading = '';
  snippetText = '';

  public url: string | ArrayBuffer = '';
  public size: any = {
    width: 1920,
    height: 1080
  };

  public initialDims = {
    width: window.innerWidth - 120 - 350,
    height: window.innerHeight - 95 - 50
  };

  maxZoom = 1;
  minZoom = 1;

  public json: any;
  public textEditor = false;
  private imageEditor = false;
  public figureEditor = false;
  public selected: any;

  public files: NgxFileDropEntry[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isLoading = true;
  AIMessagesLoader = false;
  isFullScreen = false;
  activeView = 'pages';

  /*quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{header: 1}, {header: 2}],
        ['link'],
      ],
    },
  };*/
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'header': [1, 2, 3, 4] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false
    }
  };

  editorStyle = {
    height: '150px',
  };

  tabs: INavigationItem[] = [
    {title: 'Pages', view: 'pages', img: 'pages', isVisible: true},
    {title: 'Graphics Elements', view: 'graphics', img: 'graphic-elements', isVisible: true},
    {title: 'Background', view: 'backgrounds', img: 'background', isVisible: true},
    /*{title: 'Templates', view: 'templates', img: 'templates', isVisible: false},*/
    {title: 'Uploads', view: 'uploads', img: 'uploads', isVisible: true},
    {title: 'Text', view: 'text', img: 'text', isVisible: true}
  ];
  user!: IUser;
  elem: any;

  //History state
   //index = 0;
   pageIndexes: number[] = [0]
   historyStack: any[][] = [[]];
   skipObjectEvent = false;
   connectedItems: any[][] = [];
   jsonCanvasStates: any[][] = [[]];
   pageIndexToDelete!: number;

   searchContent = {
     graphics: '',
     backgrounds: '',
     templates: '',
   }
   isChangesWasMade = false;
   savedPages: any[] = ['Page #1'];
   pageVisibleId = 0;

   searchPagesCounter: any = {
     graphics: 1,
     backgrounds: 1,
     templates: 1
   }

   queryOptions = {
     q: '',
     orientation: 'all',
     image_type: 'all',
     category: '',
     page: 1
   }

   editorData: {
    graphics: IImage[];
    backgrounds: IImage[];
    templates: IImage[];
    uploads: {
      graphics: IImage[]
      backgrounds: IImage[]
      templates: IImage[]
    };
  } = {
    graphics: [],
    backgrounds: [],
    templates: [],
    uploads: {
      graphics: [],
      backgrounds: [],
      templates: [],
    }
  };
   selectedUploadCategory = 'backgrounds';
   randomBackgroundsDisplaying = true;
   randomGraphicsDisplaying = true;

  pageForEditIndex!: number;
  pageFieldToEdit: any;
  newPageName = '';

  projectName = '';
  projectSubtitle = '';
  projectImage: any;

  isBackGroundLocked = false;
  isSnippetInFocus = false;

  project!: IProject;
  zoom = 1;

  displayedInfo = {
    tags: [],
    likes: 0
  }

  openAiRequestText = '';
  storedOpenAiRequestText: any = '';
  openAiResponseData = '';
  openAiModalCollapsed = false;
  scrollAiPosition = 0;

  onExitConfirmation = new Subject();
  routerSubscription: any;

  conversationsMessages: any[] = [];
  GPTConversations: any[] = [];
  currentConversationID = '';
  currentConversation?: any;
  conversationToDeleteId!: string;
  storyStyle = '';

  //inputs
  textInputs: any[] = [];
  textAreas: any[] = [];

  savingProjectInProgress = false;

  beforeUnloadHandler = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    return e.returnValue = '';
  }

  get isImageBackgroundLocked() {
    const selection = this.canvas.getActiveObject();
    if (!selection) {
      return this.isBackGroundLocked;
    } else {
      // @ts-ignore
      if (selection.isBackroundImage) {
        return this.isBackGroundLocked;
      } else if (selection.type === 'image') {
        return this.isObjectLocked(selection);
      } else {
        return true;
      }
    }
  }

  constructor(
      @Inject(DOCUMENT) private document: any,
      public authService: AuthService,
      public afs: AngularFirestore,
      private freepikService: ImageApiService,
      private modalService: BsModalService,
      private fireStorage: AngularFireStorage,
      private route: ActivatedRoute,
      private openAiService: OpenAiService,
      private cdr: ChangeDetectorRef,
      private s3Service: S3ServiceService,
      private http: HttpClient,
      private sanitizer: DomSanitizer,
      private renderer: Renderer2
  ) {
  }

  async ngOnInit() {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.borderColor = '#1f305e';
    fabric.Object.prototype.cornerColor = '#1f305e';
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStrokeColor = '#1f305e';
    fabric.Object.prototype.controls['mtr'].cursorStyle = 'grab';
    fabric.Object.prototype.controls['mtr'].offsetY = -30;

    this.createCustomRotateControl();
    this.addObjectControlOnLock();
    this.addObjectControlOnUnLock();
    this.addObjectControlOnDelete()
    this.addObjectsControlConnect();
    this.addObjectsControlDisconnect();
    this.addObjectControlOnGroup();
    this.addObjectControlOnUngroup();

    this.elem = document.documentElement;
    this.authService.getUser()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res.uid) {
            this.user = res;
            if (this.user.uploadedFiles) {
              this.editorData.uploads = this.user.uploadedFiles;
            }
            //this.isLoading = false;
            this.afs.collection('users').doc(this.user.uid)
                .collection('GPTConversations').valueChanges().pipe(takeUntil(this.destroyed$))
                .subscribe((resp: any) => {
                  const conversationsWithTitle = resp.filter((obj: any) => obj.title);
                  const conversationsWithoutTitle = resp.filter((obj: any) => !obj.title).sort((a: any, b: any) => a.chat[1].detail.created < b.chat[1].detail.created ? 1 : -1);

                  this.GPTConversations = conversationsWithoutTitle.concat(conversationsWithTitle);
                })
          }
        });

    this.onSelectActiveSection(this.activeView)

    this.route.queryParams.subscribe((res: any) => {
      if (res && res.openAiOpened === 'true') {
        setTimeout(() => {
          this.onOpenAskHomiModal();
        }, 0)
      }
    })

    const randomImages = await this.freepikService.getRandomHomiImages();
    this.editorData.backgrounds = randomImages.backgrounds;
    this.editorData.graphics = randomImages.graphics;
  }

  ngAfterViewInit() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    this.canvasWrapper!.nativeElement.classList.add('canvas-moved');
    this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
      backgroundColor: '#c0c5e1',
      hoverCursor: 'pointer',
      selection: true,
      //selectionBorderColor: '#1f305e',
      preserveObjectStacking: true,
      selectionColor: 'rgba(31, 48, 94, 0.2)',
      selectionLineWidth: 1,
      isDrawingMode: true,
      width: this.size.width,
      height: this.size.height,
      perPixelTargetFind: true, //TODO: remove if select per pixel boundaries
      controlsAboveOverlay: true
    });
    //this.initialCanvasZooming();

    this.route.queryParams.subscribe(params => {
      if (params && params['id']) {
        this.afs.collection('projects')
            .doc(params['id'])
            .get()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((resp: any) => {
              this.project = resp.data();
              if (!this.project) {
                return;
              }
              //console.log(this.project)
              this.historyStack = [];
              this.project.state.forEach((state: any, index: number) => {
                this.skipObjectEvent = true;
                this.historyStack.push([state]);

                this.connectedItems = JSON.parse(this.project.snippets);

                this.pageIndexes[index] = 0;

                this.savedPages[index] = this.project.pageNames && this.project.pageNames[index]
                   ? this.project.pageNames[index]
                   : `Page #${index+1}`;
              });
              // Resolving CANVAS CORS
              this.historyStack[0][0].objects.forEach((item: any) => {
                if (item.src && !item.src.includes('https://satoshi-cors.herokuapp.com') && !item.src.includes('base64')) {
                  item.src = `https://satoshi-cors.herokuapp.com/${item.src}`
                }
              })

              this.canvas.loadFromJSON(this.historyStack[0][0], () => {
                console.log('Existing project opened')
                this.initialCanvasZooming();
                this.skipObjectEvent = false;
              })
            })
      } else {
        this.initialCanvasZooming();
      }
    })

    //this.initialCanvasZooming();

    this.canvas.isDrawingMode = false; //TODO to true
    this.saveCanvasState();

    this.canvas.on('object:selected', (e) => {
      const selectedObject = e.target;
      this.selected = selectedObject;
      selectedObject!.hasRotatingPoint = true;
      selectedObject!.transparentCorners = false;
      selectedObject!.cornerColor = 'rgba(255, 87, 34, 0.7)';

      this.resetPanels();

      if (selectedObject!.type !== 'group' && selectedObject) {

        this.getId();
        this.getOpacity();

        switch (selectedObject.type) {
          case 'rect':
          case 'circle':
          case 'triangle':
            this.figureEditor = true;
            this.getFill();
            break;
          case 'i-text':
            this.textEditor = true;
            this.getLineHeight();
            this.getCharSpacing();
            this.getBold();
            this.getFill();
            this.getTextDecoration();
            this.getTextAlign();
            this.getFontFamily();
            break;
          case 'image':
            break;
        }
      }
    });

    this.canvas.on('object:added', (e) => {
      // @ts-ignore
      if (!e.target!.id) {e.target!.set('id', this.randomId())}
      if (e.target!.type === 'rect') {
        fabric.Object.prototype.controls['lockObject'].setVisibility(false);
        fabric.Object.prototype.controls['unlockObject'].setVisibility(false);
        fabric.Object.prototype.controls['deleteControl'].setVisibility(false);
      }
      // @ts-ignore
      if (e.target.isBackgroundImage && !this.skipObjectEvent) {
        this.lockBackground();
      }

      if (!this.skipObjectEvent) {
        console.log('added');
        this.saveCanvasState();
        this.isLoading = false;
      }
    });

    this.canvas.on('object:removed', (e) => {
      // @ts-ignore
      if (!this.skipObjectEvent) {
        // @ts-ignore
        const deletedItemId = e.target.id;
        console.log('removed');

        const cell = this.connectedItems.findIndex(c => c.includes(deletedItemId));
        if (cell !== -1) {
          const deletedIndex = this.connectedItems[cell].findIndex(el => el === deletedItemId);
          this.connectedItems[cell].splice(deletedIndex, 1);

          if (this.connectedItems[cell].length <= 1) {
            this.connectedItems.splice(cell, 1);
          }
          if (this.connectedItems[cell] && this.connectedItems[cell].every(item => this.isSnippetObject(item))) {
            this.connectedItems.splice(cell, 1);
          }
          if (this.connectedItems[cell] && this.connectedItems[cell].every(item => !this.isSnippetObject(item))) {
            this.connectedItems.splice(cell, 1);
          }
        }

        this.saveCanvasState();
      }
    });

    this.canvas.on('object:modified', (e) => {
      if (!this.skipObjectEvent) {
        console.log('modified');
        this.saveCanvasState();
      }
    });

    this.canvas.on('mouse:up', (e) => {
      if (this.canvas.getActiveObjects().length > 1) {
        fabric.Object.prototype.controls['ungroupObjects'].setVisibility(false);
        fabric.Object.prototype.controls['groupObjects'].setVisibility(true);
      }
      // @ts-ignore
      const selectedItemsIds = this.canvas.getActiveObjects().map(obj => obj.id);
      const isSelectedItemsConnected = this.connectedItems.some(currentArray => {
        return currentArray.length === selectedItemsIds.length && currentArray.every((element) => {
          return selectedItemsIds.includes(element);
        });
      });
      const activeObjects = this.canvas.getActiveObjects();

      // @ts-ignore
      if (e.e.shiftKey && (!!activeObjects.find(item => this.isObjectInConnection(item)) || activeObjects.some(item => item.type === 'group' && item._objects.some(i => this.isObjectInConnection(i)))) && !isSelectedItemsConnected) {
        fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);
        fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
      } else if (e.e.shiftKey && !!activeObjects.find(item => this.isObjectInConnection(item)) && isSelectedItemsConnected) {
        fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);
        fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
      } else if (e.e.shiftKey && activeObjects.find(item => this.isSnippetObject(item)) && !isSelectedItemsConnected) {
        fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);
        fabric.Object.prototype.controls['connectObjects'].setVisibility(true);
      } else if (e.e.shiftKey && activeObjects.find(item => this.isSnippetObject(item)) && isSelectedItemsConnected) {
        fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);
        fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
      }
      this.canvas.renderAll();
    });

    this.canvas.on('selection:created', (e: any) => {
      //this.onResetControlsPosition();
      const activeObject = this.canvas.getActiveObject()!;

      if (!fabric.Object.prototype.controls['deleteControl'].visible) {
        fabric.Object.prototype.controls['deleteControl'].setVisibility(true);
      }

      this.isObjectLocked(activeObject) ? (
          fabric.Object.prototype.controls['lockObject'].setVisibility(false),
          fabric.Object.prototype.controls['unlockObject'].setVisibility(true)
      ) : (
          fabric.Object.prototype.controls['lockObject'].setVisibility(true),
          fabric.Object.prototype.controls['unlockObject'].setVisibility(false)
      );

      // @ts-ignore
      if (activeObject!.type === 'image' && !activeObject!.isBackgroundImage) {
        activeObject!.on('mousedblclick', (e: any) => {
          //TODO: clipp logic is here
          /*this.clipImage(e.target, activeObject);
          activeObject!.selectable = false;
          activeObject!.off('mousedblclick');*/
        })

        if (activeObject.clipPath) {
          const rect = new fabric.Rect({
            left: activeObject.clipPath.left,
            top: activeObject.clipPath.top,
            width: activeObject.clipPath.width,
            height: activeObject.clipPath.height,
            angle: activeObject.clipPath.angle,
            absolutePositioned: true,
            originX: 'left',
            originY: 'top',
          });
          rect.setCoords();
          //this.onSetupControlsOnImageWithClipPath(activeObject, rect);
        }

        fabric.Object.prototype.controls['disconnectObjects']?.setVisibility(false);
        fabric.Object.prototype.controls['connectObjects']?.setVisibility(false);
      }

      if (this.isSnippetObject(activeObject)) {
        this.isSnippetInFocus = true;
        fabric.Object.prototype.controls['disconnectObjects']?.setVisibility(false);
        fabric.Object.prototype.controls['connectObjects']?.setVisibility(false);

        // @ts-ignore
        this.snippetHeading = activeObject._objects[1].text;
        // @ts-ignore
        this.snippetText = activeObject._objects[2].text;
        this.activeView = 'text';
      }

      if (activeObject!.type === 'activeSelection') {
        // @ts-ignore
        const selectedItemsIds = this.canvas.getActiveObjects().map(obj => obj.id);
        const isSelectedItemsConnected = this.connectedItems.some(currentArray => {
          return currentArray.length === selectedItemsIds.length && currentArray.every((element, i) => {
            return selectedItemsIds.includes(element);
          });
        });

        const activeObjects = this.canvas.getActiveObjects();

        // @ts-ignore
        if ((!!activeObjects.find(item => this.isObjectInConnection(item)) || activeObjects.some(item => item.type === 'group' && item._objects.some(i => this.isObjectInConnection(i)))) && !isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
        } else if (!!activeObjects.find(item => this.isObjectInConnection(item)) && isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
        } else if (activeObjects.find(item => this.isSnippetObject(item)) && !isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(true);
        } else if (activeObjects.find(item => this.isSnippetObject(item)) && isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
        }

        fabric.Object.prototype.controls['ungroupObjects'].setVisibility(false);
        fabric.Object.prototype.controls['groupObjects'].setVisibility(true);

        this.canvas.renderAll();
      } else if (activeObject!.type === 'group' && !this.isSnippetObject(activeObject)) {
        // @ts-ignore
        const selectedItemsIds = activeObject!._objects.map(obj => {
          return obj.id;
        });
        const isSelectedItemsConnected = this.connectedItems.some(currentArray => {
          return currentArray.length === selectedItemsIds.length && currentArray.every((element, i) => {
            return selectedItemsIds.includes(element);
          });
        });

        // @ts-ignore
        if (!!this.canvas.getActiveObject()!._objects.find(item => this.isObjectInConnection(item)) && !isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
          // @ts-ignore
        } else if (!!this.canvas.getActiveObject()!._objects.find(item => this.isObjectInConnection(item)) && isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
          // @ts-ignore
        } else if (this.canvas.getActiveObject()!._objects.find(item => this.isSnippetObject(item)) && !isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(true);
        //@ts-ignore
        } else if (this.canvas.getActiveObject()!._objects.find(item => this.isSnippetObject(item)) && isSelectedItemsConnected) {
          fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);
          fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
        }

        fabric.Object.prototype.controls['groupObjects'].setVisibility(false);
        fabric.Object.prototype.controls['ungroupObjects'].setVisibility(true);
      } else {
        fabric.Object.prototype.controls['groupObjects']?.setVisibility(false);
        fabric.Object.prototype.controls['ungroupObjects']?.setVisibility(false);
      }

      const keyupHandler = (event: KeyboardEvent) => {
        const activeObject = this.canvas.getActiveObject() as fabric.Object;
        const activeElement = document.activeElement;
        this.textInputs = Array.from(document.querySelectorAll('input[type="text"]'));
        this.textAreas = Array.from(document.querySelectorAll('textarea'));

        if ((event.key === 'Backspace'
            && !this.textInputs.includes(activeElement)
            && !this.textAreas.includes(activeElement)
            && !(activeObject as fabric.IText)?.isEditing)
            && !this.isObjectLocked(activeObject)) {
          this.removeSelected();
          document.removeEventListener('keyup', keyupHandler);
        }
      }
      document.addEventListener('keyup', keyupHandler);

      this.selected = this.canvas.getActiveObjects();
      if (this.selected.length) {
        this.isChangesWasMade = true;
      }
      this.canvas.renderAll();
    });

    this.canvas.on('selection:updated', () => {
      const activeObject = this.canvas.getActiveObject()!;
      if (this.selected.length) {
        this.isChangesWasMade = true;
      }
      if (this.isSnippetObject(activeObject)) {
        this.isSnippetInFocus = true;
        // @ts-ignore
        this.snippetHeading = activeObject._objects[1].text;
        // @ts-ignore
        this.snippetText = activeObject._objects[2].text;
        this.activeView = 'text';
      } else if (activeObject.type === 'group') {
        fabric.Object.prototype.controls['ungroupObjects']?.setVisibility(true);
      } else {
        this.snippetHeading = '';
        this.snippetText = '';
        this.isSnippetInFocus = false;
      }

      fabric.Object.prototype.controls['groupObjects']?.setVisibility(false);
      fabric.Object.prototype.controls['ungroupObjects']?.setVisibility(false);
      fabric.Object.prototype.controls['connectObjects']?.setVisibility(false);
      fabric.Object.prototype.controls['disconnectObjects']?.setVisibility(false);
    })

    this.canvas.on('selection:cleared', (e: any) => {
      fabric.Object.prototype.controls['groupObjects']?.setVisibility(false);
      fabric.Object.prototype.controls['ungroupObjects']?.setVisibility(false);
      fabric.Object.prototype.controls['connectObjects']?.setVisibility(false);
      fabric.Object.prototype.controls['disconnectObjects']?.setVisibility(false);

      if (this.skipObjectEvent || !e.target) {
        this.snippetHeading = '';
        this.snippetText = '';
      }
      this.isSnippetInFocus = false;
      /*setTimeout(() => {
        // @ts-ignore
        this.snippetText = this.canvas.getActiveObject()?._objects[2]?.text ? this.canvas.getActiveObject()?._objects[2]?.text : '';
        // @ts-ignore
        this.snippetHeading = this.canvas.getActiveObject()?._objects[1]?.text ? this.canvas.getActiveObject()?._objects[1]?.text : '';
      },50)*/

      this.selected = null;
      this.resetPanels();
      //this.onResetControlsPosition();
    });

    document.addEventListener("keydown", this.keydownHandlerBinded);

    /*document.addEventListener('keyup', (event: KeyboardEvent) => {
      // @ts-ignore
      if (event.key === 'Enter' && document.querySelector('input[type="text"]') === document.activeElement && this.searchContent[this.activeView]?.length > 0) {
        console.log('CLICK')
        this.onSearchContentByText();
      }
    });*/

    //  ZOOM for mobile (need adaptation for gesture)
    /*this.canvas.on('mouse:wheel', (opt) => {
      let delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 2) zoom = 2;
      if (zoom < 0.2) zoom = 0.2;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });*/
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    document.removeEventListener("keydown", this.keydownHandlerBinded);
    /*const storageRef = this.fireStorage.ref(`/tempData/${this.authService.user$.value.uid}`);
    storageRef.listAll().subscribe(result => {
      result.items.forEach(fireRef => {
        fireRef.delete();
      })
    })*/
    //this.routerSubscription.unsubscribe();
    this.openAiService.subject = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  keyDownHandler(event: KeyboardEvent) {
    const isWindows = navigator.userAgent.indexOf("Win") > -1;
    const isMacOS = navigator.userAgent.indexOf("Mac") > -1;

    if ((isWindows && event.ctrlKey && event.key === "z") || (isMacOS && event.metaKey && event.key === "z")) {
      this.undo();
    }

    if ((isWindows && event.ctrlKey && event.key === "y") || (isMacOS && event.metaKey && event.key === "y")) {
      event.preventDefault();
      this.redo();
    }

    if ((isWindows && event.ctrlKey && event.key === "s") || (isMacOS && event.metaKey && event.key === "s")) {
      event.preventDefault();
      if (!this.savingProjectInProgress) {
        this.savingProjectInProgress = true;
        this.onOpenModalForSave();
      }
    }

    if ((isWindows && event.ctrlKey && !event.shiftKey && event.key === "g") || (isMacOS && event.metaKey && !event.shiftKey && event.key === "g")) {
      event.preventDefault();
      if (this.canvas.getActiveObject()!.type === 'activeSelection') {
        this.groupingObjects();
      }
    }

    if ((isWindows && event.ctrlKey && event.shiftKey && event.key === "g") || (isMacOS && event.metaKey && event.shiftKey && event.key === "g")) {
      event.preventDefault();
      if (this.canvas.getActiveObject()!.type === 'group') {
        this.ungroupingObjects();
      }
    }

    if ((isWindows && event.ctrlKey && event.key === "a") || (isMacOS && event.metaKey && event.key === "a")) {
      event.preventDefault();
      this.selectAllObjects();
    }
  }

  keydownHandlerBinded = this.keyDownHandler.bind(this)

  isSnippetObject(object: any) {
    return object._objects?.length === 3
        && object?.type === 'group'
        && object._objects[0]?.type === 'rect'
        && object._objects[1]?.type === 'textbox'
        && object._objects[2]?.type === 'textbox'
  }

  isObjectInConnection(object: any) {
    return this.connectedItems.some(block => block.includes(object.id));
  }

  isObjectLocked(object: any) {
    return object?.lockMovementY === true
    && object?.lockMovementX === true
    && object?.lockScalingX === true
    && object?.lockScalingY === true;
  }

  saveCanvasState() {
    const canvasState = this.canvas.toJSON(
        ['id', 'isBackgroundImage', 'lockMovementX', 'lockMovementY', 'lockScalingX', 'lockScalingY', 'selectable']);
    if (this.historyStack[this.pageVisibleId].length > 50) {
      this.historyStack[this.pageVisibleId].shift();
      this.historyStack[this.pageVisibleId].push(canvasState);
    } else {
      this.historyStack[this.pageVisibleId].push(canvasState);
    }

    this.historyStack[this.pageVisibleId].length === 1
        ? this.pageIndexes[this.pageVisibleId] = 0
        : this.pageIndexes[this.pageVisibleId] < 50 ? this.pageIndexes[this.pageVisibleId]++ : this.pageIndexes[this.pageVisibleId] = 50;

    //Save canvas as strings to save on database
    /*const stateForSaving = this.canvas.toJSON(['id', 'isBackgroundImage', 'isBackGroundLocked']);
    if (this.jsonCanvasStates[this.pageVisibleId].length > 50) {
      this.jsonCanvasStates[this.pageVisibleId].shift();
      this.jsonCanvasStates[this.pageVisibleId].push(stateForSaving);
    } else {
      this.jsonCanvasStates[this.pageVisibleId].push(stateForSaving);
    }*/
  }

  initialCanvasZooming() {
    const diff = this.initialDims.width/this.size.width;
    this.canvas.setZoom(diff);
    this.size.width = Math.round(this.size.width * diff);
    this.size.height = Math.round(this.size.height * diff);
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
    this.canvas.zoomToPoint(new fabric.Point(Math.round(this.size.width) / 2, Math.round(this.size.height) / 2), diff)

    if (this.size.height < +this.canvasWrapper.nativeElement.style.height.replace('px', '')) {
      const diff = Math.abs(this.size.height - +this.canvasWrapper.nativeElement.style.height.replace('px', ''));
      (document.querySelectorAll('.canvas-container')[0] as HTMLElement).style.transform = `translateY(${diff/2}px)`;
    }
    this.maxZoom = this.canvas.getZoom() * 2;
    this.minZoom = this.canvas.getZoom() * 0.5;

    this.isLoading = false;
  }

  onSelectActiveSection(selectedItem: string) {
    const tabsContent = this.tabsContent?.nativeElement;
    const isTabsContentHidden = tabsContent?.classList.contains('hidden');

    if (isTabsContentHidden || this.activeView !== selectedItem) {
      this.activeView = selectedItem;
      // @ts-ignore
      tabsContent?.classList.remove('hidden');
      this.canvasWrapper!.nativeElement.classList.add('canvas-moved');
    } else {
      tabsContent?.classList.add('hidden');
      this.canvasWrapper!.nativeElement.classList.remove('canvas-moved');
    }

    this.queryOptions = {
      q: '',
      orientation: 'all',
      image_type: 'all',
      category: '',
      page: 0
    }

    this.displayedInfo = {
      tags: [],
      likes: 0
    }
  }

  onHideSideBar(element: HTMLDivElement) {
    if(element.classList.contains('hidden')) {
      element.classList.remove('hidden');
      this.canvasWrapper!.nativeElement.classList.add('canvas-moved');
    } else {
      this.activeView = '';
      element.classList.add('hidden');
      this.canvasWrapper!.nativeElement.classList.remove('canvas-moved');
    }
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    this.isFullScreen ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
  }

  onSearchContentByText(reload?: boolean) {
    // @ts-ignore
    this.queryOptions.q = this.searchContent[this.activeView];

    if (this.queryOptions.q) {
      this.isLoading = true;

      if (reload) {
        this.searchPagesCounter[this.activeView] = 1
        // @ts-ignore
        this.editorData[this.activeView] = [];
      }

      // @ts-ignore
      this.queryOptions.page = this.searchPagesCounter[this.activeView];

      const homiImages = this.freepikService.getHomiImages(this.queryOptions);
      const pixabayImages = this.freepikService.getImagesFromPixabay(this.queryOptions);
      /*const freepikImages = this.freepikService.getImagesFromFreePik(this.queryOptions);*/

      pixabayImages.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        let data = (res as any).hits.map((item: any) => {
          return {
            preview: item.previewURL,
            image: item.largeImageURL,
            info: {
              tags: item.tags,
              likes: item.likes
            }
          }
        });

        if (data.length === 0) {
          this.isLoading = false;
          return;
        }

        // @ts-ignore
        this.editorData[this.activeView] = this.editorData[this.activeView].concat(data);
      })

      /*freepikImages.subscribe((res: any) => {
        let data = res.data.map((item: any) => {
          return {
            preview: item.image.source.url,
            image: item.image.source.url,
            info: {
              tags: item.related.keywords
            }
          }
        })

        if (data.length === 0) {
          this.isLoading = false;
          return;
        }

        // @ts-ignore
        this.editorData[this.activeView] = this.editorData[this.activeView].concat(data);
      })*/

      homiImages.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        let data = res.map((item: any) => {
          return {
            preview: item.url,
            image: item.url,
            info: {
              tags: item.keywords
            }
          }
        })

        // @ts-ignore
        this.editorData[this.activeView] = this.editorData[this.activeView].concat(data);
      });

      if (reload) {
        this.sidebarWrapper?.nativeElement.scrollTo(0, 0);
      }

      this.searchPagesCounter[this.activeView]++;

      this.randomGraphicsDisplaying = false;

      setTimeout(() => {
        this.isLoading = false;
      }, 500)
    }
  }

  onSearchBackgroundsByText(reload?: boolean) {
    // @ts-ignore
    this.queryOptions.q = this.searchContent[this.activeView];

    if (this.queryOptions.q) {
      this.isLoading = true;

      if (reload) {
        this.searchPagesCounter[this.activeView] = 1
        // @ts-ignore
        this.editorData[this.activeView] = [];
      }

      // @ts-ignore
      this.queryOptions.page = this.searchPagesCounter[this.activeView];

      const homiImages = this.freepikService.getHomiImagesBackgrounds(this.queryOptions);

      homiImages.pipe(takeUntil(this.destroyed$)).subscribe(res => {

        let data = res.map((item: any) => {
          return {
            preview: item.url,
            image: item.url,
            info: {
              tags: item.keywords
            }
          }
        })

        // @ts-ignore
        this.editorData[this.activeView] = this.editorData[this.activeView].concat(data);
      });

      if (reload) {
        this.sidebarWrapper?.nativeElement.scrollTo(0, 0);
      }

      this.searchPagesCounter[this.activeView]++;

      this.randomBackgroundsDisplaying = false;

      setTimeout(() => {
        this.isLoading = false;
      }, 500)
    }
  }

  onAddNewPage() {
    this.savedPages.push(`Page #${this.savedPages.length + 1}`);
    this.pageVisibleId = this.savedPages.length - 1;
    this.pageIndexes.push(0)
    if (!this.historyStack[this.pageVisibleId]) {
      this.historyStack.push([
        {
          version: '5.3.0',
          objects: [],
          background: '#c0c5e1'
        }
      ]);
      //this.jsonCanvasStates.push([])
      this.onSelectActivePage(this.pageVisibleId);
      this.saveCanvasState();
    }
  }

  onSelectActivePage(index: number) {
    this.isLoading = true;
    this.skipObjectEvent = true;
    this.pageVisibleId = index;

    // Resolving CANVAS CORS
    this.historyStack[index][this.pageIndexes[index]].objects.forEach((item: any) => {
      if (item.src && !item.src.includes('https://satoshi-cors.herokuapp.com') && !item.src.includes('base64')) {
        item.src = `https://satoshi-cors.herokuapp.com/${item.src}`
      }
    })

    this.canvas.loadFromJSON(this.historyStack[index][this.pageIndexes[index]], () => {
      this.canvas.getObjects().forEach(object => {
        // @ts-ignore
        if (object.type === 'image' && object.isBackgroundImage) {
          this.isBackGroundLocked = !this.canvas.getObjects()[0].selectable!;
        }
        if (object.type === 'rect') {
          this.canvas.remove(object);
        }
      });
      this.skipObjectEvent = false;
      this.canvas.renderAll();
      this.isLoading = false;
      console.log('Active page selected')
    })
  }

  selectAllObjects() {
    this.canvas.discardActiveObject();
    let allObjects = this.canvas.getObjects();
    // @ts-ignore
    if (allObjects[0].isBackgroundImage && !allObjects[0].selectable) {
      allObjects.splice(0, 1);
    }

    const sel = new fabric.ActiveSelection(allObjects, {
      canvas: this.canvas,
    });
    this.canvas.setActiveObject(sel);
    this.canvas.requestRenderAll();
  }

  lockBackground() {
    const background = this.canvas.getObjects()[0];
    const activeObject = this.canvas.getActiveObject();

    if (activeObject) {
      // @ts-ignore
      if (activeObject.isBackgroundImage) {
        // @ts-ignore
        if (background.isBackgroundImage && background.selectable) {
          background.lockMovementY = true;
          background.lockMovementX = true;
          background.lockScalingX = true;
          background.lockScalingY = true;
          background.selectable = false;
          this.isBackGroundLocked = true;
          // @ts-ignore
        } else if (background.isBackgroundImage && !background.selectable) {
          background.lockMovementY = false;
          background.lockMovementX = false;
          background.lockScalingX = false;
          background.lockScalingY = false;
          background.selectable = true;
          this.isBackGroundLocked = false;
        }
      } else if (activeObject.type === 'image') {
        if (this.isObjectLocked(activeObject)) {
          activeObject.lockMovementY = false;
          activeObject.lockMovementX = false;
          activeObject.lockScalingX = false;
          activeObject.lockScalingY = false;
        } else {
          activeObject.lockMovementY = true;
          activeObject.lockMovementX = true;
          activeObject.lockScalingX = true;
          activeObject.lockScalingY = true;
        }
      }
    } else {
      // @ts-ignore
      if (background.isBackgroundImage && background.selectable) {
        background.lockMovementY = true;
        background.lockMovementX = true;
        background.lockScalingX = true;
        background.lockScalingY = true;
        background.selectable = false;
        this.isBackGroundLocked = true;
        // @ts-ignore
      } else if (background.isBackgroundImage && !background.selectable) {
        background.lockMovementY = false;
        background.lockMovementX = false;
        background.lockScalingX = false;
        background.lockScalingY = false;
        background.selectable = true;
        this.isBackGroundLocked = false;
      }
    }
    this.saveCanvasState();
  }

  undo() {
    if (this.historyStack[this.pageVisibleId].length > 0 && this.pageIndexes[this.pageVisibleId] > 0 && this.pageIndexes[this.pageVisibleId] <= 50) {
      this.pageIndexes[this.pageVisibleId]--;
      this.skipObjectEvent = true;
      const canvasState = this.historyStack[this.pageVisibleId][this.pageIndexes[this.pageVisibleId]];

      this.canvas.discardActiveObject();
      this.canvas.loadFromJSON(canvasState, () => {
        this.skipObjectEvent = false;
        // @ts-ignore
        if (this.canvas.getObjects()[0] && this.canvas.getObjects()[0] === 'image' && this.canvas.getObjects()[0].isBackgroundImage) {
          this.isBackGroundLocked = !this.canvas.getObjects()[0].selectable!;
        }
        console.log('undo')
      });
      this.canvas.renderAll();
      //this.skipObjectEvent = false;
    }
  }

  redo() {
    if (this.historyStack[this.pageVisibleId].length > 0 && this.pageIndexes[this.pageVisibleId] >= 0 && this.pageIndexes[this.pageVisibleId] <= this.historyStack[this.pageVisibleId].length - 2) {
      this.pageIndexes[this.pageVisibleId]++;
      this.skipObjectEvent = true;
      const canvasState = this.historyStack[this.pageVisibleId][this.pageIndexes[this.pageVisibleId]];

      this.canvas.discardActiveObject();
      this.canvas.loadFromJSON(canvasState, () => {
        this.skipObjectEvent = false;

        // @ts-ignore
        if (this.canvas.getObjects()[0] && this.canvas.getObjects()[0] === 'image' && this.canvas.getObjects()[0].isBackgroundImage) {
          this.isBackGroundLocked = !this.canvas.getObjects()[0].selectable!;
        }

        console.log('redo')
      });
      this.canvas.renderAll();
      //this.skipObjectEvent = false;
    }
  }

  flipVertical() {
    const target = this.canvas.getActiveObject()!;
    target.flipY ? target.flipY = false : target.flipY = true;

    this.canvas.renderAll();
  }

  flipHorizontal() {
    const target = this.canvas.getActiveObject()!;
    target.flipX ? target.flipX = false : target.flipX = true;

    this.canvas.renderAll();
  }

  onOpenModalTopDeletePage(index: number) {
    this.modalRef = this.modalService.show(this.deletePageModal,  {class: 'modal-sm modal-dialog-centered'});
    this.pageIndexToDelete = index;
    //this.projectImage = this.onConvertCanvasToImage();
  }
  onDeletePage() {
    if (this.historyStack.length > 1) {
      this.pageVisibleId = this.pageIndexToDelete - 1 >= 0 ? this.pageIndexToDelete - 1 : 0;
      this.historyStack.splice(this.pageIndexToDelete, 1);
      this.pageIndexes.splice(this.pageIndexToDelete, 1);
      this.savedPages.splice(this.pageIndexToDelete, 1);

      this.onSelectActivePage(this.pageVisibleId);
    }
    this.modalRef?.hide();
  }

  onOpenModalForSave() {
    if (!this.project || (this.project && this.project.isStarter)) {
      this.modalRef = this.modalService.show(this.saveProjectModal,  {class: 'modal-sm modal-dialog-centered'});
      this.projectImage = this.onConvertCanvasToImage();
    } else {
      this.projectImage = this.onConvertCanvasToImage();
      this.onSaveProject();
    }
  }

  async onSaveProject() {
    this.isLoading = true;
    let state = this.historyStack.map((pageStates, index) => {
      return pageStates[this.pageIndexes[index]];
    })

    try {
      const res = await this.onSaveImageToStorage();
      let id: string;
      if (this.project && !this.project.isStarter) {
        id = this.project.id
      } else {
        const docRef = await this.afs.collection('projects').add({});
        id = docRef.id;
      }

      this.processImages(state).then((data) => {
        const newProject = {
          projectName: this.projectName ? this.projectName : (this.project?.projectName ? this.project?.projectName : 'Untitled Memory Story'),
          projectSubtitle: this.projectSubtitle ? this.projectSubtitle : (this.project?.projectSubtitle ? this.project?.projectSubtitle : ''),
          count: this.project?.count ? this.project?.count : 0,
          author: this.authService.user$.value.uid,
          state: data,
          img: res,
          pageNames: this.savedPages,
          isPrivate: this.project?.isPrivate ? this.project?.isPrivate : true,
          status: 'draft',
          id,
          snippets: JSON.stringify(this.connectedItems)
        };
        //@ts-ignore
        this.project = newProject;

        this.afs.collection('projects').doc(id).set(newProject).then(() => {
          this.isLoading = false;
          this.savingProjectInProgress = false;
          this.modalRef?.hide();
        })
      })
    } catch (error) {
      console.error(error);
    }
  }

  async processImages(state: any) {
    const directory = 'images';

    for (const page of state) {
      for (const obj of page.objects) {
        if (obj.type === 'image' && obj.src.includes('pixabay')) {
          try {
            const blob = await this.http.get(obj.src, { responseType: 'blob' }).toPromise();

            if (blob) {
              const newName = `${this.user.uid}-${dayjs().format('MM-DD-YYYY-hh-mm-ss-SSS')}.jpeg`;
              const blobFile = new File([blob], newName, { type: blob.type });

              const uploadPromise = this.s3Service.uploadFile(blobFile, `${directory}/md`, blobFile.name);
              await uploadPromise;

              obj.src = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${blobFile.name}`;
            } else {
              console.error('Blob is undefined:', obj.src);
            }
          } catch (error) {
            console.error('Error fetching blob:', error);
          }
        }
      }
    }

    return state;
  }


  /*async processImages(state: any) {
    const promises: Promise<void>[] = [];
    const directory = 'images';

    for (const page of state) {
      for (const obj of page.objects) {
        if (obj.type === 'image' && obj.src.includes('pixabay')) {
          console.log('here')
          promises.push(
              (async () => {
                try {
                  const blob = await this.http.get(obj.src, { responseType: 'blob' }).toPromise();

                  if (blob) {
                    const newName = `${this.user.uid}-${dayjs().format('MM-DD-YYYY-hh-mm-ss')}.jpeg`;
                    const blobFile = new File([blob], newName, { type: blob.type });

                    await this.s3Service.uploadFile(blobFile, `${directory}/md`, blobFile.name);
                    obj.src = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${blobFile.name}`;
                  } else {
                    console.error('Blob is undefined:', obj.src);
                  }
                } catch (error) {
                  console.error('Error fetching blob:', error);
                }
              })()
          );
        }
      }
    }

    await Promise.all(promises);
    return state; // or return any other meaningful data
  }*/


  onConvertCanvasToImage() {
    const dataURL = this.canvas.toDataURL({ format: 'jpeg', quality: 1.0 });
    const image = new Image();
    image.src = dataURL;
    return image;
    /*return image.src;*/
  }

  /*async onSaveImageToStorageFromPixabay(imageUrl: string) {
    return new Promise(async (resolve, reject) => {
      const newName = `${this.authService.user$.value.uid}-${new Date().getTime()}.jpeg`;
      const storageRef = this.fireStorage.ref(`/projects/${newName}`);

      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const uploadTask = storageRef.put(blob, {contentType: 'image/jpeg'});

      uploadTask.then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL)
          resolve(downloadURL);
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  }*/

  convertImageToFile(src: string) {
    const base64Image = src.split(',')[1];
    const byteCharacters = atob(base64Image);
    const byteArrays = [];
    const newName = `${this.user.uid}-${dayjs().format('MM-DD-YYYY-hh-mm-ss')}.jpeg`;

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return new File([blob], newName, {type: 'image/png'});
  }

  async onSaveImageToStorage() {
    const directory = 'images';

    return new Promise((resolve, reject) => {
      const file = this.convertImageToFile(this.projectImage.src);

      this.s3Service.uploadFile(file, `${directory}/md`, file.name).then(() => {
        const imageUrl = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${file.name}`;
        resolve(imageUrl);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
      /*const storageRef = this.fireStorage.ref(`/projects/${newName}`);
      const uploadTask = storageRef.putString(this.projectImage.replace('data:image/jpeg;base64,', ''), 'base64', { contentType: 'image/jpeg' });
      uploadTask.then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });*/
    });
  }

  async onSaveTemporaryImagesToStorage(image: any) {
    return new Promise((resolve, reject) => {
      const newName = `${this.authService.user$.value.uid}-${new Date().getTime()}.jpeg`;
      const storageRef = this.fireStorage.ref(`/tempData/${this.authService.user$.value.uid}/${newName}`);
      const uploadTask = storageRef
          .putString(image
              .replace('data:image/jpeg;base64,', '')
              .replace('data:image/png;base64,', ''), 'base64', { contentType: 'image/jpeg' });
      uploadTask.then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  }

  onZoom(direction: string) {
    let zoom = this.canvas.getZoom();

    if (zoom < this.minZoom && direction !== 'in') {
      return;
    } else if (zoom > this.maxZoom && direction === 'in') {
      return;
    }

    direction !== 'in'
        ? (
            this.canvas.setZoom(zoom * 0.8),
            this.size.width = Math.round(this.size.width * 0.8),
            this.size.height = Math.round(this.size.height * 0.8),
            this.canvas.setWidth(this.size.width),
            this.canvas.setHeight(this.size.height),
            this.canvas.zoomToPoint(new fabric.Point(Math.round(this.size.width) / 2, Math.round(this.size.height) / 2), zoom * 0.8)
          )
        : (
            this.canvas.setZoom(zoom * 1.2),
            this.size.width = Math.round(this.size.width * 1.2),
            this.size.height = Math.round(this.size.height * 1.2),
            this.canvas.setWidth(this.size.width),
            this.canvas.setHeight(this.size.height),
            this.canvas.zoomToPoint(new fabric.Point(Math.round(this.size.width) / 2, Math.round(this.size.height) / 2), zoom * 1.2)
          );

    if (this.size.height < +this.canvasWrapper.nativeElement.style.height.replace('px', '')) {
      const diff = Math.abs(this.size.height - +this.canvasWrapper.nativeElement.style.height.replace('px', ''));
      (document.querySelectorAll('.canvas-container')[0] as HTMLElement).style.transform = `translateY(${diff/2}px)`;
    }
  }

  /*------------------------Block elements------------------------*/

  // Block "Size"

  changeSize() {
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
  }

  // Block "Add text"

  addLessonSnippet(params?: any) {
    const heading = new fabric.Textbox(this.snippetHeading || 'Lesson Heading', {
      width: 280,
      left: 10,
      top: 24,
      fontSize: 20,
      fontWeight: 900,
      fontFamily: 'Nunito',
      angle: 0,
      fill: '#ffc85b',
      hasRotatingPoint: true,
      textAlign: 'left',
      isWrapping: true
    });

    heading.set({ height: heading.getHeightOfLine(0) * (heading.textLines.filter(line => line).length + 0.2) });

    const body = new fabric.Textbox(this.snippetText || 'Lesson Body', {
      width: 280,
      left: 10,
      top: heading.top! + heading.height!,
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Nunito',
      angle: 0,
      fill: '#ffffff',
      hasRotatingPoint: true,
      textAlign: 'left',
      isWrapping: true
    });

    //body.set({ height: body.getHeightOfLine(0) * body.textLines.filter(line => line).length });

    const rect = new fabric.Rect({
      left: 0,
      top: 20,
      width: Math.max(body.width!, heading.width!) + 30,
      height: heading.height! + body.height! + 9,
      fill: '#333333',
      opacity: 0.9,
      rx: 8,
      ry: 8,
      selectable: true
    });

    const snippet = new fabric.Group([ rect,heading, body], {
      left: params?.left ? params.left : 100,
      top: params?.top ? params.top : 100,
      width: Math.max(body.width!, heading.width!) + 30,
      height: heading.height! + body.height! + 9,
      scaleX: params?.scale ? params.scale : 1.8,
      scaleY: params?.scale ? params.scale : 1.8,
      // @ts-ignore
      id: params?.id ? params?.id : this.randomId()
    });

    snippet.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false
    });

    this.snippetHeading = '';
    this.snippetText = '';

    this.canvas.add(snippet);
    this.selectItemAfterAdded(snippet);
    this.canvas.renderAll();
  }

  editLessonSnippet() {
    this.skipObjectEvent = true;
    const left = this.canvas.getActiveObject()!.left;
    const top = this.canvas.getActiveObject()!.top;
    const scale = this.canvas.getActiveObject()!.scaleX;
    const prevSnippet = this.canvas.getActiveObject()!;
    // @ts-ignore
    const id = prevSnippet.id;
    this.addLessonSnippet({left, top, id, scale});
    this.canvas.remove(prevSnippet);
    this.skipObjectEvent = false;
    this.saveCanvasState();
  }
  // Block "Add images"

  getImgPolaroid(event: any) {
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 10,
        top: 10,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      this.canvas.add(image);
      this.selectItemAfterAdded(image);
    });
  }

  // Block "Upload Image"

  addImageOnCanvas(url: any, params?: any) {
    this.isLoading = true;
    if (url) {
      if (url.includes('https')) {
        url = `https://satoshi-cors.herokuapp.com/${url}` //TODO remove for prod
      }

      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: params?.left ? params.left : 300,
          top: params?.top ? params.top : 10,
          width: params?.width ? params?.width : image.width,
          height: params?.height ? params?.height : image.height,
          angle: params?.angle ? params?.angle : image.angle,
          scaleX: params?.scaleX ? params?.scaleX : image.scaleX,
          scaleY: params?.scaleY ? params?.scaleY : image.scaleY,
          cornerSize: 10,
          hasRotatingPoint: true
          /*absolutePositioned: false,*/
        });
        if (!params) {
          image.scaleToWidth(200);
          image.scaleToHeight(200);
        }
        //image.borderColor = image.cornerColor = image.cornerStrokeColor = '#1f305e';
        image.transparentCorners = false;
        this.canvas.add(image);
        this.selectItemAfterAdded(image);
      }, {
        crossOrigin: 'anonymous'
      });
      this.canvas.renderAll();
    }
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        // @ts-ignore
        this.url = readerEvent.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeWhite(url: any) {
    this.url = '';
  }

  // Block "Add figure"

  addFigure(figure: any) {
    let add: any;
    switch (figure) {
      case 'rectangle':
        add = new fabric.Rect({
          width: 200, height: 100, left: 10, top: 10, angle: 0,
          fill: '#3f51b5'
        });
        break;
      case 'square':
        add = new fabric.Rect({
          width: 100, height: 100, left: 10, top: 10, angle: 0,
          fill: '#4caf50'
        });
        break;
      case 'triangle':
        add = new fabric.Triangle({
          width: 100, height: 100, left: 10, top: 10, fill: '#2196f3'
        });
        break;
      case 'circle':
        add = new fabric.Circle({
          radius: 50, left: 10, top: 10, fill: '#ff5722'
        });
        break;
    }
    this.canvas.add(add);
    this.selectItemAfterAdded(add);
  }

  /*Canvas*/

  cleanSelect() {
    this.canvas.discardActiveObject().renderAll();
  }

  selectItemAfterAdded(obj: any) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  setCanvasFill() {
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.props.canvasFill;
      this.canvas.renderAll();
    }
  }

  extend(obj: any, id: any) {
    obj.toObject = ((toObject) => {
      return () => {
        return fabric.util.object.extend(toObject, {
          id
        });
      };
    })(obj.toObject);
  }

  extendActiveGroupId(obj: any, activeGroupId: any) {
    obj.toObject = ((toObject) => {
      return () => {
        return fabric.util.object.extend(toObject, {
          activeGroupId
        });
      };
    })(obj.toObject);
  }

  setCanvasImage(item: any) {
    this.isLoading = true;
    if (item.includes('https')) {
      item = `https://satoshi-cors.herokuapp.com/${item}`
    }
    this.props.canvasImage = item;

    fabric.Image.fromURL(item, (img: any) => {
      const heightRatio = (this.size.height / img.height) / this.canvas.getZoom();
      const widthRatio = (this.size.width / img.width) / this.canvas.getZoom();
      const scale = Math.min(widthRatio, heightRatio);

      const bgImg = new fabric.Image(img.getElement(), {
        left: 0,
        top: 0,
        scaleX: scale,
        scaleY: scale,
        // @ts-ignore
        isBackgroundImage: true
      });

      bgImg.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        mtr: false
      });

      // @ts-ignore
      if (this.canvas.getObjects()[0]?.isBackgroundImage) {
        this.canvas.insertAt(bgImg, 0, true);
      } else {
        this.canvas.insertAt(bgImg, 0, false);
      }
    }, {
      crossOrigin: 'anonymous'
    });
    this.canvas.renderAll();
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }

  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName: any, object: any) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  setActiveStyle(styleName: any, value: string | number, object: fabric.IText) {
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      // @ts-ignore
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({underline: true});
        } else {
          object.setSelectionStyles({underline: false});
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({overline: true});
        } else {
          object.setSelectionStyles({overline: false});
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({linethrough: true});
        } else {
          object.setSelectionStyles({linethrough: false});
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }


  getActiveProp(name: any) {
    const object = this.canvas.getActiveObject();
    if (!object) { return ''; }

    // @ts-ignore
    return object[name] || '';
  }

  setActiveProp(name: any, value: any) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }

  clone(): any {
    const objectsClonedArray: any[] = [];
    const objectsToClone = this.canvas.getActiveObjects();

    objectsToClone.forEach((object: any) => {
      let clone;
      switch (object.type) {
        case 'rect':
          clone = new fabric.Rect(object.toObject());
          break;
        case 'circle':
          clone = new fabric.Circle(object.toObject());
          break;
        case 'triangle':
          clone = new fabric.Triangle(object.toObject());
          break;
        case 'i-text':
          clone = new fabric.IText('', object.toObject());
          break;
        case 'image':
          clone = fabric.util.object.clone(object);
          break;
        case 'group':
          clone = new fabric.Group(object.getObjects().map((item: any) => fabric.util.object.clone(item)), object.toObject());
          break;
      }
      clone.id = this.randomId();
      objectsClonedArray.push(clone);
      this.canvas.add(...objectsClonedArray);
    });
  }

  getId() {
    this.props.id = this.canvas.getActiveObject()!.toObject().id;
  }

  setId() {
    const val = this.props.id;
    const complete = this.canvas.getActiveObject()!.toObject();
    console.log(complete);
    this.canvas.getActiveObject()!.toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  getOpacity() {
    // @ts-ignore
    this.props.opacity = this.getActiveStyle('opacity', null) * 100;
  }

  setOpacity() {
    // @ts-ignore
    this.setActiveStyle('opacity', parseInt(this.props.opacity, 10) / 100, null);
  }

  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }

  setFill() {
    this.setActiveStyle('fill', this.props.fill!, null!);
  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }

  setLineHeight() {
    this.setActiveStyle('lineHeight', parseFloat(this.props.lineHeight!), null!);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }

  setCharSpacing() {
    this.setActiveStyle('charSpacing', this.props.charSpacing!, null!);
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle('fontSize', null);
  }

  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.props.fontSize!, 10), null!);
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight', null);
  }

  setBold() {
    // @ts-ignore
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null!);
  }

  setFontStyle() {
    // @ts-ignore
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null!);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null!);
    }
  }

  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration', null);
  }

  setTextDecoration(value: any) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration, null!);
  }

  hasTextDecoration(value: any) {
    return this.props.TextDecoration.includes(value);
  }

  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }

  setTextAlign(value: any) {
    this.props.textAlign = value;
    this.setActiveProp('textAlign', this.props.textAlign);
  }

  getFontFamily() {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }

  setFontFamily() {
    this.setActiveProp('fontFamily', this.props.fontFamily);
  }

  /*System*/

  removeSelected() {
    const activeGroup = this.canvas.getActiveObjects();
    const selection = this.canvas.getActiveObject();

    if (activeGroup.length && !this.isObjectLocked(selection)) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        this.canvas.remove(object);
      });
    }
  }

  bringToFront() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      activeObject.bringToFront();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.bringToFront();
      });
    }
    this.saveCanvasState();
  }

  sendToBack() {
    const activeObject = this.canvas.getActiveObject()!;
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.discardActiveObject();
      this.canvas.sendToBack(activeObject);
      activeObject.sendToBack();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.sendToBack();
      });
    }
    this.canvas.renderAll();
    this.saveCanvasState();
  }

  confirmClear() {
    if (confirm('Are you sure?')) {
      this.canvas.clear();
    }
  }

  rasterize() {
    const image = new Image();
    image.src = this.canvas.toDataURL({format: 'png'});
    const w = window.open('');
    w?.document.write(image.outerHTML);
  }

  rasterizeSVG() {
    const w = window.open('');
    w?.document.write(this.canvas.toSVG());
    return 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem('Kanvas', json);
    console.log('json');
    console.log(json);

  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem('Kanvas');
    console.log('CANVAS');
    console.log(CANVAS);

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      console.log('CANVAS untar');
      console.log(CANVAS);

      // making sure to render canvas at the end
      this.canvas.renderAll();

      // and checking if object's "name" is preserved
      console.log('this.canvas.item(0).name');
      console.log(this.canvas);
    });

  }

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
  }

  drawingMode(){
    this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
  }

  /*--- Drag and Drop ---*/
  onFileDropped(files: any) {
    const directory = 'images';
    Array.prototype.forEach.call(files, (file: any) => {
      if (file.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.isLoading = true;
          const newName = `${this.authService.user$.value.uid}-${this.generateUUID()}.jpeg`;
          const renamedFile = new File([file], newName, { type: file.type });
          //const dataUrl = event.target.result || '';
          this.s3Service.uploadFile(renamedFile, `${directory}/md`, renamedFile.name).then(() => {
            const imageUrl = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${renamedFile.name}`;
            //this.imagesLinksForDatabase.push({ tempLink: dataUrl, databaseLink: imageUrl });
            // @ts-ignore
            this.editorData.uploads[this.selectedUploadCategory].push(imageUrl);
            this.user.uploadedFiles = this.editorData.uploads;
            this.afs.collection('users').doc(this.user.uid).set(this.user).then(() => {
              this.isLoading = false;
            })
          })
          /*this.onSaveTemporaryImagesToStorage(dataUrl).then(result => {
            // @ts-ignore
            this.editorData.uploads[this.selectedUploadCategory].push(result);
            this.isLoading = false;
          })*/
        };
        reader.readAsDataURL(file);
      }
    })
  }

  downLoadFile(event:any) {
    this.onFileDropped(event.target.files);
  }

  onDeleteDroppedFiles(index: number, category: string) {
    this.isLoading = true;
    // @ts-ignore
    this.editorData.uploads[category].splice(index, 1);
    this.user.uploadedFiles = this.editorData.uploads;
    this.afs.collection('users').doc(this.user.uid).set(this.user).then(() => {
      this.isLoading = false;
    })
  }

  clipImage(object: any, image: any) {
    this.onResetControlsPosition();
    let clipPath: any;

    if (image.clipPath) {
      clipPath = {
        left: image.clipPath.left,
        top: image.clipPath.top,
        width: image.clipPath.width! * image.clipPath.scaleX! - 1,
        height: image.clipPath.height! * image.clipPath.scaleY! - 1,
        angle: image.clipPath.angle,
      }
      image.clipPath = undefined;
    }
    const rect = new fabric.Rect({
      left: clipPath ? clipPath.left : image.left,
      top: clipPath ? clipPath.top : image.top,
      width: clipPath ? clipPath.width : image.width! * image.scaleX! - 1,
      height: clipPath ? clipPath.height : image.height! * image.scaleY! - 1,
      angle: image.angle,
      fill: 'rgba(31, 48, 94, .1)',
      absolutePositioned: true,
      originX: 'left',
      originY: 'top'
    });
    rect.setCoords();

    this.canvas.add(rect);
    this.canvas.setActiveObject(rect);
    this.selected = rect;

    let moveStopTimeout: any;
    image.on('moving', (e: any) => {
      if (image.clipPath) {
        image.clipPath.absolutePositioned = false;

        clearTimeout(moveStopTimeout);

        moveStopTimeout = setTimeout(() => {
          console.log('updated')
          // @ts-ignore
          image.clipPath!.left! = image.left - image.diffXClip;
          // @ts-ignore
          image.clipPath!.top! = image.top - image.diffYClip;
        }, 50);
      }
    });

    const keyUpHandler = (event: KeyboardEvent) => {
          if (this.selected && this.selected.type === 'rect' && event.key === 'Enter') {
            const clipArea = new fabric.Rect({
              left: rect.left,
              top: rect.top,
              width: rect.getScaledWidth(),
              height: rect.getScaledHeight(),
              angle: rect.angle,
              absolutePositioned: true
            });

            image.clipPath = clipArea;

            // @ts-ignore
            image['diffXClip'] = parseFloat((image.left - clipArea.left).toFixed(2));
            // @ts-ignore
            image['diffYClip'] = parseFloat((image.top - clipArea.top).toFixed(2));

            //this.onSetupControlsOnImageWithClipPath(image, rect);

            this.canvas.remove(rect);
            this.canvas.renderAll();

          document.removeEventListener('keyup', keyUpHandler);
          this.canvas.getObjects().forEach(obj => {
            obj.selectable = true;
          })
        } else if (this.selected && this.selected.type === 'rect' && event.key === 'Backspace') {
          this.canvas.getObjects().forEach(obj => {
            obj.selectable = true;
          });
          this.canvas.remove(rect);
          document.removeEventListener('keyup', keyUpHandler);
        }
      }
      document.addEventListener('keyup', keyUpHandler);

    this.canvas.on('selection:cleared', (e) => {
      document.removeEventListener('keyup', keyUpHandler);
      if (e.deselected?.length && e.deselected![0]?.type === 'rect') {
        if (clipPath) {
          image.clipPath = new fabric.Rect({
            left: clipPath.left,
            top: clipPath.top,
            width: clipPath.width,
            height: clipPath.height,
            angle: clipPath.angle,
            absolutePositioned: true
          });
        }
        this.canvas.remove(e.deselected![0]);
        this.canvas.getObjects().forEach(obj => {
          obj.selectable = true;
        })
      }
    })
  }

  groupingObjects() {
    if (!this.canvas.getActiveObject()) {
      return;
    }
    if (this.canvas.getActiveObject()!.type !== 'activeSelection') {
      return;
    }
    this.skipObjectEvent = true;
    const activeSelection = this.canvas.getActiveObject() as fabric.ActiveSelection;
    if (activeSelection) {
      // @ts-ignore
      activeSelection.toGroup();
      this.skipObjectEvent = false;
      this.saveCanvasState();
    }

    fabric.Object.prototype.controls['groupObjects'].setVisibility(false);
    fabric.Object.prototype.controls['ungroupObjects'].setVisibility(true);

    this.canvas.renderAll();
  }

  ungroupingObjects() {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject!.type=="group" && activeObject instanceof fabric.Group) {
      this.skipObjectEvent = true;
      let items = activeObject!._objects;
      activeObject!._restoreObjectsState();
      this.canvas.remove(activeObject);
      for (let i = 0; i < items.length; i++) {
        this.canvas.add(items[i]);
      }
      this.canvas.setActiveObject(new fabric.ActiveSelection(items, { canvas: this.canvas }));
      this.skipObjectEvent = false;
      this.saveCanvasState();
    }

    fabric.Object.prototype.controls['groupObjects'].setVisibility(true);
    fabric.Object.prototype.controls['ungroupObjects'].setVisibility(false);

    this.canvas.renderAll();
  }

  addObjectControlOnDelete() {
    const deleteIcon = document.createElement('img');
    deleteIcon.src = 'assets/icons/trash-with-bgc.png';

    fabric.Object.prototype.controls['deleteControl'] = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -23,
      cursorStyle: 'pointer',
      mouseUpHandler: deleteObject,
      render: renderIcon(deleteIcon)
    });

     function deleteObject(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
       const target = transformData.target;
       const canvas = target.canvas;
       const isTargetLocked = target.lockMovementX && target.lockMovementY && target.lockScalingX && target.lockScalingY;
       if (
           // @ts-ignore
           target._objects
           // @ts-ignore
           && !(target._objects?.length === 3 && target?.type === 'group' && target._objects[0]?.type === 'rect' && target._objects[1]?.type === 'textbox' && target._objects[2]?.type === 'textbox')
           && !isTargetLocked) {
         // @ts-ignore
         target._objects.forEach(obj => {
           canvas!.remove(obj);
         });
         canvas!.discardActiveObject();
         canvas!.requestRenderAll();
       } else if (!isTargetLocked) {
         canvas!.remove(target);
         canvas!.requestRenderAll();
       }
       return true;
     }

     function renderIcon(deleteIcon: any) {
       return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
         const size = 25;
         ctx.save();
         ctx.translate(left, top);
         ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
         ctx.drawImage(deleteIcon, -size/2, -size/2, size, size);
         ctx.restore();
       }
     }
  }

  addObjectControlOnGroup() {
    const groupIcon = document.createElement('img');
    groupIcon.src = "assets/icons/editor/group-btn.png";

    fabric.Object.prototype.controls['groupObjects'] = new fabric.Control({
      x: -0.5,
      y: -0.5,
      cursorStyle: 'pointer',
      offsetY: -23,
      // @ts-ignore
      mouseUpHandler: this.groupingObjects.bind(this),
      render: renderIcon(groupIcon),
      sizeX: 50,
      sizeY: 25,
      touchSizeX: 50,
      touchSizeY: 25
    });

    function renderIcon(icon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left - 13, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size*2, size);
        ctx.restore();
      }
    }
  }

  addObjectControlOnUngroup() {
    const ungroupIcon = document.createElement('img');
    ungroupIcon.src = "assets/icons/editor/ungroup-btn.png";

    fabric.Object.prototype.controls['ungroupObjects'] = new fabric.Control({
      x: -0.5,
      y: -0.5,
      cursorStyle: 'pointer',
      offsetY: -23,
      // @ts-ignore
      mouseUpHandler: this.ungroupingObjects.bind(this),
      render: renderIcon(ungroupIcon),
      sizeX: 65,
      sizeY: 25,
      touchSizeX: 65,
      touchSizeY: 25
    });

    function renderIcon(icon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left - 20, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size*2.5, size);
        ctx.restore();
      }
    }
  }

  addObjectControlOnLock() {
    const lockIcon = document.createElement('img');
    lockIcon.src = "assets/icons/padlock.png";

    fabric.Object.prototype.controls['lockObject'] = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -23,
      offsetX: -30,
      cursorStyle: 'pointer',
      mouseUpHandler: this.lockObject.bind(this),
      render: renderIcon(lockIcon)
    });

    function renderIcon(icon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size, size);
        ctx.restore();
      }
    }
  }

  lockObject(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
    const target = transformData.target;
    target.lockMovementY = true;
    target.lockMovementX = true;
    target.lockScalingX = true;
    target.lockScalingY = true;

    fabric.Object.prototype.controls['lockObject'].setVisibility(false);
    fabric.Object.prototype.controls['unlockObject'].setVisibility(true);

    this.canvas.renderAll();
    return true;
  }

  addObjectControlOnUnLock() {
    const unlockIcon = document.createElement('img');
    unlockIcon.src = "assets/icons/unlock.png";

    fabric.Object.prototype.controls['unlockObject'] = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -23,
      offsetX: -30,
      cursorStyle: 'pointer',
      mouseUpHandler: this.unlockObject.bind(this),
      render: renderIcon(unlockIcon)
    });

    function renderIcon(icon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size, size);
        ctx.restore();
      }
    }
  }

  unlockObject(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
    const target = transformData.target;
    target.lockMovementY = false;
    target.lockMovementX = false;
    target.lockScalingX = false;
    target.lockScalingY = false;

    fabric.Object.prototype.controls['lockObject'].setVisibility(true);
    fabric.Object.prototype.controls['unlockObject'].setVisibility(false);

    this.canvas.renderAll();
    return true;
  }

  addObjectsControlConnect() {
    const connectIcon = document.createElement('img');
    connectIcon.src = "assets/icons/editor/connect.png";

    fabric.Object.prototype.controls['connectObjects'] = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetY: -23,
      offsetX: 65,
      cursorStyle: 'grab',
      mouseUpHandler: this.connectObjects.bind(this),
      render: renderIcon(connectIcon),
      touchSizeX: 65,
      touchSizeY: 25,
      sizeX: 65,
      sizeY: 25
    });

    function renderIcon(icon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left - 20, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size*2.5, size);
        ctx.restore();
      }
    }
  }

  connectObjects(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
    let selectedItemsIds;

    if (this.canvas.getActiveObjects().length === 1 && this.canvas.getActiveObjects()[0].type === 'group') {
      // @ts-ignore
      selectedItemsIds = this.canvas.getActiveObject()._objects.map(obj => obj.id);
      this.connectedItems.push(selectedItemsIds);
    } else {
      // @ts-ignore
      selectedItemsIds = this.canvas.getActiveObjects().map(obj => obj.id);
      this.connectedItems.push(selectedItemsIds);
    }

    fabric.Object.prototype.controls['connectObjects'].setVisibility(false);
    fabric.Object.prototype.controls['disconnectObjects'].setVisibility(true);

    this.canvas.renderAll();
    return true;
  }

  addObjectsControlDisconnect() {
    const disconnectIcon = document.createElement('img');
    disconnectIcon.src = "assets/icons/editor/disconnect.png";

    fabric.Object.prototype.controls['disconnectObjects'] = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetY: -23,
      offsetX: 65,
      cursorStyle: 'grab',
      mouseUpHandler: this.disconnectObjects.bind(this),
      render: renderIcon(disconnectIcon),
      sizeX: 75,
      sizeY: 25,
      touchSizeX: 75,
      touchSizeY: 25
    });

    function renderIcon(icon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left - 20, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size*3, size);
        ctx.restore();
      }
    }
  }

  disconnectObjects(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
    // @ts-ignore
    let selectedItemsIds: any[];

    if (this.canvas.getActiveObjects().length === 1 && this.canvas.getActiveObjects()[0].type === 'group') {
      // @ts-ignore
      selectedItemsIds = this.canvas.getActiveObject()._objects.map(obj => obj.id);
      this.connectedItems = this.connectedItems.filter(currentArray => {
        return !currentArray.every((value) => selectedItemsIds.includes(value));
      });
    } else {
      // @ts-ignore
      selectedItemsIds = this.canvas.getActiveObjects().map(obj => obj.id);
      this.connectedItems = this.connectedItems.filter(currentArray => {
        return !currentArray.every((value) => selectedItemsIds.includes(value));
      });
    }

    fabric.Object.prototype.controls['connectObjects'].setVisibility(true);
    fabric.Object.prototype.controls['disconnectObjects'].setVisibility(false);

    this.canvas.renderAll();
    return true;
  }

  createCustomRotateControl() {
    const rotateIcon = document.createElement('img');
    rotateIcon.src = "assets/icons/editor/rotate.png";

    function renderIcon(rotateIcon: any) {
      return function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const size = 25;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(rotateIcon, -size/2, -size/2, size, size);
        ctx.restore();
      }
    }
    fabric.Object.prototype.controls['mtr'].render = renderIcon(rotateIcon);
  }

  onSetupControlsOnImageWithClipPath(image: any, rect: any) {
    const imageArea = image.oCoords!;
    const itl = imageArea.tl;
    const itr = imageArea.tr;
    const ibl = imageArea.bl;
    const ibr = imageArea.br;

    const visibleArea = rect.oCoords!;
    const vtl = visibleArea.tl;
    const vtr = visibleArea.tr;
    const vbl = visibleArea.bl;
    const vbr = visibleArea.br;

    const imageCenterX = (itl.x + itr.x) / 2;
    const imageCenterY = (itl.y + ibl.y) / 2;
    const visibleCenterX = (vtl.x + vtr.x) / 2;
    const visibleCenterY = (vtl.y + vbl.y) / 2;

    fabric.Image.prototype.controls['mt'].offsetX = visibleCenterX - imageCenterX;
    fabric.Image.prototype.controls['mt'].offsetY = Math.abs(itl.y - vtl.y);

    fabric.Image.prototype.controls['mr'].offsetX = -Math.abs(itr.x - vtr.x);
    fabric.Image.prototype.controls['mr'].offsetY = visibleCenterY - imageCenterY;

    fabric.Image.prototype.controls['mb'].offsetX = visibleCenterX - imageCenterX;
    fabric.Image.prototype.controls['mb'].offsetY = -Math.abs(ibr.y - vbr.y);

    fabric.Image.prototype.controls['ml'].offsetX = Math.abs(ibl.x - vbl.x);
    fabric.Image.prototype.controls['ml'].offsetY = visibleCenterY - imageCenterY;


    fabric.Image.prototype.controls['tl'].offsetX = Math.abs(itl.x - vtl.x);
    fabric.Image.prototype.controls['tl'].offsetY = Math.abs(itl.y - vtl.y);

    fabric.Image.prototype.controls['tr'].offsetX = -Math.abs(itr.x - vtr.x);
    fabric.Image.prototype.controls['tr'].offsetY = Math.abs(itr.y - vtr.y);

    fabric.Image.prototype.controls['br'].offsetX = -Math.abs(ibr.x - vbr.x);
    fabric.Image.prototype.controls['br'].offsetY = -Math.abs(ibr.y - vbr.y);

    fabric.Image.prototype.controls['bl'].offsetX = Math.abs(ibl.x - vbl.x);
    fabric.Image.prototype.controls['bl'].offsetY = -Math.abs(ibl.y - vbl.y);

    this.canvas.renderAll();
  }

  onResetControlsPosition() {
    fabric.Image.prototype.controls['mt'].offsetX = 0;
    fabric.Image.prototype.controls['mt'].offsetY = 0;
    fabric.Image.prototype.controls['mr'].offsetX = 0;
    fabric.Image.prototype.controls['mr'].offsetY = 0;
    fabric.Image.prototype.controls['mb'].offsetX = 0;
    fabric.Image.prototype.controls['mb'].offsetY = 0;
    fabric.Image.prototype.controls['ml'].offsetX = 0;
    fabric.Image.prototype.controls['ml'].offsetY = 0;
    fabric.Image.prototype.controls['tl'].offsetX = 0;
    fabric.Image.prototype.controls['tl'].offsetY = 0;
    fabric.Image.prototype.controls['tr'].offsetX = 0;
    fabric.Image.prototype.controls['tr'].offsetY = 0;
    fabric.Image.prototype.controls['br'].offsetX = 0;
    fabric.Image.prototype.controls['br'].offsetY = 0;
    fabric.Image.prototype.controls['bl'].offsetX = 0;
    fabric.Image.prototype.controls['bl'].offsetY = 0;
    this.canvas.renderAll();
  }

  onChangeNamePage(index: number) {
    this.pageForEditIndex = index;
  }

  renamePage() {
    this.savedPages[this.pageForEditIndex] = this.newPageName;
    this.newPageName = '';
    this.pageForEditIndex = 10000;
  }

  cancelRename() {
    this.pageForEditIndex = 10000;
  }

  showImageInfo(info: any) {
    this.displayedInfo.likes = info.likes;
    if (typeof info.tags === 'string') {
      this.displayedInfo.tags = info.tags.split(',');
    }
    if (Array.isArray(info.tags)) {
      this.displayedInfo.tags = info.tags;
    }
  }

  closeImageInfo() {
    this.displayedInfo.likes = 0;
    this.displayedInfo.tags = [];
  }

  onSelectTag(item: string) {
    // @ts-ignore
    this.searchContent[this.activeView] = item;
    this.onSearchContentByText(true);
    this.closeImageInfo();
    this.sidebarWrapper.nativeElement.scrollTo(0, 0);
  }

  onOpenAskHomiModal(isNew?: boolean) {
    if (isNew) {
      this.currentConversation = null;
      this.currentConversationID = '';
      this.openAiRequestText = ''
      this.scrollAiPosition = 0;
      this.openAiModalCollapsed = false;
    }
    this.openAiService.initWebSocketConnection();
    this.openAiService.messages.pipe(takeUntil(this.destroyed$)).subscribe((msg: any) => {
      //this.isLoading = false;
      this.AIMessagesLoader = false;
      const existingMessage = this.conversationsMessages.find(m => m.id === JSON.parse(msg).id);
      existingMessage ? existingMessage.text = JSON.parse(msg).text : this.conversationsMessages.push(JSON.parse(msg));

      if (JSON.parse(msg).isLastMessage) {
        this.afs
            .collection('users')
            .doc(this.user.uid)
            .collection('GPTConversations')
            .doc(this.currentConversationID)
            .set(this.currentConversation);
      }

      const messagesContainer = document.getElementById('messagesContainer');
      if (messagesContainer) {
        messagesContainer.scroll({
          top: messagesContainer.scrollHeight,
          left: 0
        })
      }
    });

    this.modalRef = this.modalService.show(this.askHomi,  {class: 'modal-xl modal-dialog-centered'});
    this.modalRef.onHide?.pipe(take(1)).subscribe(() => {
      this.scrollAiPosition = document.getElementById('messagesContainer')?.scrollTop;
      /*this.currentConversation = null;
      this.currentConversationID = '';
      this.openAiRequestText = '';*/
    })

    setTimeout(() => {
      const tabs = document.querySelectorAll('.tab');
      const checkboxes = Array.prototype.map.call(tabs, tab => {
        return tab.querySelector('input[type="checkbox"]');
      })
      checkboxes.forEach((checkbox: any) => {
        checkbox.addEventListener('change', () => {
          checkboxes.forEach((otherCheckbox: any) => {
            if (otherCheckbox !== checkbox) {
              otherCheckbox.checked = false;
            }
          });
        });
      });
    }, 0)

    setTimeout(() => {
      if (this.scrollAiPosition) {
        document.getElementById('messagesContainer').scrollTo(0, this.scrollAiPosition);
      }
    }, 100);
  }

  async openAiRequestNew() {
    this.AIMessagesLoader = true;
    //this.isLoading = true;
    const data = {
      text: this.openAiRequestText + '' + this.storyStyle,
      id: this.currentConversation && this.currentConversation.chat[this.currentConversation.chat.length - 1].id ? this.currentConversation.chat[this.currentConversation.chat.length - 1].id : ''
    }
    this.openAiService.messages.next(data);

    const tempUserObject = {
      role: 'user',
      text: this.openAiRequestText
    }
    this.conversationsMessages.push(tempUserObject);

    if (!this.currentConversation) {
      const newDocRef = this.afs.collection('users').doc(this.user.uid).collection('GPTConversations').doc();
      this.currentConversationID = newDocRef.ref.id;
      this.currentConversation = {
        id: this.currentConversationID,
        chat: this.conversationsMessages
      }
    }
    this.openAiRequestText = '';
  }

  onNewConversation() {
    if (this.storedOpenAiRequestText) {
      this.openAiRequestText = this.storedOpenAiRequestText;
    }
    this.currentConversation = null;
    this.currentConversationID = '';
    this.conversationsMessages = [];
    this.openAiModalCollapsed = false;
    this.scrollAiPosition = 0;
    //this.openAiRequestText = '';
  }

  closeOpenAiModal() {
    this.modalRef?.hide();
    this.openAiResponseData = '';
    this.openAiRequestText = '';
    this.currentConversation = null;
    this.currentConversationID = '';
    this.openAiModalCollapsed = false;
    this.scrollAiPosition = 0;
    this.conversationsMessages = [];
  }

  collapseOpenAiModal() {
    this.modalRef?.hide();
    this.openAiModalCollapsed = true;
  }

  tryAgainOpenAi() {
    this.openAiResponseData = '';
    this.openAiRequestText = '';
  }

  onSelectConversation(id: string) {
    if (this.openAiRequestText) {
      this.storedOpenAiRequestText = this.openAiRequestText;
      this.openAiRequestText = '';
    }

    this.currentConversationID = id;
    this.currentConversation = this.GPTConversations.find((conv: any) => conv.id === this.currentConversationID);
    this.conversationsMessages = this.currentConversation.chat;
  }

  onSelectStyle(event: any) {
    this.storyStyle = event.target.value;
  }

  openDeletionConversationModal(id: string) {
    this.conversationToDeleteId = id;
    this.modalRef = this.modalService.show(this.conversationDeletionConfirm,  {class: 'modal-sm modal-dialog-centered'});
  }

  deleteConversation() {
    this.afs.collection('users')
        .doc(this.user.uid)
        .collection('GPTConversations')
        .doc(this.conversationToDeleteId)
        .delete()
        .then(() => {
          this.modalRef?.hide();
        })
  }

  getBackgroundStyle(photoURL: string) {
    const style = `url(${photoURL})`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  removeHtmlTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  }

  onCheckTextareaValue() {
    if (this.openAiRequestText === null) {
      this.openAiRequestText = '';
    }
  }

  generateUUID() {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16;
      if(d > 0){
        r = (d + r)%16 | 0;
        d = Math.floor(d/16);
      } else {
        r = (d2 + r)%16 | 0;
        d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}
