import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import firebase from "firebase/compat/app";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {environment} from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AngularFireAuth, private router: Router) {}

  canActivate(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      firebase.initializeApp(environment.firebase);
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          resolve(true);
        } else {
          this.router.navigateByUrl('/sign-in', { replaceUrl: true }).then();
          reject(false);
        }
      });
    });
  }
}

