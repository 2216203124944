<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 d-md-block sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="view-wrapper">
        <h1 class="page-title">How to Use</h1>
        <h5>Video</h5>
        <p>Of the Mind Palace elements appearing one by one to reinforce the memory of the student.</p>
        <h5>Why study Political Science?</h5>
        <p>Not just to clear exams. But practical benefits. Do you want to get into Civil Services? Do you know what they do?</p>
        <h5>Norman Lewis</h5>
        <ul>
          <li>Read with urgency</li>
          <li>Read with purpose</li>
          <li>Broader to narrower</li>
          <li>Reading for Ideas</li>
          <li>Skimming</li>
          <li>Kinds of Passages - Chronology, disparate points, context setting/rationale</li>
          <li>Identify a structure</li>
        </ul>
        <h5>Best practices</h5>
        <ul>
          <li>Elaborative Encoding</li>
          <li>Verbosity - less words can deliver same message</li>
          <li>Keep practicing. Everything is a skill. You are bound to get better.</li>
          <li>Read when you are feeling energetic. When you "want" to learn. You can develop your mind to "want" to learn</li>
          <li>Change places</li>
          <li>Multi-sensory approach</li>
          <li>Set goals and reward yourself</li>
          <li>Design editor - Create a story. Putting disparate elements together won't work. Think of a scene or a story that naturally binds those elements together.</li>
        </ul>
        <h5>Self building</h5>
        <p class="m-0">Achieve full potential. Affirmations</p>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
