import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-ai-message-container',
  templateUrl: './ai-message-container.component.html',
  styleUrls: ['./ai-message-container.component.scss']
})
export class AiMessageContainerComponent implements OnInit {

  @Input() text: any;
  constructor() { }

  ngOnInit(): void {
  }

}
