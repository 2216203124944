import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {INavigationItem} from "../shared/models/navigation.model";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ISettings} from "../shared/models/settings.model";
import {IUser} from "../shared/models/user.model";
import {map, Subject, take, takeUntil} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {ILesson} from "../shared/models/lesson.model";
import {AuthService} from "../shared/services/auth.service";
import {environment} from "../../environments/environment.prod";
import {S3ServiceService} from "../shared/services/s3-service.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild('imageDeletionConfirm') imageDeletionConfirm!: TemplateRef<any>;
  @ViewChild('description') description!: ElementRef;

  modalRef?: BsModalRef;
  uploadSuccess = false;
  updatingStarted = false;
  isLoading = true;
  features: ISettings['features'] | undefined;
  users: IUser[] = [];
  libraryImage = {
    url: '',
    name: '',
    keywords: [],
    category: '',
    description: ''
  }
  imageUpdateIndex: number | null = null;
  imageIndexToDelete: number | null = null;
  previewImage = '';
  keyword = '';
  libraryImages: any[] = [];
  libraryImagesBackgrounds: any[] = [];
  lessonResult: any;
  cacheUpdateProgress = '';
  imageCategory = 'backgrounds';
  user!: IUser;

  navigation: INavigationItem[] = [
    { title: 'Settings', view: 'settings', img: 'settings', isVisible: false},
    { title: 'Users', view: 'users', img: 'settings', isVisible: false},
    { title: 'Lessons', view: 'lessons', img: 'settings', isVisible: false},
    { title: 'Files', view: 'files', img: 'settings', isVisible: false},
    { title: 'Library', view: 'library', img: 'settings', isVisible: false}
  ];

  activeView = this.user?.isAdmin ? 'lessons' : 'library';
  selectedFile: File | undefined | any;

  $destroy = new Subject();

  constructor(
    public afs: AngularFirestore,
    private authService: AuthService,
    public storage: AngularFireStorage,
    private s3Service: S3ServiceService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.onChangeView(this.activeView);
    this.authService.user$.subscribe(u => {
      this.user = u;
      this.navigation.forEach(nav => {
        nav.view !== 'library' ? nav.isVisible = this.user.isAdmin || false : nav.isVisible = this.user.isAdmin || this.user.isEditor || false;
      })
    })
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  onChangeView(view: string) {
    this.isLoading = true;
    this.activeView = view;
    if (view === 'settings') {
      this.onGetSettings();
    }
    if (view === 'users') {
      this.onGetUsers()
    }
    if (view === 'library') {
      this.getLibraryContent();
    }
    if (view === 'lessons' || view === 'files') {
      setTimeout(() => {
        this.isLoading = false;
      }, 500)
    }
  }

  onGetSettings() {
    this.afs
      .collection('settings')
      .doc('features')
      .get()
      .subscribe(resp => {
        this.features = resp.data() as any;
        this.isLoading = false;
      });
  }

  isFeatureActivated(feature: string) {
    // @ts-ignore
    return this.features[feature];
  }

  onToggleFeature(feature: any) {
    // @ts-ignore
    this.features[feature] = !this.features[feature];
    this.afs
      .collection('settings')
      .doc('features')
      // @ts-ignore
      .update(this.features)
      .then(() => this.authService.setFeatures(this.features));
  }

  getObjectKeys(obj: any) {
    return obj ? Object.keys(obj) : [];
  }

  onGetUsers() {
    this.afs
      .collection('users')
      .snapshotChanges()
      .pipe(map(j => j.map(i => i.payload.doc.data() as IUser)))
      .subscribe((resp: IUser[]) => {
        this.users.length = 0;
        this.users = resp;
        this.isLoading = false;
      });

    this.afs
      .collection('users')
      .doc('features')
      .get()
      .subscribe(resp => {
        this.features = resp.data() as any;
        this.isLoading = false;
      });
  }

  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    // @ts-ignore
    fileReader.readAsText(this.selectedFile, "UTF-8");
    fileReader.onload = () => {
      if (typeof fileReader.result === "string") {
        this.lessonResult = JSON.parse(fileReader.result);
      }
    }
    fileReader.onerror = (error) => {
      console.log(error);
    }
  }

  onRemoveFile() {
    this.lessonResult = null;
  }

  onUpload() {
    this.afs
      .collection('lessons')
      .doc()
      .set(this.lessonResult)
      .then(() => {
        this.onRemoveFile();
        this.uploadSuccess = true;
        setTimeout(() => this.uploadSuccess = false, 2000)
      })
  }

  onUpdateFilesCacheSettings() {
    this.updatingStarted = true;
    setTimeout(() => {this.cacheUpdateProgress += `<p>Cache updating started...</p>`;}, 300);
    setTimeout(() => {this.cacheUpdateProgress += `<p>Looking for used files in Firebase Storage...</p>`;}, 300);
    const files: string[] = [];
    this.afs
      .collection('lessons')
      .snapshotChanges()
      .pipe(
        map(j => {
          return j.map(i => {
            let data = i.payload.doc.data() as ILesson;
            return {id: i.payload.doc.id, ...data};
          });
        }),
        take(1)
      )
      .subscribe((resp: ILesson[]) => {
        resp.forEach(i => {
          if(i.lessonImage && !files.includes(i.lessonImage)) {
            files.push(i.lessonImage);
          }
          i.lessonSnippets.filter(s => s.narration && s.narration.includes('https://firebasestorage.googleapis.com')).forEach(s => {
            if(s.narration && !files.includes(s.narration)) {
              files.push(s.narration);
            }
          })
        })
        setTimeout(() => {this.cacheUpdateProgress += `<p>We have found ${files.length} files</p>`;}, 300);
        setTimeout(() => {this.cacheUpdateProgress += `<p>${ files.filter(f => f.includes('narration')).length} Narrations</p>`;}, 300);
        setTimeout(() => {this.cacheUpdateProgress += `<p>${ files.filter(f => !f.includes('narration')).length} LessonImages</p>`;}, 300);
        setTimeout(() => {this.cacheUpdateProgress += `<p>Please wait, we are setting proper cache setting...`;}, 300);

        files.forEach((f, index) => {
          this.storage
            .refFromURL(f)
            .updateMetadata({ cacheControl: 'public,max-age=604800' })
            .pipe(take(1))
            .subscribe(() => {
              if(index === files.length - 1) {
                this.cacheUpdateProgress += `<p>Done!`;
              }
            })
        })
      });
  }

  downloadUserInfo(user?: IUser) {
    const csvData = this.generateCsvString(user);

    this.downloadCsvFile(csvData, user?.email);
  }

  generateCsvString(user?: IUser): string {
    const csvHeader = 'First Name,Last Name,Email,Sign Up Date\n';
    let finalString = '';
    if (!user) {
      this.users.forEach(user => {
        const csvRow = `${user.firstName ? user.firstName : ''},${user.lastName ? user.lastName : ''},${user.email},${user.signUpDate ? user.signUpDate : ''}\n`;
        finalString = finalString + csvRow;
      })
    } else {
      const csvRow = `${user.firstName ? user.firstName : ''},${user.lastName ? user.lastName : ''},${user.email},${user.signUpDate ? user.signUpDate : ''}\n`;
      finalString = finalString + csvRow;
    }

    return csvHeader + finalString;
  }

  downloadCsvFile(data: string, email?: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = email ? `${email}.csv` : 'users.csv';

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  onAccessChange(event: any, user: IUser) {
    const userRef = this.afs.collection('users').doc(user.uid).ref;

    user.specialAccess = event.target.checked;
    userRef.set(user, {merge: true})
        .then(() => {
          console.log('User access has been changed')
        })
        .catch(error => {
          console.log(error);
        })
  }

  onEditorRoleChange(event: any, user: IUser) {
    const userRef = this.afs.collection('users').doc(user.uid).ref;

    user.isEditor = event.target.checked;
    userRef.set(user, {merge: true})
        .then(() => {
          console.log('User editor role has been changed')
        })
        .catch(error => {
          console.log(error);
        })
  }

  getLibraryContent() {
    this.afs.collection('library').doc('images').valueChanges().pipe(takeUntil(this.$destroy)).subscribe((resp: any) => {
      this.libraryImages = resp.images;
      this.isLoading = false;
    });
    this.afs.collection('library').doc('backgrounds').valueChanges().pipe(takeUntil(this.$destroy)).subscribe((resp: any) => {
      this.libraryImagesBackgrounds = resp.backgrounds;
    });
  }

  onFileDropped(files: any) {
    this.isLoading = true;
    const directory = 'images';
    Array.prototype.forEach.call(files, (file: any) => {
      if (file.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.isLoading = true;
          const newName = `${this.authService.user$.value.uid}-${this.generateUUID()}.jpeg`;
          const renamedFile = new File([file], newName, { type: file.type });
          //const dataUrl = event.target.result || '';
          this.s3Service.uploadFile(renamedFile, `${directory}/md`, renamedFile.name).then(() => {
            //this.libraryImage.url = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${renamedFile.name}`;
            this.previewImage = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${renamedFile.name}`;
            this.isLoading = false;
          })
        };
        reader.readAsDataURL(file);
      }
    })
  }

  downLoadFile(event:any) {
    this.onFileDropped(event.target.files);
  }

  generateUUID() {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16;
      if(d > 0){
        r = (d + r)%16 | 0;
        d = Math.floor(d/16);
      } else {
        r = (d2 + r)%16 | 0;
        d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  addKeyword() {
    // @ts-ignore
    this.libraryImage.keywords.push(this.keyword);
    this.keyword = '';
  }

  onDeleteKeyword(i: number) {
    this.libraryImage.keywords.splice(i, 1);
  }

  onSaveNewImage() {
    this.isLoading = true;
    this.libraryImage.url = this.previewImage;
    if (!this.libraryImage.name || !this.libraryImage.url || !this.libraryImage.category) {
      return;
    }

    if (this.imageCategory === 'backgrounds') {
      this.imageUpdateIndex !== null
          ? this.libraryImagesBackgrounds.splice(this.imageUpdateIndex, 1, this.libraryImage)
          : this.libraryImagesBackgrounds.unshift(this.libraryImage);
    } else {
      this.imageUpdateIndex !== null
          ? this.libraryImages.splice(this.imageUpdateIndex, 1, this.libraryImage)
          : this.libraryImages.unshift(this.libraryImage);
    }

    if (this.libraryImage.category === 'backgrounds') {
      this.afs.collection('library').doc('backgrounds').set({backgrounds: this.libraryImagesBackgrounds}, {merge: true}).then(() => {
        this.removeImageSelection();
        this.isLoading = false;
      })
    } else {
      this.afs.collection('library').doc('images').set({images: this.libraryImages}, {merge: true}).then(() => {
        this.removeImageSelection();
        this.isLoading = false;
      })
    }
  }

  onSelectImage(image: any, index: number) {
    this.libraryImage = image;
    this.previewImage = this.libraryImage.url;
    this.imageUpdateIndex = index;
  }

  removeImageSelection() {
    this.libraryImage = {
      url: '',
      name: '',
      keywords: [],
      category: '',
      description: ''
    };
    this.previewImage = '';
    this.imageUpdateIndex = null;
  }

  openConfirmationToDelete(event: any, index: number) {
    event.preventDefault();
    event.stopPropagation();
    this.imageIndexToDelete = index;
    this.modalRef = this.modalService.show(this.imageDeletionConfirm,  {class: 'modal-sm modal-dialog-centered'});
  }

  deleteImage() {
    this.isLoading = true;
    if (this.imageIndexToDelete !== null) {
      if (this.imageCategory === 'graphics') {
        this.libraryImages.splice(this.imageIndexToDelete, 1);
        this.afs.collection('library').doc('images').set({images: this.libraryImages}, {merge: true}).then(() => {
          this.removeImageSelection();
          this.isLoading = false;
        });
        this.modalRef?.hide();
      }
      if (this.imageCategory === 'backgrounds') {
        this.libraryImagesBackgrounds.splice(this.imageIndexToDelete, 1);
        this.afs.collection('library').doc('backgrounds').set({backgrounds: this.libraryImagesBackgrounds}, {merge: true}).then(() => {
          this.removeImageSelection();
          this.isLoading = false;
        });
        this.modalRef?.hide();
      }
    }
  }

  onFilterImages(category: string) {
    this.imageCategory = category;
  }

  onSelectCategory(cat: string) {
    this.libraryImage.category = cat;
  }

  onCheckHeight() {
    this.description.nativeElement.style.height = "5px";
    this.description.nativeElement.style.height = (this.description.nativeElement.scrollHeight) + "px";
  }
}
