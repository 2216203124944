<img alt="" src="assets/img/homi-logo.png" style="width:40px;">
<small [innerHTML]="item.instruction "></small>
<p class="mb-0 px-0 color-composition" [innerHTML]="item.shortCue"></p>
<div class="destination" #destination [innerHTML]="item.question"></div>
<hr class="border border-muted mt-0">
<div class="origin" #origin>
  <div class="word-wrapper" *ngFor="let word of item.words">
    <button class="word" (click)="onSelectWord($event)">{{word}}</button>
  </div>
</div>
<!--<h6 class="mb-0 px-0" [innerHTML]="item.question"></h6>
<div id="words" class="mt-4">
  <a href="" class="btn btn-info btn-small px-1 py-0 mr-2 mb-2" *ngFor="let value1 of item.words; let i = index"> {{ value1 }} </a>
</div>-->
