export interface ILesson {
  type?: string;
  category?: string,
  subCategory?: string,
  id?: string,
  lessonTitle?: string,
  lessonSubTitle?: string,
  lessonImage?: string,
  lessonSnippets: ILessonSnippet[],
  lessonCategory?: ELessonCategory,
  lessonSubCategory?: string
  version?: string;
  isFree?: boolean;
}

export interface ILessonSnippet {
  id?: string,
  parentId?: string,
  type?: ELessonSnippetType | string,
  isLottie?: boolean,
  animationSource?: string,
  quizType?: ELessonSnippetQuizType | string,
  instruction?: string,
  style?: string,
  cutSceneType?: string,
  shortCue?: string,
  fullPoint?: string,
  words?: any[],
  additionalWords?: any[],
  correctAnswer?: string[],
  animations?: ILessonSnippetAnimation,
  cueExplanation?: string,
  points?: string,
  pointsFor?: string,
  isVisible?: boolean,
  question?: string,
  answered?: string[],
  narration?: string,
  imageStyle?: string,
  arrow?: string,
  isDLPC?: string
}

export interface ILessonSnippetAnimation {
  default?: string,
  correctAnswer?: string,
  wrongAnswer?: string
}

export enum ELessonSnippetType {
  lesson = 'lesson',
  quiz = 'quiz',
}

export enum ELessonSnippetQuizType {
  wordBank = 'wordBank',
  fillInTheBlanks = 'fillInTheBlanks',
  writeAnswer = 'writeAnswer',
  speakAnswer = 'speakAnswer'
}

export enum ELessonCategory {
  Civics = 'Civics',
  History = 'History',
  Geography = 'Geography',
  Economics = 'Economics'
}
