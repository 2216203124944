import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  public benefits = ['Free trial', 'Multiple payment options', 'Safe and secure']
  public faq = [
    {
      title: 'Do you offer a free trial?',
      text:'Yes. We offer a few lessons for free when you sign up for an account with us - this would give you an idea about how our visual mnemonics lessons work and how effective they are for the student. \n\n Purchasing a subscription unlocks the rest of the lessons.'
    },
    {
      title: 'Do the subscription plans renew automatically?',
      text:'No, we will not make any automatic charges. When your plan expires, you will have to manually purchase a plan once again.'
    },
    {
      title: 'What is your refund or cancellation policy?',
      text:'If you feel our lessons are not for you, you can request for a cancellation by writing to us at hello@homi.school\n\n We offer a full refund for a period of 48 hours from the time of your purchase.'
    },
    {
      title: 'What are the payment options?',
      text:'We offer standard payment options such an Netbanking, Debit Card, Credit Card, UPI etc. We partner with a payment gateway provider who handle the payments.'
    },
    {
      title: 'Are my payment details safe?',
      text:'We do not collect or store any of your payment details. Our payment gateway partner, on whose website or app you will make the payment, employs state of the art security measures and is completely safe to use.'
    },
    {
      title: 'What kind of support do you provide?',
      text:'We are reachable on email and phone during working hours, five days a week. Please go to our contact us page for details on how to reach us.'
    }
  ]
  isLoading = true;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }

  openInfo(index: number) {
    const item = document.querySelectorAll('.faq__item')[index].querySelector('.text');
    const toggle = document.querySelectorAll('.faq__item')[index].querySelector('.toggle');
    if (item?.classList.contains('text__visible')){
      item.classList.remove('text__visible');
      toggle?.classList.remove('toggle__down');
    } else {
      item?.classList.add('text__visible');
      toggle?.classList.add('toggle__down');
    }

  }

}
