import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {forkJoin, map, mergeMap} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class ImageApiService {

  constructor(private http: HttpClient, private afs: AngularFirestore) { }
    /*freepikApiUrl = 'https://api.freepik.com/v1/resources?locale=en-GB&page=1&limit=8&order=priority&filters[license][premium]=1';
    freepikApiKey = 'UkkNgTeOr5HpFVVMBTdgwPhP1NHWMxAInWFmocVt6bGWnqXo';*/
    pixabayApiUrl = 'https://pixabay.com/api/';
    pixabayApiKey = '36258516-a417a19e9f119b04a5aaf3f32';
    shutterstockApiUrl = 'https://api.shutterstock.com/v2/images/search';
    shutterstockAuthToken = 'v2/U2Z0eHpmRUNRbk9QUVFRcXZkR0VLc1cwcnZmSTM0RlYvMjIzMjU0OTE5L2N1c3RvbWVyLzQvdkM2ZmFEZkpnTGl4WHV4bHgwYnFJVzRWZmNHbEg4QVlaVk4yVnNLR3h4WjI5TDdOMDlRZ0ZYOHpHQ2tNZVU1anRmTUx1Uk8tRFBvOWtIdlVaNy00MkFjZm9ReWlQZFJDdGxUTEF6dmtEZEt3cHh2d0pLamIyQk1QY1ZqY2xDMzZMXzBvdUw1M1l2OWJ1cC1nZDdBejliaHNjS3R4b0VGaTNUTVBPZnFLZFN2aVFmMVpaVmJrZDBzcGhqX1R3WWZuOTdNZUVPOWdxOXZSS3ZEZTI5S0Vsdy92VjVCbmNoTy14ME1Xd1BHRW4xX2xn';


    /*getImagesFromFreePik(options: any) {
        const headers = new HttpHeaders()
            .set('Accept-Language', 'en-GB')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('X-Freepik-API-Key', this.freepikApiKey);
        const reaustUrl = `https://satoshi-cors.herokuapp.com/https://api.freepik.com/v1/resources?locale=en-GB&page=${options.page}&limit=10&order=priority&filters[license][premium]=1&filters[content_type][vector]=1&filters[content_type][psd]=1&term=${options.q}`

        return this.http.get(reaustUrl, { headers });
    }*/

  getImagesFromPixabay(options: any) {
      let params = new HttpParams();
      params = params.set('key', this.pixabayApiKey);
      params = params.set('q', options.q);
      params = params.set('image_type', options.image_type);
      params = params.set('orientation', options.orientation);
      params = params.set('page', options.page);
      params = params.set('per_page', 10);
      params = params.set('category', options.category);
      params = params.set('safesearch', true);

      return this.http.get(this.pixabayApiUrl, {params});
  }

    getImagesFromShutterstock(options: any) {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.shutterstockAuthToken}`
        });

        let params = new HttpParams()
            .set('query', options.q)
            .set('image_type', 'vector')
            .set('image_type', 'illustration')
            .set('page', options.page)
            .set('per_page', '10')
            .set('sort', 'popular')
            .set('language', 'en');

        if (options.orientation === 'all') {
            params = params.set('orientation', 'vertical');
            params = params.set('orientation', 'horizontal');
        } else {
            params = params.set('orientation', 'horizontal');
        }

        return this.http.get(this.shutterstockApiUrl, { headers, params })
            .pipe(
                mergeMap((imagesResponse: any) => {
                    const imageIds = imagesResponse.data.map((image: any) => image.id);
                    let innerParams = new HttpParams()
                        .set('language', 'en')

                    const imageDetailRequests = imageIds.map((imageId: string) => {
                        return this.http.get(`https://api.shutterstock.com/v2/images/${imageId}`, { headers, params: innerParams });
                    });

                    return forkJoin(imageDetailRequests);
                })
            );
    }

    getHomiImages(options: any) {
        return this.afs.collection('library').doc('images').valueChanges()
            .pipe(map((res: any) => {
                return res.images
                    .filter((item: any) => {
                        return item.name?.toLowerCase().includes(options.q.toLowerCase())
                            || item.keywords?.includes(options.q.toLowerCase())
                            || item.description?.toLowerCase().includes(options.q.toLowerCase());
                    }).slice((options.page - 1) * 10, (options.page * 10) - 1);
            }));
    }

    getHomiImagesBackgrounds(options: any) {
        return this.afs.collection('library').doc('backgrounds').valueChanges()
            .pipe(map((res: any) => {
                return res.backgrounds
                    .filter((item: any) => {
                        return item.name?.toLowerCase().includes(options.q.toLowerCase())
                            || item.keywords?.includes(options.q.toLowerCase())
                            || item.description?.toLowerCase().includes(options.q.toLowerCase());
                    }).slice((options.page - 1) * 10, (options.page * 10) - 1);
            }));
    }

    getRandomHomiImages(): Promise<any> {
        return new Promise<any[]>((resolve, reject) => {
            let data: any = {};
            this.afs.collection('library').get().subscribe({
                next: (res) => {
                    res.docs.forEach((array) => {
                        if ((array.data() as any).backgrounds) {
                            data.backgrounds = this.shuffleAndTakeTenItems((array.data() as any).backgrounds).map((item: any) => {
                                return {
                                    preview: item.url,
                                    image: item.url,
                                    info: {
                                        tags: item.keywords
                                    }
                                }
                            });
                        }
                        if ((array.data() as any).images) {
                            data.graphics = this.shuffleAndTakeTenItems((array.data() as any).images).map((item: any) => {
                                return {
                                    preview: item.url,
                                    image: item.url,
                                    info: {
                                        tags: item.keywords
                                    }
                                }
                            });
                        }
                    });
                    resolve(data);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    }

    shuffleAndTakeTenItems(arr: []) {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }

        return arr.slice(0, 20);
    }
}
