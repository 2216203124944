export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBTDC2YP0eVHs0mMFW7T1SvJUR4shL4kIw',
    authDomain: 'homi-dev-64a26.firebaseapp.com',
    projectId: 'homi-dev-64a26',
    storageBucket: 'homi-dev-64a26.appspot.com',
    messagingSenderId: '363192685645',
    appId: '1:363192685645:web:c99b2dc17be2dd95c53804'
  },
  AWS: {
    ACCESS_KEY_ID: "AKIA6QOC5YOFRKRYVMU5",
    SECRET_ACCESS_KEY: "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",
    REGION: "ap-south-1",
    CLOUDFRONT: "d1f5m9ybhzznuh.cloudfront.net",
    API: "https://jiizbcu41m.execute-api.eu-north-1.amazonaws.com/prod",
    ORIGIN_DOMAIN: "homi.s3.ap-south-1.amazonaws.com"
  }
};
