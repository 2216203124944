// @ts-nocheck
import {Component, HostListener, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ELessonSnippetQuizType, ELessonSnippetType, ILesson} from '../../shared/models/lesson.model';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {DOCUMENT} from '@angular/common';
import {IMastery} from '../../shared/models/mastery.model';
import {AuthService} from '../../shared/services/auth.service';
import {IDlpc} from '../../shared/models/dlpc.model';
import {IUserSession} from "../../shared/models/user.model";
import {combineLatest, filter, first, ReplaySubject, takeUntil} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.scss']
})
export class LessonComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event) {
    if(this.isMobile) {
      this.isPortrait = event.target.innerHeight < event.target.innerWidth;
      if(!this.isPortrait && !this.loadingStarted) {
        this.onLoad();
      }
    }
  }
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  user: any;
  helloLessonId = 'kznd4fzrLlSWYGM33c9o';
  loadingStarted = false;
  isPortrait = true;
  isMobile = false;
  isFullscreen = false;
  dlpcConfiguration: IDlpc;
  lesson: ILesson;
  modalRef?: BsModalRef;
  answer = '';
  wordBankStr = '';
  wrongAnswers = 0;
  questionsCount = 0;
  correctAnswers = 0;
  selectedSnippet = 0;
  result: 'correct' | 'wrong' | null = null;
  isFinished = false;
  isFullScreen = false;
  fillArr = [];
  lessonsPlan: any[] = [];
  lessonId = '';
  lessonCategory = '';
  elem: any;
  mastery: IMastery[];

  audioVolume = this.authService.user$.value.settings.audio === 'Enabled' ? 0.2 : 0;

  seconds: number = 0;
  interval;

  startTime: Date;
  endTime: Date;

  isSuccessfullyCompleted: boolean;
  isNavigationHidden = false;
  isExitButtonDisabled = false;

  get isCheckDisabled() {
    const snippet = this.lesson.lessonSnippets[this.selectedSnippet];
    if (snippet.quizType === ELessonSnippetQuizType.wordBank && this.wordBankStr.length > 0) {
      return false
    } else if (snippet.quizType === ELessonSnippetQuizType.fillInTheBlanks && this.fillArr.length > 0) {
      return false
    } else if (snippet.quizType === ELessonSnippetQuizType.writeAnswer && this.answer.length > 0) {
      return false
    } else if (snippet.quizType === ELessonSnippetQuizType.speakAnswer && this.answer.length > 0) {
      return false
    }
    return true
  }

  private config = {
    answer: {
      correct: {audio: '../../../assets/sound-effects/correct.mp3'},
      wrong: {audio: '../../../assets/sound-effects/wrong.mp3'}
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private modalService: BsModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private db: AngularFirestore
  ) {}

  ngOnInit(): void {

    this.document.body.classList.add('mob');
    const user =  this.authService.getUser().pipe(filter(u => !u.initial)).pipe(takeUntil(this.destroyed$));
    const general = this.authService.getGeneral().pipe(filter(g => !g.initial)).pipe(first());
    const features = this.authService.getFeatures().pipe(filter(g => !g.initial)).pipe(first());
    combineLatest(user, general,features).subscribe(([user, general, features]) => {
      this.dlpcConfiguration = general.dlpc;
      this.user = user;
      this.isMobile = window.innerWidth < 800;
      this.isPortrait = !(window.innerHeight < window.innerWidth);
      this.mastery = user.mastery ? user.mastery : [];
      this.isFullscreen = features.isLessonFullscreen;
      if (this.isMobile && !this.isPortrait) {
        this.onLoad();
      } else {
        this.onLoad();
      }
    })
  }

  onLoad() {
    this.loadingStarted = true;
    this.elem = document.documentElement;
    this.db
      .collection('lessons')
      .doc(this.activatedRoute.snapshot['_urlSegment']['segments'][1]['path'])
      .get()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((resp: any) => {
        this.lessonCategory = resp.data().category;
        this.lessonId = resp.id;
        if ((resp.data() as ILesson).type && (resp.data() as ILesson).type === 'DLPC') {
          const lesson = resp.data() as ILesson;
          lesson.id = resp.id;
          this.DLPC(lesson);
        } else {
          this.lesson = resp.data() as ILesson;
          this.startTimer();
        }
        this.questionsCount = this.lesson.lessonSnippets.filter(l => l.type === ELessonSnippetType.quiz).length;
        this.onGetLesson();
      });
  }

  get lessonProgress() {
    return (this.selectedSnippet / (this.lesson.lessonSnippets.length / 100)).toFixed();
  }

  onGetLesson() {
    this.hideAllSnippets();
    this.lesson.lessonSnippets[0].isVisible = true;
  }

  onGetLessonImage() {
    return this.lesson.lessonImage;
    /*const isFirebase = this.authService.features$.value.isFirebase;
    const image = this.lesson.lessonImage;
    const firebaseSource = `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/images%2F`;
    const source = '../../../assets/lessons/images/';

    if (image?.includes(firebaseSource)) {
      return isFirebase ? image : source + image.replace(firebaseSource, '').split('?alt=media')[0]
    } else {
      return source + image;
    }*/
  }

  hideAllSnippets() {
    this.lesson.lessonSnippets.forEach(i => i.isVisible = false);
    this.result = null;
    this.wordBankStr = '';
    this.answer = '';
    this.fillArr.length = 0;
  }

  onChangeSnippet(direction: 'prev' | 'next') {
    console.log(direction)
    if(this.lesson.lessonSnippets[this.selectedSnippet].type !== 'not_lesson') {
      this.onUpdateMastery(this.dlpcConfiguration.masteryScore)
    }
    this.hideAllSnippets();
    if (this.selectedSnippet + 1 === this.lesson.lessonSnippets.length && direction === 'next') {
      ++this.selectedSnippet;
      this.isFinished = true;
      if(this.lessonId === this.helloLessonId) {
        this.authService.user$.value.isOnboardingCompleted = true;
        this.authService.SetUserData(this.user).then()
      }
      this.onUpdateUserMastery()
      this.isSuccessfullyCompleted = this.isLevelRaise;
    } else {
      this.lesson.lessonSnippets[this.selectedSnippet]['isFinished'] = true;
      this.selectedSnippet = direction === 'prev'
          ? this.selectedSnippet - 1
          : this.selectedSnippet + 1;
      this.lesson.lessonSnippets[this.selectedSnippet].isVisible = true;
    }
  }

  onSkip() {
    this.onShowResult('wrong');
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm modal-dialog-centered'});
  }

  onExitConfirm() {
    this.isExitButtonDisabled = true;
    if(this.lessonId === this.helloLessonId && !this.user.isOnboardingCompleted) {
      this.user.isOnboardingCompleted = true;
      this.modalRef?.hide();
      this.authService.SetUserData(this.user).then(() => {
        this.router.navigate(['home']).then(() => {
          this.isExitButtonDisabled = false;
        });
      })
    } else {
      this.modalRef?.hide();
      this.router.navigate(['home'], {
        queryParams: {
          activeCategory: this.lessonCategory
        }
      }).then(() => {
        this.isExitButtonDisabled = false;
      });
    }
  }

  onGoBackToHome() {
    this.router.navigate(['home'], {
      queryParams: {
        activeCategory: this.lessonCategory
      }
    }).then();
  }

  onCheck() {
    const snippet = this.lesson.lessonSnippets[this.selectedSnippet];
    if (snippet.type === ELessonSnippetType.quiz) {
      if (snippet.quizType === ELessonSnippetQuizType.wordBank) {
        if (this.wordBankStr.trim().toLowerCase() === snippet.correctAnswer[0].trim().toLowerCase()) {
          this.onShowResult('correct');
        } else {
          this.onShowResult('wrong');
        }
      }
      if (snippet.quizType === ELessonSnippetQuizType.fillInTheBlanks) {
        if (this.fillArr.length === snippet.correctAnswer.length && this.fillArr.every((value, index) => {
          const regEx = '/[,-.:]/gm';
          return value.replace(regEx, '').trim() === snippet.correctAnswer[index].replace(regEx, '').trim();
        })) {
          this.mastery.find(m => m.id === snippet.id)
          this.onShowResult('correct');
        } else {
          this.onShowResult('wrong');
        }
      }
      if (snippet.quizType === ELessonSnippetQuizType.speakAnswer) {
        if(Array.isArray(snippet.correctAnswer)) {
          if (snippet.correctAnswer?.find(i => i.toLowerCase() === this.answer.toLowerCase())) {
            this.onShowResult('correct');
          } else {
            this.onShowResult('wrong');
          }
        } else {
          if (snippet.correctAnswer.toLowerCase() === this.answer.toLowerCase()) {
            this.onShowResult('correct');
          } else {
            this.onShowResult('wrong');
          }
        }
      }

      if (snippet.quizType === ELessonSnippetQuizType.writeAnswer) {
        if(Array.isArray(snippet.correctAnswer)) {
          if (snippet.correctAnswer?.find(i => i === this.removePunctuation(this.answer.trim().toLowerCase()))) {
            this.onShowResult('correct');
          } else {
            this.onShowResult('wrong');
          }
        } else {
          if (snippet.correctAnswer.toLowerCase() === this.removePunctuation(this.answer.trim().toLowerCase())) {
            this.onShowResult('correct');
          } else {
            this.onShowResult('wrong');
          }
        }
      }
    }
  }

  removePunctuation(str: string) {
    return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
  }

  public playEffect(result: 'correct' | 'wrong') {
    let audio = new Audio();
    audio.src = this.config.answer[result].audio;
    audio.volume = this.audioVolume;
    audio.load();
    audio.play().then();
  }

  onShowResult(result: 'correct' | 'wrong') {
    let masteryScore = this.dlpcConfiguration.masteryScore;
    this.result = result;
    setTimeout(() => this.playEffect(result), 0)
    result === 'correct' ? ++this.correctAnswers : ++this.wrongAnswers;
    this.onUpdateMastery(result === 'correct' ? masteryScore : Math.abs(masteryScore) * -1)
  }

  DLPC(lesson: ILesson) {
    const LSMatrix: any[] = [];
    lesson.lessonSnippets.forEach((snippet, index) => {
      snippet.id = lesson.id + 'S' + (index + 1)
    });

    lesson.lessonSnippets
      .filter(LS => LS.type === 'lesson')
      .forEach((LS) => LSMatrix.push(this.getActionByMasteryScore(LS)));

    LSMatrix.forEach((LS, index) => {
      const LP = lesson.lessonSnippets[index];
      if (LS[1]) {
        this.lessonsPlan.push(lesson.lessonSnippets[index])
      } // Add Lesson Or Not
      if (this.getProbabilityResult(LS[2])) {
        if (LS[4] !== -1) {
          this.lessonsPlan = this.insert(this.lessonsPlan, index + LS[4], this.onGetActivity(LS, LP));
        } else {
          this.lessonsPlan = this.insert(this.lessonsPlan, this.getRandomPosition(this.lessonsPlan.length), this.onGetActivity(LS, LP));
        }
      }
    });

    this.lesson = {
      category: lesson.category,
      subCategory: lesson.subCategory,
      version: lesson.version,
      lessonTitle: lesson.lessonTitle,
      lessonSubTitle: lesson.lessonSubTitle || '',
      lessonImage: lesson.lessonImage,
      lessonSnippets: this.lessonsPlan
    }
    this.startTimer();
  }

  /**
  getActionByMasteryScore(lessonSnippet: any): any[] {
    let score = 0;
    const masteryGrade = this.dlpcConfiguration.masteryGrade.sort((a, b) => (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0))
    const mastery = this.mastery.find(m => m.id === lessonSnippet.id);
    !mastery ? this.mastery.push({id: lessonSnippet.id, score: 0}) : score = mastery.score;
    if(lessonSnippet.words.length > 1) {
      if (score >= masteryGrade[0].min && score <= masteryGrade[0].max) {
        return [lessonSnippet.id, true, 33, 'fillInTheBlanks', 2];
      } else if (score > masteryGrade[1].min && score <= masteryGrade[1].max) {
        return [lessonSnippet.id, true, 67, 'fillInTheBlanks', this.getProbabilityResult(50) ? 2 : 3];
      } else if (score > masteryGrade[2].min && score <= masteryGrade[2].max) {
        return [lessonSnippet.id, this.getProbabilityResult(67), 100, 'fillInTheBlanks', this.getRandom([2, 3, 4])];
      } else if (score > masteryGrade[3].min && score <= masteryGrade[3].max) {
        return [lessonSnippet.id, this.getProbabilityResult(33), 100, this.getRandom(['fillInTheBlanks', 'writeAnswer']), -1];
      } else if (score > masteryGrade[4].min && score <= masteryGrade[4].max) {
        return [lessonSnippet.id, false, 100, this.getRandom(['fillInTheBlanks', 'writeAnswer', 'speakAnswer']), 0];
      } else if (score > masteryGrade[5].min && score <= masteryGrade[5].max) {
        return [lessonSnippet.id, false, 100, this.getRandom(['writeAnswer', 'speakAnswer']), 0];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  */

  getActionByMasteryScore(lessonSnippet: any): any[] {
    let score = 0;
    const masteryGrade = this.dlpcConfiguration.masteryGrade.sort((a, b) => (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0))
    const mastery = this.mastery.find(m => m.id === lessonSnippet.id);
    !mastery ? this.mastery.push({id: lessonSnippet.id, score: 0}) : score = mastery.score;
    if (score >= masteryGrade[0].min && score <= masteryGrade[0].max) {
      return [lessonSnippet.id, true, 10, 'fillInTheBlanks', 1];
    } else if (score > masteryGrade[1].min && score <= masteryGrade[1].max) {
      return [lessonSnippet.id, true, 20, 'fillInTheBlanks', 1];
    } else if (score > masteryGrade[2].min && score <= masteryGrade[2].max) {
      return [lessonSnippet.id, true, 30, 'fillInTheBlanks', 1];
    } else if (score > masteryGrade[3].min && score <= masteryGrade[3].max) {
      return [lessonSnippet.id, true, 40, 'fillInTheBlanks', 1];
    } else if (score > masteryGrade[4].min && score <= masteryGrade[4].max) {
      return [lessonSnippet.id, true, 50, 'fillInTheBlanks', 1];
    } else if (score > masteryGrade[5].min && score <= masteryGrade[5].max) {
      return [lessonSnippet.id, true, 60, 'fillInTheBlanks', 1];
    } else {
      return [];
    }
  }

  onGetActivity(LS: any, LP: any): any {
    const activity = {
      id: LP['id'] + 'Q',
      parentId: LP['id'],
      quizType: LS[3],
      shortCue: LP['shortCue'],
      words: [],
      additionalWords: [],
      instruction: this.onGetInstruction(LS[3]),
      type: 'quiz',
      style: 'width:300px;position:absolute;top:100px;left:300px;',
      isDLPC: true,
      DLPCPercentage: LS[2],
      question: ''
    }

    if (LS[3] === 'fillInTheBlanks') {
      activity.correctAnswer = [];
      const wordsByImportance = [...LP.words].sort((a: any, b: any) => a.importance - b.importance).reverse().map((w: any) => w.word);
      const wordsCount = Number(LS[2] / (100 / wordsByImportance.length)).toFixed();
      const additionalWordsCount = 2;
      const questionWords = [];

      activity.words = [...new Set(wordsByImportance)].slice(0, wordsCount);

      [...new Set(LP.words.map((w: any) => w.word))].forEach((w: any) => {
        if (activity.words.includes(w)) {
          activity.correctAnswer.push(w);
        }
      })

      LP.words.forEach((w: any) => {
        activity.question += ' ' + (questionWords.includes(w.word) ? w.word : (!activity.words.includes(w.word) ? w.word : '<span class=\'blank\'></span>'));
        questionWords.push(w.word);
      })
      for (let i = 0; i < additionalWordsCount; i++) {
        activity.additionalWords.push(this.dlpcConfiguration.wordBank[Math.floor(Math.random() * this.dlpcConfiguration.wordBank.length)])
      }
    } else if (LS[3] === 'writeAnswer') {
      let correct = [];
      LP.words.forEach((w: any) => {
        correct.push(w.word);
      })
      activity.correctAnswer = correct.join(' ');
    } else if (LS[3] === 'speakAnswer') {
      let correct = [];
      LP.words.forEach((w: any) => {
        correct.push(w.word);
      })
      activity.correctAnswer = correct.join(' ');
    }
    return activity;
  }

  onGetInstruction(type: string): string {
    let instruction = '';
    if (type === 'fillInTheBlanks') {
      instruction = 'Fill in the blanks'
    }
    if (type === 'writeAnswer') {
      instruction = 'Type the full point'
    }
    if (type === 'speakAnswer') {
      instruction = 'Speak the full point'
    }
    return instruction;
  }

  getProbabilityResult(probability: number) {
    return probability >= Math.floor(Math.random() * (Math.floor(100) - Math.ceil(1) + 1)) + Math.ceil(1);
  }

  getRandomPosition(length: number): number {
    return Math.floor(Math.random() * (Math.floor(length) - Math.ceil(0) + 1)) + Math.ceil(0)
  }

  getRandom(arr: string[] | number[]): any {
    return arr[Math.floor(Math.random() * arr.length)]
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    this.isFullScreen ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
  }

  onUpdateMastery(score: number) {
    const activeSnippet = this.lesson.lessonSnippets[this.selectedSnippet];
    if (!activeSnippet['isFinished']) {
      const currentMastery = this.mastery.find(m => m.id === (activeSnippet.type === 'lesson' ? activeSnippet.id : activeSnippet.parentId));
      if(currentMastery) {
        let masteryScore = (currentMastery ? Number(Number(currentMastery.score) + score).toFixed(2) : 0);
        if (masteryScore > 1) {
          masteryScore = 1
        } else if (masteryScore < 0) {
          masteryScore = 0
        }
        currentMastery.score = masteryScore;
        this.localStorageUpdate();
      }
    }
  }

  localStorageUpdate(isLast?: boolean) {
    delete this.user.mastery;
    this.user.mastery = this.mastery;
    if(isLast) {
      const session: IUserSession = {
        startTime: this.startTime,
        endTime: this.endTime,
        result: this.isLevelRaise ? 'complete' : 'incomplete'
      };
      this.user.sessions ? this.user.sessions.push(session) : this.user.sessions = [].push(session);
    }
  }

  onUpdateUserMastery() {
    this.pauseTimer();
    let lessonMastery = this.mastery.find(m => m.id === this.lessonId);
    if (lessonMastery) {
      lessonMastery.timeSpent += this.seconds;
      if(this.isLevelRaise) {
        ++lessonMastery.iterations;
      }
    } else {
      this.mastery.push({id: this.lessonId, timeSpent: this.seconds, iterations: this.isLevelRaise ? 1 : 0});
    }
    this.localStorageUpdate(true);
    this.authService.SetUserData(this.user).then()
  }

  get isLevelRaise(): boolean {
    const percentage = this.dlpcConfiguration.levelRaisePercentage;
    return Math.round(this.correctAnswers * (100 / this.questionsCount)) >= percentage;
  }

  insert(arr: any[], index: number, newItem: any) {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)]
  }

  startTimer() {
    this.startTime = new Date().toISOString();
    this.interval = setInterval(() => {
      if (this.seconds < 600) {
        this.seconds++;
      } else {
        this.seconds = 600;
      }
    }, 1000)
  }

  pauseTimer() {
    this.endTime = new Date().toISOString();
    clearInterval(this.interval);
  }

  navbarAnimation() {
    this.isNavigationHidden = !this.isNavigationHidden;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

