import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {timezones} from '../../../assets/timezones';
import {combineLatest, filter, of, take} from "rxjs";
import {IUser} from "../../shared/models/user.model";
import {AngularFireStorage, AngularFireStorageReference} from "@angular/fire/compat/storage";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  timezonesList = timezones;
  isFirst = true;
  isLoading = true;
  isInformationUpdated = false;
  isPhotoUpdated = false;
  success = false;
  error = false;
  // @ts-ignore
  user: IUser;
  imageType!: string;

  constructor(
    public authService: AuthService,
    private fireStorage: AngularFireStorage
  ) {}

  ngOnInit(): void {
    this.authService.getUser()
      .pipe(
        filter(u => Object.keys(u).length > 0),
        take(1)
      )
      .subscribe((resp: IUser) => {
        this.user = {...resp};
        this.isLoading = false
        this.isFirst = false;
      });
  }

  onSave() {
    this.isPhotoUpdated ? this.addUserImg() : this.onUpdateUserInfo();
  }

  onCancel() {
    this.user = this.authService.user$.value;
    this.isInformationUpdated = false;
  }

  onUpdateUserInfo() {
    this.isLoading = true;
    this.authService.SetUserData(this.user).then(() => {
      this.isInformationUpdated = false;
      this.success = true;
      this.isLoading = false;
      setTimeout(() => {
        this.success = false;
      }, 3000)
    }).catch(() => {
      this.error = true;
      this.isLoading = false;
      setTimeout(() => {
        this.error = false;
      }, 3000)
    })
  }

  onSelectPhoto(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.imageType = event.target.files[0].type;
      reader.onload = (event) => {
        // @ts-ignore
        this.user.photoURL = event.target.result;
        this.isInformationUpdated = true;
        this.isPhotoUpdated = true;
      }
    }
  }

  addUserImg() {
    this.isLoading = true;
    combineLatest([this.user.photoURL].map(img => {
      const userId = this.user.uid;
      const newName = `avatar.jpeg`;
      const storageRef: AngularFireStorageReference = this.fireStorage.ref(`/files/${userId}/${newName}`);
      return of({
        task: storageRef.putString(img.replace(`data:${this.imageType};base64,`, ''), 'base64', { contentType: 'image/jpeg' }),
        ref: storageRef
      });
    })).subscribe( (resp: any) => {
      Promise.all(resp.map((i: { task: any; }) => i.task))
        .then(() => {
          combineLatest(resp.map((i: { ref: { getDownloadURL: () => any; }; }) => i.ref.getDownloadURL()))
            .subscribe(img => {
              // @ts-ignore
              img.forEach(i => this.user.imgUrl = i as string);
              this.onUpdateUserInfo();
            });
        });
    });
  }

}
