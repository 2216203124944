<div class="sidebar-sticky" *ngIf="!isLoading && user">
  <div class="user-greeting" *ngIf="authService.userData">
    <div class="user-greeting__avatar"
         [ngStyle]="{'background-image': 'url(' + (authService.userData.photoURL ? authService.userData.photoURL : '../../assets/img/homi-logo.png') + ')'}">
    </div>
    <p class="user-greeting__text">Welcome<strong *ngIf="authService.userData.firstName">, {{authService.userData.firstName}}</strong></p>
    <img *ngIf="user.access.status === 'Active'"
         class="user-greeting__icon"
         tooltip="You are a premium member"
         placement="top" src="assets/img/premium.png"
         alt="premium"
    >
  </div>
  <ul class="nav flex-column">
    <ng-container *ngFor="let item of navigation">
      <li class="nav-item"
          *ngIf="item.isVisible"
          [ngClass]="{'active': activeView === item.view}">
        <a class="nav-link"
           (click)="onChangeView(item.view)">
          <img class="nav-link__icon" [src]="'assets/icons/navigation/' + item.img + '.png'" alt="">
          {{item.title}}
          <img *ngIf="item.img === 'contact'" src="assets/icons/maximize.png" style="width: 20px; height: 20px; margin-top: -1px;">
        </a>
      </li>
    </ng-container>
  </ul>
</div>
