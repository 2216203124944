import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {INavigationItem} from '../../../shared/models/navigation.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;
  isLoading = true;
  isLoggedIn!: boolean;
  isEditorPage!: boolean;
  navigation: INavigationItem[] = [
    // {title: 'Home', view: 'home', img: 'home', isVisible: true},
    // {title: 'How to use', view: 'how-to-use', img: 'help', isVisible: true},
    // {title: 'Achievements', view: 'achievements', img: 'achievements', isVisible: true},
    // {title: 'Leaderboard', view: 'leaderboard', img: 'leaderboard', isVisible: true},
    // {title: 'Settings', view: 'settings', img: 'settings', isVisible: true},
    // {title: 'Pricing', view: 'pricing', img: 'pricing', isVisible: true},
    // {title: 'Billing', view: 'billing', img: 'billing', isVisible: true},
    // {title: 'Contact us', view: 'help', img: 'contact', isVisible: true}
  ];

  activeView = 'home';

  constructor(
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.user$.subscribe(user => {
      this.isLoggedIn = !!user?.email;
    });
    this.onUpdateAvailability(this.authService.features$.value);
    this.isEditorPage = this.router.url.includes('editor');
  }

  onChangeView(view: string) {
    this.router.navigate(['/' + view]).then()
  }

  onUpdateAvailability(features: any) {
    if(features) {
      Object.keys(features).forEach(f => {
        if (f !== 'isLessonFullscreen' && f !== 'isFirebase' && f !== 'isQuestionBased') {
          // @ts-ignore
          const v = this.navigation.find(i => i.view === f);
          if(v) { v.isVisible = features[f]}
        }
      })
    }
    this.isLoading = false;
  }

  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }
}
