import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ILessonSnippet} from "../../../shared/models/lesson.model";
import {UtilityService} from "../../../shared/services/utility.service";

@Component({
  selector: 'app-fill',
  templateUrl: './fill.component.html',
  styleUrls: ['./fill.component.scss']
})
export class FillComponent {
  @Input() item: ILessonSnippet = {};
  @Output() onChange = new EventEmitter()

  @ViewChild('destination') destination: any;
  @ViewChild('origin') origin: any;

  isAnimating = false;

  constructor(private utility: UtilityService) {}

  ngOnInit() {
    this.item.additionalWords?.forEach(w => this.item.words?.push(w));
    this.utility.onShuffleArray(this.item.words);
  }

  flip(word: any, settings: any) {
    const invert = {
      x: settings.first.left - settings.last.left,
      y: settings.first.top - settings.last.top
    };

    let animation = word.animate(
      [
        {transform: `scale(1,1) translate(${invert.x}px, ${invert.y}px)`},
        {transform: `scale(1,1) translate(0, 0)`}
      ],
      {
        duration: 300,
        easing: "ease"
      }
    );
    animation.onfinish = () => (this.isAnimating = false);
  };

  move(word: any) {
    const path = word.path || (word.composedPath && word.composedPath());
    const id = Math.random();
    const container = path[1];
    let rect = path[0].getBoundingClientRect();
    let first, last;
    this.isAnimating = true;
    container.dataset.id = id;
    path[0].dataset.id = id;
    container.style.height = `${path[0].offsetHeight}px`;
    container.style.width = `${path[0].offsetWidth}px`;
    first = {top: rect.top, left: rect.left};
    const z = [...this.destination.nativeElement.querySelectorAll(".blank")].filter(
      (sib) => Array.from(sib.children).length === 0
    )
    z[0].insertAdjacentElement("beforeend", path[0]);
    rect = path[0].getBoundingClientRect();
    last = {top: rect.top, left: rect.left};
    this.flip(path[0], {first, last});

    console.log(this.item.words)
  };

  putback(word: any) {
    const path = word.path || (word.composedPath && word.composedPath());
    const id = path[0].dataset.id;
    const container = this.origin.nativeElement.querySelector(`[data-id="${id}"]`);
    const siblings = [...this.destination.nativeElement.querySelectorAll(".word")].filter(
      (sib) => {
        return sib !== path[0]
      }
    );

    let rect = path[0].getBoundingClientRect();
    let first, last;
    this.isAnimating = true;
    first = {top: rect.top, left: rect.left};
    siblings.forEach((sib) => {
      let rect = sib.getBoundingClientRect();
      sib.__first = {top: rect.top, left: rect.left};
    });

    container.insertAdjacentElement("beforeend", path[0]);

    rect = path[0].getBoundingClientRect();
    last = {top: rect.top, left: rect.left};
    siblings.forEach((sib) => {
      let rect = sib.getBoundingClientRect();
      sib.__last = {top: rect.top, left: rect.left};
    });

    this.flip(path[0], {first, last});

    siblings.forEach((sib) => this.flip(sib, {first: sib.__first, last: sib.__last}));
    container.style.height = "";
    container.style.width = "";
    container.removeAttribute("data-id");
    path[0].removeAttribute("data-id");
  };

  onSelectWord(word: any) {
    let sentence: any[] = [];
    const path = word.path || (word.composedPath && word.composedPath());
    Array.from(path[1].classList).indexOf('word-wrapper') > -1 ? this.move(word) : this.putback(word);
    Array.from(this.destination.nativeElement.children).forEach((c: any) => sentence.push(c.innerText))

    this.onChange.emit(sentence);
  }
}
