<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 d-md-block sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="view-wrapper">
        <h1 class="page-title">Achievements</h1>
        <div class="row">
          <div class="col-sm-6 col-md-4" *ngFor="let item of achievements">
            <div class="achievement" [ngClass]="{'received': item.received}">
              <div class="row align-items-center">
                <div class="col-4">
                  <img class="achievement__img" [src]="'assets/img/achievements/' + item.img" alt="">
                </div>
                <div class="col-8">
                  <p class="achievement__title">{{item.title}}</p>
                  <p class="achievement__description">{{item.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
