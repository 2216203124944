<h6 class="py-2 px-0 mb-0 color-composition" [innerHTML]="item.shortCue"></h6>
<h6 class="mb-0" [innerHTML]="item.fullPoint"></h6>
<p class="py-2 px-0 color-function mb-0" [innerHTML]="item.cueExplanation"></p>
<div *ngIf="item.narration">
  <gs-player #player
             [files]="files"
             [playerTheme]="playerTheme"></gs-player>
</div>

<ng-lottie *ngIf="item.isLottie"
           [options]="{ path: item.animationSource}">
</ng-lottie>
