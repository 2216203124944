import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ILessonSnippet} from '../../../shared/models/lesson.model';
import {debounceTime, distinctUntilChanged, fromEvent, map} from "rxjs";

@Component({
  selector: 'app-write',
  templateUrl: './write.component.html',
  styleUrls: ['./write.component.scss']
})
export class WriteComponent implements AfterViewInit {
  @Input() item: ILessonSnippet = {};
  @Output() onChange = new EventEmitter()
  @ViewChild('answerElement') answerElement: ElementRef | undefined;

  ngAfterViewInit(): void {
    console.log(this.item)
    // @ts-ignore
    fromEvent(this.answerElement.nativeElement, 'input')
      // @ts-ignore
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(data => this.onChange.emit(data));
  }
}
