<header class="header navbar navbar-expand-lg navbar-dark bg-dark flex-md-nowrap p-0 shadow">
  <div class="container-fluid">
    <a class="navbar-brand" href="https://homi.school/" target="_blank">
      <img class="brand-logo" src="assets/img/logo-h.png" alt="Homi Logo">
    </a>
    <button *ngIf="isLoggedIn" class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="isLoggedIn" class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <ng-container *ngFor="let item of navigation">
          <li class="nav-item d-mobile-only"
              *ngIf="item.isVisible"
              [ngClass]="{'active': activeView === item.view}">
            <a class="nav-link" (click)="onChangeView(item.view)">
              <img class="nav-link__icon" [src]="'assets/icons/navigation/' + item.img + '.png'" alt="">
              {{item.title}}
            </a>
          </li>
        </ng-container>
        <li *ngIf="!isEditorPage" class="nav-item">
          <a class="nav-link" (click)="authService.SignOut()">
            <img *ngIf="navbarOpen" class="nav-link__icon" [src]="'assets/icons/navigation/logout.png'" alt="">
            Logout
          </a>
        </li>
        <li *ngIf="isEditorPage">
          <a class="nav-link" [routerLink]="['/home']">
            Exit Editor
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>
