import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import firebase from "firebase/compat/app";
import {environment} from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root',
})

export class AdminGuard implements CanActivate {
  constructor(
      public authService: AuthService,
      public router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      firebase.initializeApp(environment.firebase);
      firebase.auth().onAuthStateChanged(async user => {
        if (user && (this.authService.user$.value.isAdmin || this.authService.user$.value.isEditor)) {
          resolve(true);
        } else {
          this.router.navigate(['home']).then();
          reject(false);
        }
      });
    });
  }
}
