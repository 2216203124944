import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ILessonSnippet} from "../../../shared/models/lesson.model";
import {UtilityService} from "../../../shared/services/utility.service";

@Component({
  selector: 'app-word-bank',
  templateUrl: './word-bank.component.html',
  styleUrls: ['./word-bank.component.scss']
})
export class WordBankComponent implements OnInit {
  @Input() item: ILessonSnippet = {};
  @Output() onChange = new EventEmitter()

  @ViewChild('destination') destination: any;
  @ViewChild('origin') origin: any;

  isAnimating = false;

  constructor(private utility: UtilityService) {
  }

  ngOnInit() {
    this.utility.onShuffleArray(this.item.words);
  }

  flip(word: any, settings: any) {
    const invert = {
      x: settings.first.left - settings.last.left,
      y: settings.first.top - settings.last.top
    };

    let animation = word.animate(
      [
        {transform: `scale(1,1) translate(${invert.x}px, ${invert.y}px)`},
        {transform: `scale(1,1) translate(0, 0)`}
      ],
      {
        duration: 300,
        easing: "ease"
      }
    );
    animation.onfinish = () => (this.isAnimating = false);
  };

  move(word: any) {
    const id = Math.random();
    const container = word.path[1];
    let rect = word.path[0].getBoundingClientRect();
    let first, last;
    this.isAnimating = true;
    container.dataset.id = id;
    word.path[0].dataset.id = id;
    container.style.height = `${word.path[0].offsetHeight}px`;
    container.style.width = `${word.path[0].offsetWidth}px`;
    first = {top: rect.top, left: rect.left};
    this.destination.nativeElement.insertAdjacentElement("beforeend", word.path[0]);
    rect = word.path[0].getBoundingClientRect();
    last = {top: rect.top, left: rect.left};
    this.flip(word.path[0], {first, last});
  };

  putback(word: any) {
    const id = word.path[0].dataset.id;
    const container = this.origin.nativeElement.querySelector(`[data-id="${id}"]`);
    const siblings = [...this.destination.nativeElement.querySelectorAll(".word")].filter(
      (sib) => {
        return sib !== word.path[0]
      }
    );

    let rect = word.path[0].getBoundingClientRect();
    let first, last;
    this.isAnimating = true;
    first = {top: rect.top, left: rect.left};
    siblings.forEach((sib) => {
      let rect = sib.getBoundingClientRect();
      sib.__first = {top: rect.top, left: rect.left};
    });

    container.insertAdjacentElement("beforeend", word.path[0]);

    rect = word.path[0].getBoundingClientRect();
    last = {top: rect.top, left: rect.left};
    siblings.forEach((sib) => {
      let rect = sib.getBoundingClientRect();
      sib.__last = {top: rect.top, left: rect.left};
    });

    this.flip(word.path[0], {first, last});

    siblings.forEach((sib) => this.flip(sib, {first: sib.__first, last: sib.__last}));
    container.style.height = "";
    container.style.width = "";
    container.removeAttribute("data-id");
    word.path[0].removeAttribute("data-id");
  };

  onSelectWord(word: any) {
    let sentence = '';
    Array.from(word.path[1].classList).indexOf('word-wrapper') > -1 ? this.move(word) : this.putback(word);
    Array.from(this.destination.nativeElement.children).forEach((c: any) => sentence += c.innerText + ' ')
    this.onChange.emit(sentence);
  }
}
