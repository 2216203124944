<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 d-md-block sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="view-wrapper">
        <h1 class="page-title">Leaderboard</h1>
        <div class="row">
          <div class="col-12">
            <div class="leader" *ngFor="let item of leaders; let i = index">
              <div class="leader__rate">
                <img src="assets/icons/leaderboard/{{i + 1}}.png" alt=""
                     *ngIf="i === 0 || i === 1 || i === 2">
                <span *ngIf="i !== 0 && i !== 1 && i !== 2">{{i + 1}}</span>
              </div>
              <img class="leader__img" [src]="'assets/img/leaders/' + item.img" alt="">
              <div class="leader__info">
                <div class="leader__name">{{item.name}}</div>
                <div class="leader__experience">{{item.experience}}XP</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
