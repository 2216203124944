import {Directive, ElementRef, Renderer2} from "@angular/core";

@Directive({
  selector: '[ellipsisScroll]',
  host: {
    '(mouseenter)': 'onStartScrolling()',
    '(mouseleave)': 'onStopScrolling()'
  }
})
export class EllipsisScrollDirective {
  hostElem = this.element.nativeElement;
  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterContentInit() {
    this.renderer.setStyle(this.hostElem, "overflow", 'hidden');
    const content = this.hostElem.innerHTML;
    const scrolling = `<div style='white-space: nowrap;position: relative;overflow: hidden;text-overflow: ellipsis;left: 0;width: 100%;-webkit-transition: left 2s linear, width 2s linear;-moz-transition: left 2s linear, width 2s linear;transition: left 2s linear, width 2s linear;'>${content}</div>`;
    const origin = `<div style='position: fixed;z-index: 9999;left: -10000px;opacity: 1;'>${content}</div>`;
    this.hostElem.innerHTML = scrolling + origin;
  }

  onStartScrolling() {
    this.renderer.setStyle(this.hostElem.children[0], "width", '100%');
    this.renderer.setStyle(this.hostElem.children[0], "left", '0');

    if (this.hostElem.children[1].offsetWidth > this.hostElem.children[0].offsetWidth) {
      const width = (this.hostElem.children[1].offsetWidth) + 'px';
      const left = '-' + (this.hostElem.children[1].offsetWidth - this.hostElem.offsetWidth) + 'px';
      this.renderer.setStyle(this.hostElem.children[0], "width", width);
      this.renderer.setStyle(this.hostElem.children[0], "left", left);
    }
  }

  onStopScrolling() {
    this.renderer.setStyle(this.hostElem.children[0], "width", '100%');
    this.renderer.setStyle(this.hostElem.children[0], "left", '0');
  }
}
