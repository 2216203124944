import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {combineLatest, filter, first, map, Observable, ReplaySubject, take, takeUntil} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {ILesson} from '../../shared/models/lesson.model';
import {IUser} from '../../shared/models/user.model';
import {AuthService} from '../../shared/services/auth.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import * as dayjs from 'dayjs';
import {environment} from "../../../environments/environment";
import {IProject} from "../../shared/models/projects.model";
import {where} from "@angular/fire/firestore";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  // @ts-ignore
  @ViewChild('template', {static: true}) template: TemplateRef<any>
  @ViewChild('explainer', {static: false}) explainer!: ElementRef
  modalRef?: BsModalRef;
  lessons: ILesson[] = [];
  isLoading = true;
  education: any[] = [];
  activeTab = '';
  // @ts-ignore
  user: IUser;
  isInformationUpdated = false;
  projects: IProject[] = [];
  starterProjects: IProject[] = [];
  selectedProjectToDelete = '';
  isEditorAvailable!: boolean;
  selectedProject = '';

  constructor(
    public authService: AuthService,
    private modalService: BsModalService,
    public afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    const projects: Observable<any[]> = this.afs.collection('projects').valueChanges().pipe(takeUntil(this.destroyed$));
    const user: Observable<IUser> =  this.authService.getUser().pipe(filter(u => u && !u.initial)).pipe(takeUntil(this.destroyed$));
    const general = this.authService.getGeneral().pipe(filter(g => g && !g.initial)).pipe(first());
    const features = this.authService.getFeatures().pipe(filter(g => g && !g.initial)).pipe(first());

    combineLatest(user, projects).pipe(takeUntil(this.destroyed$)).subscribe(([user, projects]) => {
      this.user = {...user};
      // @ts-ignore
      this.projects = user.isAdmin
          ? ([...projects].filter((item: IProject) => (item.author === this.user?.uid))
              .sort((a, b) => (a.projectName > b.projectName) ? 1 : -1))
          : ([...projects].filter((item: IProject) => (item.author === this.user?.uid) && !item.isStarter)
              .sort((a, b) => (a.projectName > b.projectName) ? 1 : -1))

      this.starterProjects = [...projects].filter((item: IProject) => item.isStarter)
          .sort((a, b) => (a.priorityOrder < b.priorityOrder) ? 1 : -1);

      /*if(!this.user.isOnboardingCompleted) {
        this.router.navigate(['onboarding']);
        sessionStorage.setItem('first', 'yes')
      } else if (sessionStorage.getItem('first') && (!this.user.firstName || !this.user.lastName)) {
        this.modalRef = this.modalService.show(this.template, {class: 'modal-sm modal-dialog-centered'});
      }*/

      /*if (sessionStorage.getItem('first') && (!this.user.firstName || !this.user.lastName)) {
        this.modalRef = this.modalService.show(this.template, {class: 'modal-sm modal-dialog-centered'});
      }*/

      if (user.specialAccess) {
        this.isEditorAvailable = true;
      } else {
        if (this.user.access.expDate && dayjs(this.user.access.expDate).diff(dayjs()) < 0) {
          let user = JSON.parse(JSON.stringify(this.user));
          user.access.status = 'Expired';
          user.access.name = 'free';
          this.authService.SetUserData(user).then();
        }

        this.isEditorAvailable = user.access.status === 'Active' || this.projects.length < 2;
      }
      /*this.onGetLessons()*/
      this.isLoading = false;

      this.cdr.detectChanges();
      this.loadScript();
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onChangeTab(tab: string) {
    this.activeTab = tab;
  }

  onLessonOpen(lessonId: string) {
    this.router.navigate(['/lesson', lessonId]).then()
  }

  onPlansOpen(element: TemplateRef<any>, id?: string) {
    this.selectedProject = id ? id : '';
    this.modalRef = this.modalService.show(element, {class: 'modal-dialog modal-dialog-centered modal-md'})
  }

  onGetLessonImage(img: string) {
    return img;
  }

  onPrepareEducationStructure() {
    this.education.length = 0;
    const categories = this.getUnique(this.lessons.map((lesson: ILesson) => lesson.category))
      .sort((a, b) => (a.title > b.title) ? 1 : -1);
    categories.forEach((c: string) => {
      const lessons = this.lessons
        .filter((lesson: ILesson) => lesson.category === c)
        // @ts-ignore
        .sort((a, b) => (a.lessonTitle > b.lessonTitle) ? 1 : -1);
      const subCategories = this.getUnique(lessons.map((lesson: ILesson) => lesson.subCategory));
      this.education.push(
        {
          title: c,
          id: c.toLowerCase(),
          // @ts-ignore
          chapters: subCategories.map((subCategory: string) => {
            return {
              title: subCategory,
              id: subCategory.toLowerCase(),
              lessons: lessons
                .filter((lesson: ILesson) => lesson.subCategory === subCategory)
                .map((lesson: ILesson) => {
                  return {
                    title: lesson.lessonTitle,
                    subTitle: lesson.lessonSubTitle,
                    id: lesson.id,
                    img: lesson.lessonImage,
                    isFree: lesson.isFree || false
                  }
                })
            }
          }).sort((a, b) => (a.title > b.title) ? 1 : -1)
        }
      )
      this.education.sort((a, b) => (a.title > b.title) ? 1 : -1);
    });

    this.activatedRoute.queryParams
        .pipe(takeUntil(this.destroyed$))
        .subscribe((params: any) => {
          if (Object.keys(params).length) {
            this.activeTab = params.activeCategory.toLowerCase();
          } else {
            this.activeTab = this.education[0].id
          }
        })
  }

  getUnique(arr: any[]): any[] {
    return Array.from(new Set(arr));
  }

  onGetIterations(id: string) {
    // @ts-ignore
    let mastery = this.user ? this.user.mastery : null;
    let lessonMastery = mastery ? mastery.find((i: any) => i.id === id) : null;
    return (lessonMastery && lessonMastery.iterations && lessonMastery.iterations > 0) ? lessonMastery.iterations : null;
  }

  onUpdateUserInfo() {
    this.isLoading = true;
    this.authService.SetUserData(this.user).then(() => {
      this.isInformationUpdated = false;
      this.isLoading = false;
      sessionStorage.clear();
      this.modalService.hide();
    }).catch(() => {
      this.isLoading = false;
    })
  }

  onCancel() {
    this.modalRef?.hide();
    sessionStorage.clear();
  }

  onPricingNav() {
    this.router.navigate(['/pricing']).then(() => {
      this.modalRef?.hide();
    })
  }

  openProject(id: string) {
    this.router.navigate(['/project', id]).then()
  }

  goToAskHomiOnEditor(params?: boolean, id?: string) {
    if (id) {
      this.router.navigate(['/editor'], { queryParams: {id} }).then()
    } else {
      this.router.navigate(['/editor'], { queryParams: {openAiOpened: params} }).then()
    }
  }

  onOpenConfirmationToDeleteProject(element: any, elementId: string, event?: any) {
    event?.stopPropagation();
    this.selectedProjectToDelete = elementId;
    this.modalRef = this.modalService.show(element, {class: 'modal-dialog modal-dialog-centered modal-sm'});
  }

  onDeleteProject() {
    this.afs.collection('projects')
        .doc(this.selectedProjectToDelete)
        .delete().then(() => {
      this.modalRef?.hide();
    })
  }

  loadScript() {
    const urls = ['https://fast.wistia.com/embed/medias/r8wvcdyrx8.jsonp', 'https://fast.wistia.com/assets/external/E-v1.js'];
    urls.forEach(url => {
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      this.explainer.nativeElement.appendChild(script);
    })

  }

  toggleStoryAsStarter(id: string) {
    this.isLoading = true;
    const docRef = this.afs.collection('projects').doc(id);

    docRef.get().subscribe(async resp => {
      const project: any = resp.data();
      project.isStarter = !project.isStarter;

      await docRef.update(project);
      this.isLoading = false;
    })

  }

  async onEditStoryThumbnail(newUrl: string) {
    try {
      this.isLoading = true;
      this.modalRef?.hide();

      const docRef = this.afs.collection('projects').doc(this.selectedProject);

      const docSnapshot: any = await docRef.get().toPromise();

      const project: any = docSnapshot.data();
      project.img = newUrl;

      await docRef.update(project);
      this.selectedProject = '';
    } catch (error) {
      console.error('Error updating document:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
