<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 d-md-block sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="view-wrapper">
        <h1 class="page-title">Billing</h1>

        <ng-container *ngIf="billingData.expDate">
          <p class="page-subtitle">
            Plan will not renew automatically. You will have to purchase a plan again after
            the current plan expires.
          </p>
          <table class="table table-responsive">
            <tr>
              <th>Plan name</th>
              <th>Value</th>
              <th>Status</th>
              <th>Purchased date</th>
              <th>Expiry date</th>
              <th>Purchase mechanism</th>
            </tr>
            <tr>
              <td>CBSE 10th SS {{billingData.name}}</td>
              <td>{{billingData.name === 'Yr' ? '495' : '195' }}</td>
              <td>{{billingData.status }}</td>
              <td>{{billingData.purchaseDate}}</td>
              <td>{{billingData.expDate}}</td>
              <td>RazorPay</td>
            </tr>
          </table>

        </ng-container>
        <ng-container *ngIf="!billingData.expDate">
          <div class="not-found">
            <img src="../../../assets/img/page-not-found.png" alt="" class="not-found_img">
            <p class="not-found__title">Sorry, nothing to show here.</p>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
