import {Component, NgZone, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})

export class SignInComponent implements OnInit {
    isLoading = false;

    constructor(
        public router: Router,
        public authService: AuthService,
        public ngZone: NgZone
    ) {}

    ngOnInit() {
        const button = document.getElementById("singIn");
        document.addEventListener("keyup", function(event) {
            if (event.key === "Enter") {
                event.preventDefault();
                button!.click();
            }
        });
    }

    onSignIn(name: string, password: string): void {
        this.isLoading = true;
        this.authService.SignIn(name, password).then((res) => {
            setTimeout(() => {
                this.isLoading = false;
                this.ngZone.run(() => {
                  this.router.navigateByUrl('/home', { replaceUrl: true }).then();
                });
            }, 1000)
        }).catch((error) => {
            if (error.code === 'auth/wrong-password') {
                window.alert('You have either provided an incorrect password or your account does not have a password. Please check and try again.');
            } else {
                window.alert(error.message);
            }
            this.isLoading = false;
        })
    }
}
