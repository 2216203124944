import {Injectable} from '@angular/core';
import {map, Observable, Observer, Subject} from "rxjs";
import {AnonymousSubject} from "rxjs/internal/Subject";

@Injectable()
export class OpenAiService {

  /*realServerEndpoint = 'ws://localhost:8080';*/
  realServerEndpoint = 'wss://homi-stt.herokuapp.com';
  public subject!: AnonymousSubject<any> | null;
  public messages!: Subject<any>;

  initWebSocketConnection() {
    this.messages = <Subject<any>>this.connect().pipe(
        map(
            (response: MessageEvent): any => {
              return response.data;
            }
        )
    );
  }
  public connect(): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(this.realServerEndpoint);
    }
    return this.subject;
  }

  private create(url: any): AnonymousSubject<MessageEvent> {
    let ws = new WebSocket(url);

    ws.addEventListener('open', () => {
      console.log("Successfully connected: " + url);
    });

    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      error: null,
      complete: null,
      next: (data: Object) => {
        console.log(ws.readyState, WebSocket.OPEN);
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };
    // @ts-ignore
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }
}
