import { Injectable } from '@angular/core';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class S3ServiceService {
  private bucket: S3Client;

  constructor() {
    this.bucket = new S3Client(
      {
        credentials: {
          accessKeyId: environment.AWS.ACCESS_KEY_ID,
          secretAccessKey: environment.AWS.SECRET_ACCESS_KEY,
        },
        region: environment.AWS.REGION,
      }
    );
  }

  async uploadFile(file: File, folder: string, fileName?: string) {
    const params = {
      Bucket: 'homi',
      Key: `${folder}/${fileName ? fileName : file.name}`,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    };

    try {
      const response = await this.bucket.send(new PutObjectCommand(params));
      console.log("SUCCESS", response);
    } catch(error) {
      console.log("FAILURE", error);
    }
  }
}
