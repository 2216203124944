import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-leave-page-modal',
  templateUrl: './leave-page-modal.component.html',
  styleUrls: ['./leave-page-modal.component.scss']
})
export class LeavePageModalComponent {
  @Output() confirmAction = new EventEmitter<boolean>();

  constructor(private modalRef: BsModalRef) {}

  confirm() {
    this.confirmAction.emit(true);
    this.modalRef.hide();
  }

  cancel() {
    this.confirmAction.emit(false);
    this.modalRef.hide();
  }

  onClose() {
    this.cancel();
  }
}
