export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDXS_0NkWT1Siixh3b8NQtLCyXIFMvnhoQ',
    authDomain: 'homi-workbook.firebaseapp.com',
    databaseURL: 'https://homi-workbook-default-rtdb.firebaseio.com',
    projectId: 'homi-workbook',
    storageBucket: 'homi-workbook.appspot.com',
    messagingSenderId: '296901113550',
    appId: '1:296901113550:web:09d3604381ad24482d429f',
    measurementId: 'G-HKBESH82G9'
  },
  AWS: {
    ACCESS_KEY_ID: "AKIA6QOC5YOFRKRYVMU5",
    SECRET_ACCESS_KEY: "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",
    REGION: "ap-south-1",
    CLOUDFRONT: "d1f5m9ybhzznuh.cloudfront.net",
    API: "https://jiizbcu41m.execute-api.eu-north-1.amazonaws.com/prod",
    ORIGIN_DOMAIN: "homi.s3.ap-south-1.amazonaws.com"
  }
};
