<app-loader *ngIf="isLoading" [isBlurred]="true"></app-loader>
<app-header *ngIf="!isFullScreen"></app-header>
<div class="container-fluid editor" [ngClass]="{'fullscreen': isFullScreen}">
  <div class="row h-100">
    <div #canvasWrapper id="wrapper" class="canvas" [ngStyle]="{'height': initialDims.height + 'px', 'width': initialDims.width + 'px'}">
      <canvas id="canvas" #htmlCanvas></canvas>
    </div>
    <!--<div *ngIf="isChangesWasMade" class="save-group">
      <button (click)="onSavePage()" class="button" type="button">Save Page</button>
    </div>-->
    <!--for drawing mode-->
    <!--<div class="toggle">
      <label for="toggle-button" class="text">Free Drawing Mode</label>
      <input type="checkbox" id="toggle-button" class="toggle-button">
    </div>-->
    <div class="sidebar">
      <div class="sidebar__tabs">
        <div *ngFor="let tab of tabs"
             (click)="onSelectActiveSection(tab.view)" class="item"
             [class.item-active]="tab.view === activeView"
        >
          <div class="item__inner">
            <img src="assets/icons/editor/{{tab.img}}.png" alt="nav-image">
            <div>{{tab.view.charAt(0).toUpperCase() + tab.view.slice(1)}}</div>
          </div>
        </div>
        <div class="item" (click)="onOpenAskHomiModal(true)">
          <div class="item__inner">
            <img src="assets/icons/editor/idea.png" alt="nav-image">
            <div>Imagine Wizard</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content" #tabsContent>
      <div class="project-name" *ngIf="project && project.projectName">
        {{project.projectName}}
      </div>
      <div class="sidebar-show"
           *ngIf="activeView"
           (click)="onHideSideBar(tabsContent)">
        <img src="assets/icons/chevron-left.png" alt="">
      </div>

      <ng-container [ngSwitch]="activeView">
        <ng-container *ngSwitchCase="'backgrounds'">
          <div class="content">
            <div class="search">
              <div class="input-group">
                <input
                  [(ngModel)]="searchContent.backgrounds"
                  type="text" class="form-control"
                  placeholder="e.g. desert planet, dark forest"
                  (keyup.enter)="onSearchBackgroundsByText(true)"
                >
                <div class="input-group-append">
                  <button (click)="onSearchBackgroundsByText(true)" class="button" type="button">
                    <img src="assets/icons/search_icon.png" alt="search">
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="editorData.backgrounds.length === 0 && !randomBackgroundsDisplaying" class="empty">
              <img src="assets/icons/empty.svg" alt="empty-space">
              <!-- <p class="empty__title">There are no available <strong>{{activeView}}</strong></p> -->
              <p class="empty__title">Find and choose a background for your story.</p>
              <p class="empty__sub-title">
                Stretch and lock the background in place. Choose clean backgrounds where graphic elements will stand out.
              </p>
            </div>
            <div class="wrapper"
                 #sidebarWrapper
                 *ngIf="editorData.backgrounds.length > 0"
                 [ngStyle]="{'overflow-y': displayedInfo.tags.length && displayedInfo.likes ? 'hidden' : 'scroll'}"
            >
              <div *ngFor="let item of editorData.backgrounds">
                <div class="item__image" (click)="setCanvasImage(item.image)"
                     [ngStyle]="{'background-image': 'url(' + item.preview + ')'}">
                  <img src="assets/icons/placeholder.svg" alt="">
                </div>
                <div class="item__more" (click)="showImageInfo(item.info)">
                  <img src="assets/icons/more-btn.svg" alt="">
                </div>
              </div>
              <div *ngIf="displayedInfo.tags.length || displayedInfo.likes" class="wrapper__modal">
                <div class="info">
                  <div *ngIf="displayedInfo.tags.length" class="tags">
                    <div class="tag" (click)="onSelectTag(tag)" *ngFor="let tag of displayedInfo.tags">{{tag}}</div>
                  </div>
                  <div *ngIf="displayedInfo.likes" class="likes"><img src="assets/icons/like.png" alt="">{{displayedInfo.likes}}</div>
                  <div class="close" (click)="closeImageInfo()"><img src="assets/icons/close.png" alt=""></div>
                </div>
              </div>
              <button *ngIf="!randomBackgroundsDisplaying" (click)="onSearchBackgroundsByText()" class="btn btn-secondary btn-title" type="button">Load More</button>
            </div>
            <div *ngIf="randomBackgroundsDisplaying" class="empty">
              <img src="assets/icons/empty.svg" alt="empty-space">
              <!-- <p class="empty__title">There are no available <strong>{{activeView}}</strong></p> -->
              <p class="empty__title">Find and choose a background for your story.</p>
              <p class="empty__sub-title">
                Stretch and lock the background in place. Choose clean backgrounds where graphic elements will stand out.
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'graphics'">
          <div class="content">
            <div class="search">
              <div class="input-group">
                <input
                  [(ngModel)]="searchContent.graphics"
                  type="text" class="form-control"
                  placeholder="e.g. tree, castle"
                  (keyup.enter)="onSearchContentByText(true)"
                >
                <div class="input-group-append">
                  <button (click)="onSearchContentByText(true)" class="button" type="button">
                    <img src="assets/icons/search_icon.png" alt="search">
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="editorData.graphics.length === 0 && !randomGraphicsDisplaying" class="empty">
              <img src="assets/icons/empty.svg" alt="empty-space">
              <!-- <p class="empty__title">There are no available <strong>{{activeView}}</strong></p> -->
              <p class="empty__title">Find characters and things to use in your story.</p>
              <p class="empty__sub-title">
                Move, resize, flip and lock with the toolbar on the right. Use a graphic element as a background if you like.
              </p>
            </div>
            <div class="wrapper"
                 #sidebarWrapper
                 *ngIf="editorData.graphics.length > 0"
                 [ngStyle]="{'overflow-y': displayedInfo.tags.length && displayedInfo.likes ? 'hidden' : 'scroll'}"
            >
              <div *ngFor="let item of editorData.graphics" class="item">
                <div class="item__image" (click)="addImageOnCanvas(item.image)"
                     [ngStyle]="{'background-image': 'url(' + item.preview + ')'}">
                  <img src="assets/icons/placeholder.svg" alt="">
                </div>
                <div class="item__more" (click)="showImageInfo(item.info)">
                  <img src="assets/icons/more-btn.svg" alt="">
                </div>
              </div>
              <div *ngIf="displayedInfo.tags.length && displayedInfo.likes" class="wrapper__modal">
                <div class="info">
                  <div class="tags" >
                    <div class="tag" (click)="onSelectTag(tag)" *ngFor="let tag of displayedInfo.tags">{{tag}}</div>
                  </div>
                  <div class="likes"><img src="assets/icons/like.png" alt="">{{displayedInfo.likes}}</div>
                  <div class="close" (click)="closeImageInfo()"><img src="assets/icons/close.png" alt=""></div>
                </div>
              </div>
              <button *ngIf="!randomGraphicsDisplaying" (click)="onSearchContentByText()" class="btn btn-secondary btn-title" type="button">Load More</button>
            </div>
            <div *ngIf="randomGraphicsDisplaying" class="empty">
              <img src="assets/icons/empty.svg" alt="empty-space">
              <!-- <p class="empty__title">There are no available <strong>{{activeView}}</strong></p> -->
              <p class="empty__title">Find characters and things to use in your story.</p>
              <p class="empty__sub-title">
                Move, resize, flip and lock with the toolbar on the right. Use a graphic element as a background if you like.
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'uploads'">
          <div class="content">
            <div *ngIf="editorData.uploads.backgrounds?.length === 0
              && editorData.uploads.templates?.length === 0
              && editorData.uploads.graphics?.length === 0"
              class="empty"
            >
              <img src="assets/icons/empty.svg" alt="empty-space">
              <p class="empty__title">Upload your own assets. </p>
              <p class="empty__sub-title">
                We are working to expand our graphics library. Meanwhile you can bring your own assets. Choose a category and upload.
              </p>
            </div>

            <label class="content__dropdown">
              Sound effects
              <span class="select-wrapper">
                      <img src="../../assets/icons/chevron-down.svg">
                      <select #uploadedCategory
                              (change)="selectedUploadCategory = uploadedCategory.value">
                        <option value="backgrounds"
                                [selected]="selectedUploadCategory === 'backgrounds'">
                          Backgrounds
                        </option>
                        <option value="graphics"
                                [selected]="selectedUploadCategory === 'graphics'">
                          Graphics
                        </option>
                        <!-- <option value="templates"
                                [selected]="selectedUploadCategory === 'templates'">
                          Templates
                        </option> -->
                      </select>
                    </span>
            </label>

            <div class="dragAndDrop" appDnd (fileDropped)="onFileDropped($event)">
              <div class="text">
                Drag & drop your files here or
              </div>
              <input #file type="file" multiple="multiple" accept="image/*" (change)="downLoadFile($event)">
              <button (click)="file.click()" class="button" type="button">Browse Files</button>
            </div>

            <ng-container *ngIf="selectedUploadCategory === 'backgrounds'">
              <div class="title" *ngIf="editorData.uploads.backgrounds?.length">Backgrounds</div>
              <div *ngIf="editorData.uploads.backgrounds.length > 0" class="wrapper wrapper-upload">
                <div *ngFor="let item of editorData.uploads.backgrounds; let i = index" class="item" (click)="setCanvasImage(item)"
                     [ngStyle]="{'background-image': 'url(' + item + ')'}">
                  <img src="assets/icons/placeholder.svg" alt="">
                  <div class="item__more" (click)="onDeleteDroppedFiles(i, 'backgrounds')">
                    <img src="assets/icons/trash.svg" alt="delete">
                  </div>
                </div>
              </div>
              </ng-container>

            <ng-container *ngIf="selectedUploadCategory === 'graphics'">
              <div class="title" *ngIf="editorData.uploads.graphics?.length">Graphics</div>
              <div *ngIf="editorData.uploads.graphics.length > 0" class="wrapper wrapper-upload">
                <div *ngFor="let item of editorData.uploads.graphics; let i = index" class="item" (click)="addImageOnCanvas(item)"
                     [ngStyle]="{'background-image': 'url(' + item + ')'}">
                  <img src="assets/icons/placeholder.svg" alt="">
                  <div class="item__more" (click)="onDeleteDroppedFiles(i, 'graphics')">
                    <img src="assets/icons/trash.svg" alt="delete">
                  </div>
                </div>
              </div>
            </ng-container>

            <!--<div class="title" *ngIf="editorData.uploads.templates?.length">Templates</div>
            <div *ngIf="editorData.uploads.templates.length > 0" class="wrapper wrapper-upload">
              <div *ngFor="let item of editorData.uploads.templates; let i = index" class="item" (click)="addImageOnCanvas(item)"
                   [ngStyle]="{'background-image': 'url(' + item + ')'}">
                <img src="assets/icons/placeholder.svg" alt="">
                <div class="item__more" (click)="onDeleteDroppedFiles(i, 'templates')">
                  <img src="assets/icons/trash.svg" alt="delete">
                </div>
              </div>
            </div>-->
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <!--<div class="text__tools">
            <div class="row">
              <div class="col-6">
                <label for="size" class="label">Font Size</label>
                <div class="range">
                  <input type="range"
                         [(ngModel)]="props.fontSize"
                         (change)="setFontSize()"
                         class="form-range"
                         min="1"
                         max="120"
                         id="size"
                  >
                  <div class="range__text">{{props.fontSize}}</div>
                </div>
              </div>
              <div class="col-6">
                <label for="lineHeight" class="label">Line Height</label>
                <div class="range">
                  <input type="range"
                         [(ngModel)]="props.lineHeight"
                         (change)="setLineHeight()"
                         class="form-range"
                         min="1"
                         max="10"
                         step="0.1"
                         id="lineHeight"
                  >
                  <div class="range__text">{{props.lineHeight}}</div>
                </div>
              </div>
            </div>

            <div class="row styl">
              <div class="col col-z">
                <div class="label">Text Align</div>
                <div class="btn-group align" role="group" aria-label="...">
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': props.textAlign == 'left' }" (click)="setTextAlign('left')">
                    <i class="fa fa-align-left"></i>
                  </button>
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': props.textAlign == 'center' }" (click)="setTextAlign('center')">
                    <i class="fa fa-align-center"></i>
                  </button>
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': props.textAlign == 'right' }" (click)="setTextAlign('right')">
                    <i class="fa fa-align-right"></i>
                  </button>
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': props.textAlign == 'justify' }" (click)="setTextAlign('justify')">
                    <i class="fa fa-align-justify"></i>
                  </button>
                </div>
              </div>
              <div class="col col-z">
                <div class="label">Style</div>
                <div class="btn-group align" role="group" aria-label="...">
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': props.fontWeight }" (click)="setBold()">
                    <i class="fa fa-bold"></i>
                  </button>
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': props.fontStyle }" (click)="setFontStyle()">
                    <i class="fa fa-italic"></i>
                  </button>
                  <button type="button" class="btn btn-sm" [ngClass]="{'active': hasTextDecoration('underline') }"
                          (click)="setTextDecoration('underline')">
                    <i class="fa fa-underline"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="color" class="label">Color</label>
                <input type="text"
                       class="form-control"
                       [(colorPicker)]="props.fill"
                       [style.background]="props.fill"
                       [value]="props.fill"
                       [cpPosition]="'top'"
                       (colorPickerChange)="setFill()"
                       id="color"
                >
              </div>
              <div class="col-6">
                <label for="opacity" class="label">Opacity</label>
                <div class="range">
                  <input type="range"
                         [(ngModel)]="props.opacity"
                         (change)="setOpacity()"
                         class="form-range"
                         min="0"
                         max="100"
                         id="opacity"
                  >
                  <div class="range__text">{{props.opacity}}</div>
                </div>
              </div>
            </div>
          </div>-->
          <!--<div class="text__area">
            <textarea class="form-control" placeholder="Enter your text" [(ngModel)]="textString"></textarea>
            <button (click)="addText()" class="btn btn-secondary" type="button">Add text</button>
          </div>-->
          <div class="text__buttons">
            <textarea class="form-control" placeholder="Lesson Heading" [(ngModel)]="snippetHeading"></textarea>
            <textarea class="form-control" placeholder="Lesson Body" [(ngModel)]="snippetText"></textarea>
            <button *ngIf="!isSnippetInFocus" (click)="addLessonSnippet()" class="btn btn-secondary btn-title" type="button">Add Lesson Snippet</button>
            <button *ngIf="isSnippetInFocus" (click)="editLessonSnippet()" class="btn btn-secondary btn-title" type="button">Edit Lesson Snippet</button>
            <div class="help-text">This is where you add your lessons to the story to practice in Study Mode.
              <p class="empty__sub-title">
                Select the lesson snippet and the graphic, and click on "Connect" to link them for Study Mode.
              </p>
            </div>
            <!--<button (click)="addText('subtitle')" class="btn btn-secondary btn-subtitle" type="button">Add Subtitle</button>
            <button (click)="addText('text')" class="btn btn-secondary btn-text" type="button">Add text</button>-->
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'templates'">
          <div class="content">
            <div class="search">
              <div class="input-group">
                <input [(ngModel)]="searchContent.templates"
                       type="text" class="form-control"
                       placeholder="What are you looking for?"
                       (keyup.enter)="onSearchContentByText(true)"
                >
                <div class="input-group-append">
                  <button (click)="onSearchContentByText(true)" class="button" type="button">
                    <img src="assets/icons/search_icon.png" alt="search">
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="editorData.templates.length === 0" class="empty">
              <img src="assets/icons/empty.svg" alt="empty-space">
              <p class="empty__title">There are no available <strong>{{activeView}}</strong></p>
              <p class="empty__sub-title">
                Please type your query into the search input to find what you're looking for
              </p>
            </div>
            <div class="wrapper"
                 #sidebarWrapper
                 *ngIf="editorData.templates.length > 0"
                 [ngStyle]="{'overflow-y': displayedInfo.tags.length && displayedInfo.likes ? 'hidden' : 'scroll'}"
            >
              <div *ngFor="let item of editorData.templates" class="item">
                <div class="item__image" (click)="addImageOnCanvas(item.image)"
                     [ngStyle]="{'background-image': 'url(' + item.preview + ')'}">
                  <img src="assets/icons/placeholder.svg" alt="">
                </div>
                <div class="item__more" (click)="showImageInfo(item.info)">
                  <img src="assets/icons/more-btn.svg" alt="">
                </div>
              </div>
              <div *ngIf="displayedInfo.tags.length && displayedInfo.likes" class="wrapper__modal">
                <div class="info">
                  <div class="tags" >
                    <div class="tag" (click)="onSelectTag(tag)" *ngFor="let tag of displayedInfo.tags">{{tag}}</div>
                  </div>
                  <div class="likes"><img src="assets/icons/like.png" alt="">{{displayedInfo.likes}}</div>
                  <div class="close" (click)="closeImageInfo()"><img src="assets/icons/close.png" alt=""></div>
                </div>
              </div>
              <button (click)="onSearchContentByText()" class="btn btn-secondary btn-title" type="button">Load More</button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'pages'">
          <ul class="pages">
            <li *ngFor="let item of savedPages; let i = index"
                (click)="onSelectActivePage(i)"
                class="pages__item"
                [ngClass]="pageVisibleId === i ? 'pages__item-active' : ''"
            >
              <div [ngClass]="i === pageForEditIndex ? 'invisible' : ''">
                <div class="text">{{item}}</div>
                <img (click)="onChangeNamePage(i)" class="image" src="assets/icons/edit.png" alt="edit">
                <img *ngIf="historyStack.length > 1" (click)="onOpenModalTopDeletePage(i)" class="image" src="assets/icons/trash.svg" alt="edit">
              </div>
              <div [ngClass]="i !== pageForEditIndex ? 'invisible' : ''">
                <input [(ngModel)]="newPageName" class="form-control form-control-sm" type="text" placeholder="Page name" aria-label=".form-control-sm example">
                <img (click)="renamePage()" class="image me-2" src="assets/icons/check-mark.png" alt="checkmark">
                <img (click)="cancelRename()" class="image" src="assets/icons/cancel.png" alt="cancel">
              </div>
            </li>
            <li class="pages__item">
              <div class="d-flex justify-content-center">
                <img (click)="onAddNewPage()" class="plus" src="assets/icons/plus.png" alt="edit">
              </div>
            </li>
          </ul>
        </ng-container>
      </ng-container>
    </div>

    <div class="col-md-1 d-md-block sidebar sidebar-right">
      <div #editorSidebarRight class="sidebar-sticky" *ngIf="!isLoading && user">
        <ul class="nav flex-column">
          <li class="nav-item">
            <div [ngStyle]="{'right': '15px'}"
                 class="fs-toggle nav-link">
              <a class="full-toggler" (click)="toggleFullScreen()">
                <img src="../../assets/icons/fullscreen-{{isFullScreen ? 'off' : 'on'}}.svg" alt="">
              </a>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Lock"
              placement="start"
              [adaptivePosition]="false"
              container="body"
          >
            <div class="nav-link" (click)="lockBackground()">
              <img [src]="!isImageBackgroundLocked ? 'assets/icons/lock-white.png' : 'assets/icons/unlock-white.png'" alt="">
              <div class="l-text">Lock background</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Flip Horizontal"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="flipHorizontal()"
          >
            <div class="nav-link">
              <img src="assets/icons/flip-h.png" alt="">
              <div class="l-text">Copy</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Flip Vertical"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="flipVertical()"
          >
            <div class="nav-link">
              <img src="assets/icons/flip-v.png" alt="">
              <div class="l-text">Copy</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Bring Forward"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="bringToFront()"
          >
            <div class="nav-link">
              <img src="assets/icons/to-top.svg" alt="">
              <div class="l-text">Move To Top</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Send Backward"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="sendToBack()"
          >
            <div class="nav-link">
              <img src="assets/icons/to-bottom.svg" alt="">
              <div class="l-text">Move To Bottom</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Copy"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="clone()"
          >
            <div class="nav-link">
              <img src="assets/icons/clone.svg" alt="">
              <div class="l-text">Copy</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Delete"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="removeSelected()"
          >
            <div class="nav-link">
              <img src="assets/icons/trash.svg" alt="">
              <div class="l-text">Delete</div>
            </div>
          </li>
          <div class="divider"></div>
          <li class="nav-item"
              tooltip="Save"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="onOpenModalForSave()"
          >
            <div class="nav-link">
              <img src="assets/icons/diskette.png" alt="">
              <div class="l-text">Save</div>
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link"
                 tooltip="Zoom In"
                 placement="start"
                 [adaptivePosition]="false"
                 container="body"
                 (click)="onZoom('in')"
            >
              <img src="assets/icons/plus.png" alt="">
              <div class="l-text">Zoom In</div>
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link"
                 tooltip="Zoom Out"
                 placement="start"
                 [adaptivePosition]="false"
                 container="body"
                 (click)="onZoom('out')"
            >
              <img src="assets/icons/minus.png" alt="">
              <div class="l-text">Zoom Out</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Undo"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="undo()"
          >
            <div class="nav-link">
              <img src="assets/icons/left-arrow.png" alt="">
              <div class="l-text">Undo</div>
            </div>
          </li>
          <li class="nav-item"
              tooltip="Redo"
              placement="start"
              [adaptivePosition]="false"
              container="body"
              (click)="redo()"
          >
            <div class="nav-link" style="transform: rotate(180deg)">
              <img src="assets/icons/left-arrow.png" alt="">
              <div class="l-text">Redo</div>
            </div>
          </li>
          <!--<li class="nav-item" (click)="cropImage()">
            <div class="nav-link">
              <img src="assets/icons/scissors.png" alt="">
              <div class="l-text">Cut</div>
            </div>
          </li>-->
        </ul>
      </div>
    </div>

    <!--<div #editorScrollBar class="scrollbar">
      &lt;!&ndash;<div class="scrollbar__header">
        <div *ngFor="let tab of tabs"
             (click)="onSelectActiveSection(tab.view)" class="scrollbar__item"
             [class.scrollbar__item-active]="tab.view === activeView"
        >
          {{tab.view.charAt(0).toUpperCase() + tab.view.slice(1)}}
        </div>
      </div>&ndash;&gt;
      &lt;!&ndash;<div class="scrollbar-show" (click)="onHideSideBar(editorScrollBar)"><img src="assets/icons/chevron-left.png" alt=""></div>&ndash;&gt;

      &lt;!&ndash;<div class="container-fluid">

      </div>&ndash;&gt;
    </div>-->
  </div>

  <button *ngIf="openAiModalCollapsed" (click)="onOpenAskHomiModal()" class="editor__button">
    <img src="assets/icons/open-collapsed.png">
    <p>{{currentConversation.title ? removeHtmlTags(currentConversation.title) : removeHtmlTags(currentConversation.chat[0].text)}}</p>
  </button>
</div>
<app-footer *ngIf="!isFullScreen"></app-footer>

<ng-template #saveProjectModal>
  <div class="modal-body text-center">
    <p class="modal__title">Save Your project</p>
    <div class="image"
         [ngStyle]="{'background-image': 'url(' + projectImage.src + ')'}">
      <img src="assets/icons/placeholder.svg" alt="">
    </div>
    <input [(ngModel)]="projectName" type="text" class="form-control" placeholder="Project name">
    <input style="display: none;" [(ngModel)]="projectSubtitle" type="text" class="form-control" placeholder="Project subtitle">
    <div>
      <button type="button" class="btn btn-primary" (click)="onSaveProject()">Save</button>
      <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #deletePageModal>
  <div class="modal-body text-center">
    <p class="modal__title">Are you sure?</p>
    <div>
      <button type="button" class="btn btn-primary" (click)="onDeletePage()">Delete</button>
      <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #askHomi>
  <div class="modal-header flex-column">
    <div class="top">
      <div class="modal__title d-flex"><img src="assets/icons/editor/idea.png" alt="">Imagine Wizard</div>
      <div>
        <img *ngIf="currentConversationID" class="close me-3" src="assets/icons/collapse.png" (click)="collapseOpenAiModal()">
        <img class="close" src="assets/icons/close.png" (click)="closeOpenAiModal()">
      </div>
    </div>
    <div class="modal__text">Get story ideas for your lesson with AI help.</div>
  </div>
  <div class="modal-body text-start modal-body_chat">
    <div class="modal__content row">
      <div class="modal__sidebar col-3">
        <div (click)="onNewConversation()"
             [ngClass]="!currentConversationID ? 'conversation conversation-active' : 'conversation'"
        >
          New Conversation
        </div>
        <ng-container *ngIf="GPTConversations">
          <div [ngClass]="{
            'conversation': true,
            'conversation-active': currentConversationID === conversation.id,
            'conversation-sample': conversation.title
          }"
               *ngFor="let conversation of GPTConversations"
          >
            <p (click)="onSelectConversation(conversation.id)">{{conversation.title ? removeHtmlTags(conversation.title) : removeHtmlTags(conversation.chat[0].text)}}</p>
            <div (click)="openDeletionConversationModal(conversation.id)">&#215;</div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!currentConversationID" class="modal__main col-9">
        <!-- <div class="modal__subtitle">
          1.Story plot
        </div>
        <p class="modal__tip">Chose Your style below.</p>
        <div class="modal__tabs">
          <div class="tab">
            <label>
              <input type="checkbox" (change)="onSelectStyle($event)" value="Movie plots"><span>Movie plots</span>
            </label>
          </div>
          <div class="tab">
            <label>
              <input type="checkbox" (change)="onSelectStyle($event)" value="Book plots"><span>Book plots</span>
            </label>
          </div>
          <div class="tab">
            <label>
              <input type="checkbox" (change)="onSelectStyle($event)" value="Classic fairytale"><span>Classic fairytale</span>
            </label>
          </div>
          <div class="tab">
            <label>
              <input type="checkbox" (change)="onSelectStyle($event)" value="Folktales"><span>Folktales</span>
            </label>
          </div>
        </div>
        <div class="modal__card card">
          <div class="card__header">
            <div class="modal__subtitle">Cars (2006)</div>
            <div href="#" class="modal__link">Try another</div>
          </div>
          <div class="card__text">
            A successfull but vain race car is forced to spend time in small town, learns about love, friendship and life.
          </div>
        </div>
        <p class="modal__tip">
          Tip: You can provide Your own story plot in step 3.
        </p>
        <div class="modal__divider"></div>
        <div class="modal__subtitle">
          2.The lesson
        </div>
        <div class="modal__tip">
          Provide Your lesson below. <div class="modal__link">Use a sample lesson</div> (overwrites the text below, if any)
        </div>
        <textarea class="form-control" rows="4" placeholder="Your lesson here"></textarea>
        <div class="modal__divider"></div> -->
        <div class="modal__subtitle">
          Provide your lesson and instructions below.
        </div>
        <div class="modal__tip">
          Check out the sample conversations on the left to see how to arrive at your ideal story.
        </div>
        <!--<textarea [(ngModel)]="openAiRequestText" class="form-control" rows="4" placeholder="Generate a story for the following lesson on the lines of Jack."></textarea>-->
        <div class="modal__textarea">
          <quill-editor class="modal__textarea"
                        [(ngModel)]="openAiRequestText"
                        [styles]="editorStyle"
                        (onContentChanged)="onCheckTextareaValue()"
                        [placeholder]="'e.g. Generate a story for the following lesson on the lines of Jack and the Beanstalk.'"
                        [modules]="quillConfig">
          </quill-editor>
        </div>
        <button type="button" class="btn btn-primary" (click)="openAiRequestNew()" [disabled]="openAiRequestText.length > 50000 || !openAiRequestText.length">
          <img src="assets/icons/editor/idea.png" alt="">
          <div>Get ideas</div>
          <img src="assets/icons/left-arrow.png" alt="">
        </button>
      </div>

      <div *ngIf="currentConversation && conversationsMessages.length" class="modal__main col-9">
        <div id="messagesContainer" class="modal__messages">
          <div *ngFor="let item of conversationsMessages" class="message" [ngClass]="item.role === 'assistant' ? 'message__assistant' : 'message__my'">
            <div class="message__avatar"
                 [style.background-image]="item.role === 'assistant' ? 'url(/assets/img/assistant.png)' : (user.photoURL ? getBackgroundStyle(user.photoURL) : 'url(/assets/img/avatar.png)') "
            >
              <img src="assets/icons/avatar-placeholder.svg" alt="placeholder">
            </div>
            <div class="message__text">
              <app-ai-message-container [text]="item.text"></app-ai-message-container>
            </div>
          </div>
          <div *ngIf="AIMessagesLoader" style="position:relative;">
            <app-loader [innerLoader]="true"></app-loader>
          </div>
        </div>
        <div class="modal-footer">
          <!--<quill-editor
                  [(ngModel)]="openAiRequestText"
                  [styles]="editorStyle"
                  [modules]="quillConfig"
                  class="ion-margin-bottom">
          </quill-editor>-->
          <textarea [(ngModel)]="openAiRequestText" class="form-control" rows="1" placeholder="Your prompt here"></textarea>
          <button type="button" class="btn btn-primary" (click)="openAiRequestNew()">
            <img src="assets/icons/editor/idea.png" alt="">
            <div>Get ideas</div>
            <img src="assets/icons/left-arrow.png" alt="">
          </button>
        </div>
      </div>
    </div>
    <!--<p class="modal__subtitle">Provide your Lesson Below</p>
    <p class="modal__text">If possible, provide your lesson as a list of points. Use upto 500 words</p>

    <textarea *ngIf="!openAiResponseData && !isLoadingOpenAi" [(ngModel)]="openAiRequestText" class="form-control h-100" rows="5" placeholder="Your lesson here"></textarea>

    <div *ngIf="isLoadingOpenAi" class="loader">
      <div class="loader-div">
        <span class="loader">
          <span></span>
          <span></span>
        </span>
      </div>
    </div>

    <div class="modal__response" *ngIf="openAiResponseData && !isLoadingOpenAi">
      <p class="modal__subtitle">Generated idea</p>
      <div class="modal__text">{{openAiResponseData}}</div>
    </div>

    <div *ngIf="openAiRequestText.length > 500" class="alert alert-danger" role="alert">
      Only 500 words are allowed.
    </div>
    <p *ngIf="!openAiResponseData" class="modal__text">Tip: You can provide instructions such as "use the plot of Jack and the Beanstalk", "suggest a whodunnit", "don't use process flow diagrams".</p>-->
  </div>
  <!--<div class="modal-footer justify-content-center">
    <button *ngIf="!openAiResponseData" type="button" class="btn btn-primary" (click)="openAiRequest()" [disabled]="openAiRequestText.length > 500 || !openAiRequestText.length || isLoadingOpenAi">
      <img src="assets/icons/editor/idea.png" alt="">
      <div>Get ideas</div>
      <img src="assets/icons/left-arrow.png" alt="">
    </button>
    <button *ngIf="openAiResponseData" (click)="tryAgainOpenAi()" type="button" class="btn btn-primary">
      Try again
    </button>
  </div>-->
</ng-template>

<ng-template #conversationDeletionConfirm>
  <div class="modal-body text-center">
    <p class="modal__title">You sure You want to delete conversation?</p>
    <div>
      <button type="button" class="btn btn-primary" (click)="deleteConversation()">Delete</button>
      <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
    </div>
  </div>
</ng-template>
