<app-loader *ngIf="isLoading"></app-loader>
<ng-container *ngIf="!isLoading && user">
  <app-header></app-header>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 d-md-block sidebar">
        <app-sidebar [isEditorAvailable]="isEditorAvailable"
                     (onOpenPricingModal)="onPlansOpen(plansModal)"
        >
        </app-sidebar>
      </div>
      <div class="col-md-9 ml-sm-auto col-lg-9 px-4" style="position: relative">
        <div class="view-wrapper">
          <div *ngIf="projects.length" class="content">
            <h3 *ngIf="projects.length > 0" class="d-none user-stream">Welcome to Homi! Memorize your lessons with stories.</h3>
            <h5 style="margin-bottom: 5px;color: #cdd4e6; font-weight: 600;">Your Homigrams</h5>
            <div class="content__wrapper">
              <div class="row">
                <div [class.col-md-8]="authService.features$.value.isQuestionBased"
                     [class.col-md-4]="!authService.features$.value.isQuestionBased"
                     class="col-sm-12 col-lg-6 mt-3"
                     *ngFor="let project of projects; let k = index">
                  <div class="project">
                    <div class="project__img">
                      <img [defaultImage]="'assets/lessons/images/placeholder.png'"
                           class="project__picture"
                           [lazyLoad]="onGetLessonImage(project.img)"
                           alt="">
                      <div *ngIf="!isEditorAvailable && k >= 2"
                           class="project__lock"
                           (click)="onPlansOpen(plansModal)"
                      >
                        <img src="assets/icons/lock.png" alt="locked">
                        <div class="delete" (click)="onOpenConfirmationToDeleteProject(deleteProjectModal, project.id, $event)"><img src="assets/icons/trash.svg" alt="delete"></div>
                      </div>
                      <div *ngIf="isEditorAvailable || k < 2" class="project__buttons project__buttons-left">
                        <div (click)="openProject(project.id)"><img src="assets/icons/play-button.png" alt="play"></div>
                        <div (click)="goToAskHomiOnEditor(false, project.id)"><img src="assets/icons/pencil.png" alt="edit"></div>
                        <div (click)="onOpenConfirmationToDeleteProject(deleteProjectModal, project.id)"><img src="assets/icons/garbage.png" alt="delete"></div>
                      </div>
                    </div>
                    <div [ngClass]="{'flex-column': project.projectSubtitle}">
                      <p class="project__title mt-2" [style.padding-bottom]="project.projectSubtitle ? '5px' : '0'">
                        {{project.projectName}}
                      </p>
                      <p class="project__sub-title" *ngIf="project.projectSubtitle">{{project.projectSubtitle}}</p>
                    </div>
                    <div *ngIf="user.isAdmin || user.isEditor" class="project__buttons project__buttons-right">
                      <div (click)="toggleStoryAsStarter(project.id)">{{project.isStarter ? 'Unmark Starter' : 'Mark as Starter'}}</div>
                      <div (click)="onPlansOpen(updateProjectThumbnailModal, project.id)">Edit Thumbnail</div>
                    </div>
                    <div *ngIf="project.isStarter && (user.isAdmin || user.isEditor)" class="project__icon">
                      <img src="assets/icons/s-icon.png" alt="starter project">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="content__empty" *ngIf="!projects.length">
              <img src="assets/icons/empty-space.png" alt="no-data">
              <div>Nothing to show here.</div>
            </div>-->
          </div>

          <div *ngIf="starterProjects.length && !user.isAdmin && !user.isEditor" class="content">
            <h4 style="margin-bottom: 5px;color: #cdd4e6; font-weight: 600;">Begin here</h4>
            <!-- <p>Design these story templates. Don't forget to save as your own.</p> -->
            <div class="content__wrapper">
              <div class="row">

                <div class="col-sm-12 col-lg-6 mt-3">
                  <div class="project">
                    <a href="/#/onboarding/">
                      <div class="project__img">
                        <img class="project__picture"
                            src="https://d1f5m9ybhzznuh.cloudfront.net/images/md/NmhH5LHBFiO2TC9Z9R7sd0NfvCh1-03-10-2024-11-43-14.jpeg"
                            alt="">
                      </div>
                    </a>
                    <div>
                      <p class="project__title mt-2">
                        <a href="/#/onboarding/">Spanish Basics (Trial)</a>
                      </p>
                    </div>
                  </div>
                </div>


                <div [class.col-md-8]="authService.features$.value.isQuestionBased"
                     [class.col-md-4]="!authService.features$.value.isQuestionBased"
                     class="col-sm-12 col-lg-6 mt-3"
                     *ngFor="let project of starterProjects; let k = index">
                  <div class="project" (click)="k < 0 && goToAskHomiOnEditor(false, project.id)">
                    <div class="project__img">
                      <img [defaultImage]="'assets/lessons/images/placeholder.png'"
                           class="project__picture"
                           [lazyLoad]="onGetLessonImage(project.img)"
                           alt="">
                      <div *ngIf="k >= 0"
                           class="project__lock"
                           (click)="onPlansOpen(plansModal)"
                      >
                        <img src="assets/icons/lock.png" alt="locked">
                      </div>
                      <div *ngIf="k < 0" class="project__buttons project__buttons-left">
                        <div><img src="assets/icons/pencil.png" alt="edit"></div>
                      </div>
                    </div>
                    <div [ngClass]="{'flex-column': project.projectSubtitle}">
                      <p class="project__title mt-2" [style.padding-bottom]="project.projectSubtitle ? '5px' : '0'">
                        {{project.projectName}}
                      </p>
                      <p class="project__sub-title" *ngIf="project.projectSubtitle">{{project.projectSubtitle}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="top">
            <h3 *ngIf="projects.length === 0" class="user-stream">Welcome to Homi!</h3>
            <h5 style="color: #cdd4e6; font-weight: 600;">Memorize anything with a story in 3 simple steps:</h5>
            <div class="row">
              <div class="col-4">
                <div class="top-card card">
                  <h4 class="card__title">1.Imagine</h4>
                  <p class="card__text">
                    Begin by imagining your lesson as a story.
                    Take help from our AI-enabled Imagine Wizard.
                  </p>
                  <button (click)="goToAskHomiOnEditor(true)" class="card__button">Launch Imagine Wizard <img src="assets/icons/right-arrow.svg" alt="arrow"></button>
                  <!-- <div *ngIf="!isEditorAvailable" class="card__lock"><img src="assets/icons/lock.png" alt="locked"></div> -->
                </div>
              </div>
              <div class="col-4">
                <div class="top-card card">
                  <h4 class="card__title">2.Design</h4>
                  <p class="card__text">Once you have an idea, design the story using our Story Editor.</p>
                  <button (click)="goToAskHomiOnEditor(false)" class="card__button">Go to Story Editor <img src="assets/icons/right-arrow.svg" alt="arrow"></button>
                  <!-- <div *ngIf="!isEditorAvailable" class="card__lock"><img src="assets/icons/lock.png" alt="locked"></div> -->
                </div>
              </div>
              <div class="col-4">
                <div class="top-card card">
                  <h4 class="card__title">3.Practice</h4>
                  <p class="card__text">Practice with the story in Study Mode to reinforce it in your memory.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="videos d-none">
            <h5 style="margin-bottom: 5px;">Take a memory challenge</h5>
            <p>Learn how you can memorize better and improve your grades</p>

            <div class="videos__content row">
              <div class="col-4">
                <div [style.background-image]="'url(/assets/lessons/images/01-decentralisation.jpg)'">
                  <img src="assets/icons/placeholder-sq.svg" alt="placeholder">
                  <div class="play-button"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="explainer">
            <h5 style="color: #cdd4e6; font-weight: 600;">Watch our video</h5>
            <p>Learn about your memory and how you can get the most out of it.</p>
            <div #explainer class="explainer__video">
              <div class="wistia_responsive_padding" style="padding: 0;position:relative;"><div class="wistia_responsive_wrapper" style="left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_r8wvcdyrx8 seo=true videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/r8wvcdyrx8/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

</ng-container>

<ng-template #template>
  <div class="modal-body text-center" style="padding-top: 30px">
    <span class="close-icon" (click)="onCancel()">+</span>
    <h3 class="modal__title">Welcome to Homi!</h3>
    <p class="modal__sub-title">Please tell us a little bit about yourself</p>
    <div>
      <div class="user-info__item">
        <label>
          First Name:
          <input type="text"
                 placeholder="First Name..."
                 #userFirstName
                 [value]="user.firstName"
                 (keyup)="user.firstName = userFirstName.value; isInformationUpdated = true">
        </label>
      </div>
      <div class="user-info__item">
        <label>
          Last Name:
          <input type="text"
                 placeholder="Last Name..."
                 #userLastName
                 [value]="user.lastName"
                 (keyup)="user.lastName = userLastName.value; isInformationUpdated = true">
        </label>
      </div>
    </div>
    <button type="button" class="btn btn_remove" (click)="onCancel()" >Cancel</button>
    <button type="button"
            class="btn btn_select"
            [disabled]="!isInformationUpdated"
            (click)="onUpdateUserInfo()" >Save</button>
  </div>
</ng-template>

<ng-template #plansModal>
  <div class="modal-body text-center">
    <p class="modal__title">
      You can access only one Homigram with a free account. Please upgrade to a premium account for full access.
      Check out our plans.
    </p>
    <!--<p class="modal__sub-title">Please purchase a subscription to access it</p>-->
    <button type="button" class="btn btn-primary" (click)="onPricingNav()">View plans</button>
    <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
  </div>
</ng-template>

<ng-template #updateProjectThumbnailModal>
  <div class="modal-body text-center">
    <p class="modal__title">Set new thumbnail image:</p>
    <input type="text"
           class="modal__input"
           placeholder="New thumbnail..."
           #newThumbUrl>
    <button type="button" class="btn btn-primary" (click)="onEditStoryThumbnail(newThumbUrl.value)">Update</button>
    <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
  </div>
</ng-template>

<ng-template #deleteProjectModal>
  <div class="modal-body text-center">
    <p class="modal__title">Are You sure you want to delete the project?</p>
    <button type="button" class="btn btn-primary" (click)="onDeleteProject()">Yes</button>
    <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
  </div>
</ng-template>



