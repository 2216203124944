import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  billingData: any = {};

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const user = this.authService.user$.value;
    this.billingData.name = user.access?.name ? user.access.name : '';
    this.billingData.purchaseDate = user.access?.purchaseDate?.split('T')[0] ? user.access.purchaseDate.split('T')[0] : '';
    this.billingData.expDate = user.access?.expDate?.split('T')[0] ? user.access.expDate.split('T')[0] : '';
    this.billingData.status = user.access?.status ? user.access.status : '';
  }
}
