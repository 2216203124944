import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private testApi = 'https://homi-stt.herokuapp.com';
  headers = {'content-type': 'application/json'};

  constructor(
      private http: HttpClient
  ) { }

  generateOrder(amount: number) {
    const options = {'amount': amount * 100}
    return this.http.post(`${this.testApi}/create/orderId`, JSON.stringify(options), {headers: this.headers});
  }

  verifyPayment(data: any) {
    return this.http.post(`${this.testApi}/api/payment/verify`, JSON.stringify(data), {headers: this.headers});
  }
}
