<img alt="" src="assets/img/homi-logo.png" style="width:40px;">
<small [innerHTML]="item.instruction"></small>
<p class="mb-3 px-0 color-composition" [innerHTML]="item.shortCue"></p>
<div class="destination" #destination></div>
<hr class="border border-muted mt-0">
<div class="origin" #origin *ngIf="item && item.words">
  <div class="word-wrapper" *ngFor="let word of item.words">
    <button class="word" (click)="onSelectWord($event)">{{word}}</button>
  </div>
</div>
