<app-loader [isBlurred]="true" *ngIf="isLoading"></app-loader>
<div class="cards">
  <div *ngFor="let card of cards; let i = index" class="card">
    <div class="circles">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
    <div class="h1 card__title">
      <span *ngIf="i !== 0">Rs.</span>{{card.price}}
      <h2 class="subtitle">
        {{card.subTitle}}
      </h2>
      <div class="shortDesc">
        {{card.shortDesc}}
      </div>
    </div>
    <div class="card__lessons">
      {{card.lessons}}
    </div>
    <ul class="card__text">
      <li *ngFor="let line of card.text">
        {{line}}
      </li>
    </ul>
    <button *ngIf="user.access.name === 'free' || user.access.name === card.plan"
            (click)="buyPlan(card.price, card.plan, card.shortDesc, paymentModal)"
            [ngClass]="card.plan !== user.access.name ? 'card__button card__button-dynamic' : 'card__button card__button-static'"
    >
      {{card.plan !== user.access.name ? 'Buy plan' : 'Current plan'}}
    </button>
  </div>
</div>

<ng-template #modal>
  <div class="modal-body text-center">
    <p class="modal__title">Thank you for your purchase. </p>
    <p class="modal__sub-title">You can now access all lessons.</p>
    <button type="button" class="btn" (click)="onModalClose()">Go Home</button>
  </div>
</ng-template>

<ng-template #modalFailure>
  <div class="modal-body text-center">
    <p class="modal__title">Sorry, your payment did not go through. Please try again.</p>
    <button type="button" class="btn danger" (click)="onModalClose()">Retry</button>
  </div>
</ng-template>

<ng-template #paymentModal>
  <div class="modal-body">
    <p class="modal__title text-center mb-4">
      {{description}}
    </p>
    <div id="paypal-button-container"></div>
  </div>
</ng-template>


