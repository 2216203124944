import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PaymentService} from "../../shared/services/payment.service";
import {AuthService} from "../../shared/services/auth.service";
import {IUser} from "../../shared/models/user.model";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import {Subject, takeUntil} from "rxjs";
declare const paypal: any;


@Component({
  selector: 'app-pricing-cards',
  templateUrl: './pricing-cards.component.html',
  styleUrls: ['./pricing-cards.component.scss']
})
export class PricingCardsComponent implements OnInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal!: TemplateRef<any>;
  @ViewChild('modalFailure', {static: true}) modalFailure!: TemplateRef<any>;
  selectedPlan = '';
  description = '';
  isFirst = true;
  isLoading = false;
  user!:IUser;
  modalRef?: BsModalRef;

  //test
  /*key = 'rzp_test_uXcezn5RiQt7pE';*/
  //prod
  key = 'rzp_live_YgqLNl4L2CvMNv';

  cards = [
    {
      price: 'Free.',
      subTitle: '',
      shortDesc: '✔ Free plan',
      lessons: '1 Homigram',
      text: ['30 assets'],
      plan: 'free'
    },
    {
      price: '$89',
      subTitle: 'per year',
      shortDesc: '✔ Annual plan',
      lessons: 'Unlimited Homigrams',
      text: ['Unlimited assets'],
      plan: 'year',
      id: 'button1'
    },
    {
      price: '$10',
      subTitle: 'per month',
      shortDesc: '✔ Monthly plan',
      lessons: 'Unlimited Homigrams',
      text: ['Unlimited assets'],
      plan: 'month',
      id: 'button2'
    }
  ]

  $destroy = new Subject();

  /*options = {
    "key": this.key,
    "amount": 50000,
    "currency": "INR",
    "name": "Homi",
    "description": "Test Transaction",
    "image": "assets/img/homi-logo.png",
    "order_id": '',
    "handler": (response: any) => {
      /!*alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature);*!/

      this.paymentService.verifyPayment(response).subscribe(result => {
        if (result) {
          this.modalService.show(this.modal, {class: 'modal-sm modal-dialog-centered'});
          this.setPaymentData();
        } else {
          this.modalService.show(this.modalFailure, {class: 'modal-sm modal-dialog-centered'});
        }
      })
    },
    "prefill": {
      "name": this.user.displayName,
      "email": this.user.email,
    },
    "theme": {
      "color": "#233668"
    }
  }*/

  constructor(
      private paymentService: PaymentService,
      private authService: AuthService,
      private modalService: BsModalService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.user$.pipe(takeUntil(this.$destroy)).subscribe(user => {
      this.user = user;
    });
  }

  generatePayPalButtons(amount: string) {
    paypal.Buttons({
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: amount
              }
            }
          ]
        });
      },
      onApprove: (data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
          // Handle successful payment
          this.user.access.purchaseDate = details.update_time;
          if (this.selectedPlan === 'year') {
            this.user.access.expDate = dayjs(this.user.access.purchaseDate).add(1, 'year').utc().format();
            this.user.access.name = this.selectedPlan;
            this.user.access.status = 'Active';
          } else if (this.selectedPlan === 'month') {
            this.user.access.expDate = dayjs(this.user.access.purchaseDate).add(1, 'month').utc().format();
            this.user.access.name = this.selectedPlan;
            this.user.access.status = 'Active';
          }
          this.authService.SetUserData(this.user).then(() => {
            this.modalRef?.hide();
          });
        });
      },
      onError: (err: any) => {
        // Handle errors
        console.error(err);
      }
    }).render('#paypal-button-container');
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  buyPlan(price: string, plan: string, descr: string, element: any) {
    this.selectedPlan = plan;
    this.description = descr;
    this.modalRef = this.modalService.show(element, {class: 'modal-dialog modal-dialog-centered modal-sm'})
    this.generatePayPalButtons(price.replace('$', '') + '.00')

    /*this.isLoading = true;
    this.selectedPlan = plan;
    this.paymentService.generateOrder(+price).subscribe((res: any) => {
      this.isLoading = false;
      this.options.amount = +price * 100;
      this.options.order_id = res.orderId;

      const rzp1 = new Razorpay(this.options);
      rzp1.open();
    })*/
  }

  setPaymentData() {
    /*let user: IUser;
    this.authService
        .getUser()
        .pipe(take(1))
        .subscribe(resp => {
      user = JSON.parse(JSON.stringify(resp));
      user.access.purchaseDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      if (this.selectedPlan === 'year') {
        user.access.expDate = dayjs().add(1, 'year').format('YYYY-MM-DDTHH:mm:ss')
        user.access.name = this.selectedPlan;
        user.access.status = 'Active';
      } else if (this.selectedPlan === 'month') {
        user.access.expDate = dayjs().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss');
        user.access.name = this.selectedPlan;
        user.access.status = 'Active';
      }
      this.authService.SetUserData(user).then();
    });*/
  }

  onModalClose() {
    this.router.navigate(['/home']).then(() => {
      this.modalService.hide();
    })
  }
}
