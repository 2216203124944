<app-loader *ngIf="isLoading && !isPortrait"></app-loader>
<div *ngIf="isMobile && isPortrait" class="rotate">
  <img src="../../assets/icons/icon_landscape.png" alt="" class="rotate__img">
  <p class="rotate__title">Please rotate your device</p>
  <p class="rotate__sub-title">We do not support portrait mode, please use the app in landscape mode for better experience</p>
</div>
<ng-container *ngIf="project">
  <!--<app-header *ngIf="!isFullScreen"></app-header>-->
  <canvas style="position: absolute; z-index: -1" id="supportedCanvas"></canvas>
  <div *ngIf="!isPortrait"
       class="header lesson__header"
       [ngClass]="isNavigationHidden ? 'header header-hidden': ''">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12 lesson__header_left"
             style="position: relative;padding: 10px 60px 10px 15px;"
             [class.lesson__header_sub-title]="project.projectSubtitle">
          <div class="lesson__title" ellipsisScroll>{{project.projectName}}</div>
          <div class="lesson__sub-title"
               *ngIf="project.projectSubtitle"
               ellipsisScroll>{{project.projectSubtitle}}
          </div>
          <div class="timer">
            {{currentTime}}
          </div>
          <div [tooltip]="(isFullScreen ? 'Exit' : 'Enter') + ' Distraction Free Mode'"
               class="fs-toggle">
            <a class="nav-link full-toggler" (click)="toggleFullScreen()">
              <img src="../../assets/icons/fullscreen-{{isFullScreen ? 'off' : 'on'}}.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="project__content"
       [ngClass]="{'fullscreen': isFullScreen, 'fullscreen-active': isNavigationHidden}">
    <div class="h-100 d-flex justify-content-center">
      <div #canvasWrapper id="wrapper" class="canvas">
        <canvas id="canvas" #htmlCanvas [ngStyle]="{'height': size.height + 'px', 'width': size.width + 'px'}"></canvas>
      </div>
      <!--<div class="col-md-3 d-md-block sidebar" *ngIf="!isFullScreen">
        <div class="sidebar-sticky lesson__sidebar">
          <div class="lesson__title" ellipsisScroll>{{project.projectName}}</div>
          <p class="lesson__sub-title">
            It would be better for UI/UX if you can place some description here
          </p>
          <div class="row align-items-center">
            <div class="col-9">
              <div class="progress">
                <div class="progress__line"
                     [style.width]="lessonProgress + '%'"></div>
              </div>
            </div>
            <div class="col-3 text-center">
              <p class="progress__percentage">{{lessonProgress}}%</p>
            </div>
          </div>
          <div class="row" *ngIf="selectedSnippet + 1 <= lesson.lessonSnippets.length">
            <div class="col lesson__activity">
              Activity <strong>{{selectedSnippet + 1}}</strong> of <strong>{{lesson.lessonSnippets.length}}</strong>
            </div>
          </div>
        </div>
      </div>-->
      <!--<div [ngClass]="isFullScreen ? 'col-md-12 col-lg-12' : 'col-md-9 col-lg-9 px-4 '"
           class="ml-sm-auto lesson">
        <div *ngIf="!isFinished" class="lesson__wrapper">
          &lt;!&ndash;<div class="activity-container"
               [class.result]="result">
            <div class="img-wrapper" (click)="navbarAnimation()">
              <img #lessonImage
                   [src]="onGetLessonImage()"
                   alt="">
            </div>
            <ng-container *ngIf="lessonImage.complete">
              <ng-container *ngFor="let value of lesson.lessonSnippets; let i = index">
                <ng-container *ngIf="value.isVisible">
                  <app-learn *ngIf="value.type == 'lesson'"
                             [item]="value"
                             [style]="value.style"
                             [ngClass]="value.arrow ? 'arrow ' + value.arrow : ''"
                             class="lesson-snippet visible">
                  </app-learn>

                  <app-not-lesson *ngIf="value.type == 'not_lesson'"
                                  [item]="value"
                                  [style]="value.style"
                                  [ngClass]="value.arrow ? 'arrow ' + value.arrow : ''"
                                  class="lesson-snippet visible">
                  </app-not-lesson>

                  <ng-container *ngIf="value.type=='quiz'">

                    <app-word-bank *ngIf="value.quizType == 'wordBank'"
                                   (onChange)="wordBankStr = $event"
                                   [item]="value"
                                   [style]="value.style"
                                   [ngClass]="value.arrow ? 'arrow ' + value.arrow : ''"
                                   class="lesson-snippet visible"></app-word-bank>

                    <app-fill *ngIf="value.quizType == 'fillInTheBlanks'"
                              (onChange)="fillArr = $event"
                              [item]="value"
                              [style]="value.style"
                              [ngClass]="value.arrow ? 'arrow ' + value.arrow : ''"
                              class="lesson-snippet visible"></app-fill>

                    <app-write *ngIf="value.quizType == 'writeAnswer'"
                               (onChange)="answer = $event"
                               [item]="value"
                               [style]="value.style"
                               [ngClass]="value.arrow ? 'arrow ' + value.arrow : ''"
                               class="lesson-snippet visible"></app-write>

                    <app-speak *ngIf="value.quizType == 'speakAnswer'"
                               (onChange)="answer = $event"
                               [item]="value"
                               [style]="value.style"
                               [ngClass]="value.arrow ? 'arrow ' + value.arrow : ''"
                               class="lesson-snippet visible"></app-speak>

                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>&ndash;&gt;
          &lt;!&ndash;<div class="lesson__controls row align-items-center"
               *ngIf="!isFullScreen"
               [class.correct]="result === 'correct'"
               [class.wrong]="result === 'wrong'">
            <div class="col-8 result">
              <ng-container *ngIf="!result">
                <button (click)="openModal(template)"
                        style="margin-left: 0"
                        class="btn lesson__btn btn_skip">Exit Lesson
                </button>
                &lt;!&ndash;<button (click)="isFullscreen = true"
                        class="btn lesson__btn btn_skip">Fullscreen
                </button>&ndash;&gt;
              </ng-container>
              &lt;!&ndash;<ng-container *ngIf="result === 'correct'">
                <p class="result__title">Great!</p>
                <p class="result__sub-title">This is the correct answer.</p>
              </ng-container>
              <ng-container *ngIf="result === 'wrong'">
                <p class="result__title">Correct answer:</p>
                <p class="result__sub-title"
                   [title]="lesson.lessonSnippets[selectedSnippet].correctAnswer">
                  {{lesson.lessonSnippets[selectedSnippet].correctAnswer}}
                </p>
              </ng-container>&ndash;&gt;
            </div>
            <div class="col text-right">
              <button *ngIf="!result"
                      (click)="onSkip()"
                      class="btn lesson__btn btn_skip">Skip
              </button>
              <button *ngIf="result"
                      (click)="onChangeSnippet('next')"
                      class="btn lesson__btn">{{true ? 'Finish' : 'Next'}}
              </button>
              <button *ngIf="!result"
                      (click)="onCheck()"
                      class="btn lesson__btn">Check
              </button>
            </div>
          </div>&ndash;&gt;
        </div>
        <div *ngIf="isFinished" class="lesson__results results">
          <ng-container *ngIf="isSuccessfullyCompleted">
            <img src="../../assets/img/res.svg" alt="" class="results__img">
            <p class="results__title">Congratulations, you levelled up!</p>
            <p class="results__sub-title">You have successfully completed this lesson</p>
          </ng-container>
          <ng-container *ngIf="!isSuccessfullyCompleted">
            <img src="../../assets/img/finished.svg" alt="" class="results__img">
            <p class="results__title">End of lesson!</p>
            <p class="results__sub-title">You have finished this lesson</p>
          </ng-container>

          <div class="text-center" *ngIf="questionsCount > 0">
            <span class="results__correct">Correct - <span>{{correctAnswers}}</span></span>
            <span style="display: inline-block; width: 30px"></span>
            <span class="results__wrong">Wrong - <span>{{wrongAnswers}}</span></span>
          </div>

          <button class="results__btn" (click)="onGoBackToHome()" style="margin: 30px 0">Go Back To Projects</button>
        </div>
      </div>-->
    </div>
  </div>
  <!--<app-footer *ngIf="!isFullScreen"></app-footer>-->
  <footer *ngIf="!isPortrait"
          [ngClass]="{'footer_fullscreen': isFullScreen, 'footer-hidden': isNavigationHidden}"
          class="footer">
    <div class="container-fluid">
      <div class="row lesson__controls"
           [class.correct]="result === 'correct'"
           [class.wrong]="result === 'wrong'">
        <div class="col-3">
            <div class="d-flex justify-content-start text-left">
              <button (click)="isFinished ? onExitConfirm() : openModal(template)"
                      [disabled]="isExitButtonDisabled"
                      style="margin-left: 0"
                      class="btn lesson__btn btn_skip">Exit Lesson
              </button>
              <button (click)="goToEditor()"
                      style="margin-left: 10px"
                      class="btn lesson__btn btn_skip">Edit
              </button>
            </div>
            <!--<button (click)="isFullscreen = false"
                    class="btn lesson__btn btn_skip">Exit Fullscreen
            </button>-->
          <!--<ng-container *ngIf="result === 'correct'">
            <div class="result__wrapper">
              <p class="result__title">Great!</p>
              <p class="result__sub-title">This is the correct answer.</p>
            </div>
          </ng-container>-->
          <!--<ng-container *ngIf="result === 'wrong'">
            <div class="result__wrapper">
              <p class="result__title">Correct answer:</p>
              <p class="result__sub-title"
                 [title]="lesson.lessonSnippets[selectedSnippet].correctAnswer">
                {{lesson.lessonSnippets[selectedSnippet].correctAnswer}}
              </p>
            </div>
          </ng-container>-->
        </div>
        <div [ngClass]="project.state.length > 1 ? 'col-6' : 'col-8'">
          <div class="progress-wrapper">
            <div class="progress">
              <div class="progress__line" [style.width]="projectProgress + '%'"></div>
            </div>
            <span class="progress__percentage">{{projectProgress}}%</span>
          </div>
        </div>
        <div [ngClass]="project.state.length > 1 ? 'col-3' : 'col-1'">
          <div class="d-flex justify-content-end text-right">
            <button *ngIf="project.author === user.uid"
                    (click)="onResetProject()"
                    class="btn lesson__btn">Reset
            </button>
            <button
              *ngIf="project.state.length > 1"
              [disabled]="pageIndex <= 0"
              (click)="onChangeSnippet('prev')"
              class="btn lesson__btn"
            >
              Previous
            </button>
            <button
              [disabled]="pageIndex >= project.state.length - 1"
              *ngIf="project.state.length > 1"
              (click)="onChangeSnippet('next')"
              class="btn lesson__btn"
            >
              Next
            </button>
            <!--<button *ngIf="!result && lesson.lessonSnippets[selectedSnippet].correctAnswer"
                    (click)="onSkip()"
                    class="btn lesson__btn btn_skip">Skip
            </button>
            <button *ngIf="!result && lesson.lessonSnippets[selectedSnippet - 1] && (lesson.lessonSnippets[selectedSnippet - 1].type === 'lesson' || lesson.lessonSnippets[selectedSnippet - 1].type === 'not_lesson')"
                    (click)="onChangeSnippet('prev')"
                    class="btn lesson__btn">Back
            </button>
            <button *ngIf="result || !lesson.lessonSnippets[selectedSnippet].correctAnswer"
                    (click)="onChangeSnippet('next')"
                    class="btn lesson__btn">{{selectedSnippet + 1 === lesson.lessonSnippets.length ? 'Finish' : 'Next'}}
            </button>
            <button *ngIf="lesson.lessonSnippets[selectedSnippet].correctAnswer && !result"
                    [disabled]="isCheckDisabled"
                    (click)="onCheck()"
                    class="btn lesson__btn">Check
            </button>-->
          </div>
        </div>
      </div>
    </div>
  </footer>

  <ng-template #template>
    <div class="modal-body text-center">
      <p class="modal__title">Do you want to exit?</p>
      <p class="modal__sub-title">If you exit the lesson you will lose all progress</p>
      <button type="button" class="btn danger" (click)="onExitConfirm()">Exit</button>
      <button type="button" class="btn btn-primary" (click)="modalRef?.hide()" >Cancel</button>
    </div>
  </ng-template>
</ng-container>

