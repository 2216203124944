import {Component} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment.prod';
import { S3ServiceService } from './shared/services/s3-service.service';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {AppModule} from "./app.module";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angularfiebase-authentication';
  isMobileDevice!: boolean;

  uploads: any[] = []; // S3
  imagesLinksForDatabase: any[] = []; // S3
  imgSizes = {md: {maxWidth: 1200, maxHeight: 1200,}, sm: {maxWidth: 1200, maxHeight: 1200}} // S3
  mobileFriendlyPage!: boolean;
  isLoading = true;

  constructor(
      private deviceService: DeviceDetectorService,
      private s3Service: S3ServiceService, // S3
      private router: Router
  ) {
    deviceService.isMobile() ? this.isMobileDevice = true : this.isMobileDevice = false;

    this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          this.mobileFriendlyPage = event.urlAfterRedirects.includes('onboarding') || event.urlAfterRedirects.includes('sign-in') || event.urlAfterRedirects.includes('register-user') || event.urlAfterRedirects.includes('forgot-password');
          this.isLoading = false;
        })
  }


  // S3 CLOUDFRONT
  onFileDropped(files: any) {
    const directory = 'images';
    Array.prototype.forEach.call(files, (file: any) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const dataUrl = event.target.result || '';
        this.s3Service.uploadFile(file, `${directory}/md`, file.name).then(() => {
          const imageUrl = `https://${environment.AWS.CLOUDFRONT}/${directory}/md/${file.name}`;
          this.imagesLinksForDatabase.push({ tempLink: dataUrl, databaseLink: imageUrl });
        })
      };
      reader.readAsDataURL(file);
    })
  }
}
