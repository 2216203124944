<app-loader *ngIf="isLoading"></app-loader>
<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 d-md-block sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="view-wrapper">
        <div class="head d-flex gap-5 pb-5 pb-sm-0">
          <div class="half">
            <h1 class="title">
              Pricing
            </h1>
            <p class="head__text">
              We offer a simple subscription plan.
              You can choose between a monthly or an annual subscription.
            </p>
          </div>
          <ul class="head__benefits half">
            <li *ngFor="let benefit of benefits">
              {{benefit}}
            </li>
          </ul>
        </div>
        <app-pricing-cards></app-pricing-cards>
        <a class="policy" href="https://homi.school/pricing/" target="_blank">Read about payment options, cancellation and refund policy here.</a>
        <!--<div class="faq">
          <h1 class="h1 display-3">
            Pricing FAQs
          </h1>
          <ul class="faq__list">
            <li class="faq__item" *ngFor="let item of faq; let i = index" (click)="openInfo(i)">
              <div class="toggle">&#8250;</div>
              <h2 class="title">{{item.title}}</h2>
              <div class="text">
                {{item.text}}
              </div>
            </li>
          </ul>
        </div>-->
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
