import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ILessonSnippet} from '../../../shared/models/lesson.model';
import {PlayerFile, PlayerTheme, PlayerThemeLight} from 'gs-player';
import {GsPlayerComponent} from "gs-player/lib/gs-player.component";
import {AuthService} from "../../../shared/services/auth.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-not-lesson',
  templateUrl: './not-lesson.component.html',
  styleUrls: ['./not-lesson.component.scss']
})
export class NotLessonComponent implements OnInit, OnDestroy {
  @ViewChild('player') player: GsPlayerComponent | undefined;
  @Input() item: ILessonSnippet = {};
  files: PlayerFile[] = [];
  playerTheme: PlayerTheme = PlayerThemeLight;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    if (this.item.narration) {
      this.files.push({
        url: this.onGetAudio(),
        name: this.item.narration
      })
    }
  }

  onGetAudio() {
    const isFirebase = this.authService.features$.value.isFirebase;
    const audio = this.item.narration;
    const firebaseSource = `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/narrations%2F`;
    const source = '../../../assets/lessons/audio/';

    if (audio?.includes(firebaseSource)) {
      return isFirebase ? audio : source + audio.replace(firebaseSource, '').split('?alt=media')[0]
    } else {
      return source + audio;
    }
  }

  ngOnDestroy() {
    this.player?.stop()
  }
}
