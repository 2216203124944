<div class="disabler">
  <app-header></app-header>
  <span class="disabler__content">
    <img src="assets/img/computer.png" alt="laptop">
    <!--<div>Sorry, we are not available on mobile devices yet. Use a desktop, laptop or tablet to access Homi's visual mnemonics app.</div>-->
    <div>
      <p>Sorry, we are not available on mobile devices yet. Use a desktop or laptop to access Homi.</p>
      <p>Check out the <a href="https://homi.school/">Homi website</a>.</p>
    </div>
  </span>
</div>
