import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ILessonSnippet} from '../../../shared/models/lesson.model';
import {NgAudioRecorderService} from "ng-audio-recorder";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-speak',
  templateUrl: './speak.component.html',
  styleUrls: ['./speak.component.scss']
})
export class SpeakComponent implements OnInit{
  @Input() item: ILessonSnippet = {};
  @Output() onChange: any = new EventEmitter()
  isRecording = false;
  isProcessing = false;
  progress = '';
  audio = '';
  text = '';

  ngOnInit() {

    console.log(this.item)
  }

  constructor(
    private http: HttpClient,
    private audioRecorderService: NgAudioRecorderService,
  ) {
  }

  startRecording() {
    this.audio = '';
    this.text = '';
    this.isRecording = true;
    this.audioRecorderService.startRecording();
  }

  stopRecording() {
    this.isRecording = false;
    this.audioRecorderService.stopRecording(1).then((output) => {
      this.blobToBase64(output).then((r: any) => {
        this.audio = r.replace('data:audio/webm;base64,', '');
        this.onTransformSTT(this.audio);
      })
    }).catch(() => {
    });
  }

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  onTransformSTT(audio: string) {
    this.isProcessing = true;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.http.post(
      'https://homi-stt.herokuapp.com/stt',
      {"audio": audio},
      {headers: headers}
    ).subscribe(resp => {
      this.isProcessing = false;
      // @ts-ignore
      this.text = resp.text;
      // @ts-ignore
      this.onChange.emit(resp.text);
    })
  }
}
