import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import {EditorComponent} from "../../components/editor/editor.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {LeavePageModalComponent} from "../modals/leave-page-modal/leave-page-modal.component";
import {Observable, take} from "rxjs";

@Injectable()
export class LeavePageGuard implements CanDeactivate<EditorComponent> {

    modalRef?: BsModalRef;
    constructor(private modalService: BsModalService) {}

    /*canDeactivate(component: EditorComponent): Promise<boolean> {
        console.log('DEACTIVATE')

        let confirmationModal = this.modalService.show(LeavePageModalComponent,  {class: 'modal-sm modal-dialog-centered'});

        return new Promise<boolean>((resolve) => {
            confirmationModal.content!.confirmAction.subscribe((confirmed: boolean) => {
                resolve(confirmed);
                confirmationModal.hide();
            });
        });
    }*/

    canDeactivate(component: EditorComponent): Observable<boolean> | Promise<boolean> | boolean {
        let confirmationModal = this.modalService.show(LeavePageModalComponent, { class: 'modal-sm modal-dialog-centered' });

        return new Observable<boolean>((observer) => {
            return confirmationModal.content!.confirmAction.subscribe((confirmed: boolean) => {
                observer.next(confirmed);
                observer.complete();
                confirmationModal.hide();
            });
        });
    }
}
