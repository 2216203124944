import { Component, OnInit } from '@angular/core';
import {IUser} from "../../shared/models/user.model";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {filter, ReplaySubject, takeUntil} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";
import {IProject} from "../../shared/models/projects.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  isLoading = true;
  user!: IUser;
  activeTab = 'Personal';
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  projects!: IProject[];
  userProjects!: IProject[];
  tabs: any[] = ['Personal'];

  constructor(
      public afs: AngularFirestore,
      public authService: AuthService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.isLoading = false;
    this.authService.getUser().pipe(filter(u => u && !u.initial)).pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.user = user;
      this.afs.collection('projects').valueChanges().subscribe((res: any) => {
        this.projects = res;
        this.userProjects = res.filter((item: IProject) => {
          return item.author === this.user?.uid;
        });
        if (this.projects.length !== this.userProjects.length) {
          this.tabs.push('All');
        }
        res.forEach((item: any) => {
          //console.log(item)
        })
      })
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onChangeTab(tab: string) {
    this.activeTab = tab;
  }

  openProject(id: string) {
    this.router.navigate(['/project', id]).then()
  }

  onGetLessonImage(img: string) {
    return img;
  }
}
