<app-loader *ngIf="isLoading"></app-loader>
<ng-container *ngIf="!isLoading && user">
  <app-header></app-header>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 d-md-block sidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-md-9 ml-sm-auto col-lg-9 px-4" style="position: relative">
        <div class="view-wrapper">
          <!--<h1 class="page-title text-center">Elen Cards for CBSE Class 10</h1>
          <div class="home">
            <h2 class="page-sub-title">Remember your Lessons Easily</h2>
            <p class="page-description">Learn how Elen Cards can help you remember your lessons more easily.</p>
            <button class="home__btn">Play Video</button>
            <a pageScroll href="#tabs" class="home__scroll"></a>
          </div>-->
          <h3 class="user-stream">Projects</h3>
          <div class="tabs" id="tabs">
            <div class="tabs__header">
              <div class="btn-wrapper">
                <button *ngFor="let item of tabs"
                        [ngClass]="{'active': activeTab === item}"
                        (click)="onChangeTab(item)">{{item}}
                </button>
              </div>
            </div>
            <div class="tabs__content mt-5">
              <div class="row">
                <div [class.col-md-8]="authService.features$.value.isQuestionBased"
                     [class.col-md-4]="!authService.features$.value.isQuestionBased"
                     class="col-sm-12 col-lg-6 mt-3"
                     *ngFor="let project of activeTab === 'All' ? projects : userProjects; let k = index">
                  <div class="project"
                       (click)="openProject(project.id)">
                    <div class="project__img" *ngIf="user?.access?.status === ''">
                      <img [defaultImage]="'assets/lessons/images/placeholder.png'"
                           class="project__picture"
                           [lazyLoad]="onGetLessonImage(project.img)"
                           alt="">
                    </div>
                    <div [ngClass]="{'flex-column': project.projectSubtitle}">
                      <p class="project__title" [style.padding-bottom]="project.projectSubtitle ? '1rem' : '0'">
                        {{project.projectName}}
                      </p>
                      <p class="project__sub-title" *ngIf="project.projectSubtitle">{{project.projectSubtitle}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

</ng-container>
