import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {

  constructor() {
  }

  onShuffleArray(array: string[] | undefined) {
    if (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
    return array;
  }
}
