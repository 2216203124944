import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject, OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {filter, ReplaySubject, take, takeUntil} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {IProject} from "../../shared/models/projects.model";
import {AuthService} from "../../shared/services/auth.service";
import {IUser} from "../../shared/models/user.model";
import {DOCUMENT} from "@angular/common";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {fabric} from "fabric";
import {log} from "fabric/fabric-impl";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
  @ViewChild('htmlCanvas') htmlCanvas!: ElementRef;
  @ViewChild('canvasWrapper', {static: false}) canvasWrapper!: ElementRef;

  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    if(this.isMobile) {
      this.isPortrait = event.target.innerHeight < event.target.innerWidth;
      if(!this.isPortrait && !this.loadingStarted) {
        this.onLoad();
      }
    }
  }

  private config = {
    answer: {
      correct: {audio: '../../../assets/sound-effects/correct.mp3'},
      wrong: {audio: '../../../assets/sound-effects/wrong.mp3'}
    }
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public canvas!: fabric.Canvas;
  body: any;

  modalRef?: BsModalRef;

  isLoading = true;
  audioVolume = this.authService.user$.value.settings.audio === 'Enabled' ? 0.2 : 0;
  isPortrait = true;
  isMobile = false;
  isFullScreen = false;
  loadingStarted = false;
  isFinished = false;

  projectId = '';
  project!: IProject;
  user!: IUser;
  isNavigationHidden = false;
  isSuccessfullyCompleted = false;

  questionsCount = 0;

  elem: any;
  result: 'correct' | 'wrong' | null = null;
  isExitButtonDisabled = false;
  correctAnswers = 0;
  wrongAnswers = 0;

  pageIndex: number = 0;
  connectedItems = [];
  projectProgress = 0

  currentTime: any = 0;
  time: number = 0;

  size = {
    width: window.innerWidth,
    height: window.innerHeight - 149
  }

  highlightColorsPalette = {
    red: '#FF0000',
    green: '#00FF00',
    yellow: '#FFFF00',
    magenta: '#FF00FF',
    cyan: '#00FFFF',
    orange: '#FFA500',
    purple: '#800080',
    blue: '#0000FF',
    pink: '#FFC0CB',
    brown: '#A52A2A',
    gray: '#808080',
    black: '#000000',
    white: '#FFFFFF',
  }
  colorKeys = Object.keys(this.highlightColorsPalette);
  colorIndex = 0;

  constructor(
      @Inject(DOCUMENT) private document: any,
      private activatedRoute: ActivatedRoute,
      private db: AngularFirestore,
      private authService: AuthService,
      private modalService: BsModalService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.body =  document.querySelector('body')!;
    //this.body.classList.add('black');

    this.authService.getUser().pipe(filter(u => !u.initial)).pipe(take(1)).subscribe(user => {
      this.user = user;
      this.isMobile = window.innerWidth < 800;
      this.isPortrait = !(window.innerHeight < window.innerWidth);
      if (this.isMobile && !this.isPortrait) {
        this.onLoad();
      } else {
        this.onLoad();
      }
    })
  }

  ngOnDestroy() {
    this.body.classList.remove('black');
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  initCanvas() {
    this.canvas = new fabric.Canvas(this.htmlCanvas?.nativeElement, {
      backgroundColor: '#ffffff',
      hoverCursor: 'pointer',
      selection: true,
      //selectionBorderColor: '#1f305e',
      preserveObjectStacking: true,
      selectionColor: 'rgba(31, 48, 94, 0.2)',
      selectionLineWidth: 1,
      width: this.size.width,
      height: this.size.height,
      isDrawingMode: true,
      perPixelTargetFind: true,
      controlsAboveOverlay: true
    });
    this.canvas.isDrawingMode = false;

    this.project.state.forEach((state: any) => {
      state.background = '#1f305e';
    })

    // Resolving CANVAS CORS
    this.project.state[0].objects.forEach((item: any) => {
      if (item.src && !item.src.includes('https://satoshi-cors.herokuapp.com') && !item.src.includes('base64')) {
        item.src = `https://satoshi-cors.herokuapp.com/${item.src}`
      }
    })

    this.canvas.loadFromJSON(this.project.state[this.pageIndex], () => {
      let ratio = Math.ceil(((+this.htmlCanvas.nativeElement.style.height.replace('px', '')) / 1080) * 100) / 100;

      this.canvasWrapper.nativeElement.style.width = 1920*ratio + 'px';

      this.canvas.setZoom(ratio);

      //this.canvas.relativePan(new fabric.Point(this.size.width/2, 0));
      //console.log(this.size.width / 2)

      let objectsToAdd: any[] = [];
      const objectsToRemove: any[] = [];

      this.canvas._objects.forEach(object => {
        // @ts-ignore
        if (object.type === 'group' && !this.isSnippetObject(object) && object._objects.find(obj => this.isSnippetObject(obj))) {
          // @ts-ignore
          objectsToAdd = objectsToAdd.concat(object._objects);
          objectsToRemove.push(object);
        }
      })
      objectsToRemove.forEach(o => this.canvas.remove(o));
      objectsToAdd.forEach(o => this.canvas.add(o));

      this.canvas._objects.forEach((object: any) => {
        object.selectable = false;
        // @ts-ignore
        if (this.connectedItems.some((innerArr: any) => innerArr.includes(object.id)) && !this.isSnippetObject(object)) {
        object.set('shadow', new fabric.Shadow(
              {
                blur: 100,
                offsetX: 0,
                offsetY: 0,
                // @ts-ignore
                color: this.highlightColorsPalette[this.colorKeys[this.colorIndex < 13 ? this.colorIndex : this.colorIndex - 13]]
              }));
          this.colorIndex++;
        } else if (this.connectedItems.some((innerArr: any) => innerArr.includes(object.id)) && this.isSnippetObject(object)) {
          object.opacity = 0;
        }
      });

      this.canvas.renderAll();
      this.isLoading = false;
    });

    this.canvas.on('mouse:up', (event) => {
      if (event.target && !this.isSnippetObject(event.target) && this.connectedItems.length) {
        // @ts-ignore
        const selectedItemId = event.target.id;
        const allObjects = this.canvas.getObjects();
        if (selectedItemId) {
          this.connectedItems.forEach((array: string[]) => {
            if (array.includes(selectedItemId)) {
              array.forEach(id => {
                // @ts-ignore
                const obj: any = allObjects.find(object => object.id === id)!;
                if (obj && this.isSnippetObject(obj)) {
                  obj.opacity = 1
                } else if (obj) {
                  obj.shadow = '';
                }
              })

              //event.target!.shadow = '';
              this.playEffect('correct');
              this.projectProgress += Math.round(100/this.questionsCount);
              // @ts-ignore
              const index= this.connectedItems.findIndex(innerArr => innerArr.indexOf(event.target.id) !== -1);
              this.connectedItems.splice(index, 1);

              if (!this.connectedItems.length) {
                this.projectProgress = 100;
                this.isFinished = true;
                this.playEffectFinal();
                this.createConfetti();
              }
            }
          })
          /*this.canvas.renderAll();*/
        }
      }
      this.canvas.renderAll()
    });
  }

  onLoad() {
    this.loadingStarted = true;
    this.elem = document.documentElement;
    this.db
        .collection('projects')
        // @ts-ignore
        .doc(this.activatedRoute.snapshot['_urlSegment']['segments'][1]['path'])
        .get()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((resp: any) => {
          this.projectId = resp.id;
          this.project = resp.data() as IProject;
          this.connectedItems = JSON.parse(this.project.snippets);
          //this.connectedItemsInit = JSON.parse(this.project.snippets);
          this.questionsCount = this.connectedItems.length;

          /*this.project.state.forEach((page: any) => {
            console.log(page.objects)
            page.objects.forEach((object: any) => {
              object.selectable = false;
              if (this.isSnippetObject(object)) {
                object.opacity = 0;
              } else if (object.type === 'group') {
                object.objects.forEach((o: any) => {
                  page.objects.push(o);
                });
                const index = page.objects.findIndex((item: any) => item === object);
                page.objects.splice(index, 1);
              }
            })
            console.log(page.objects);
          })*/
          this.startTimer();
          //this.questionsCount = this.project.state.length;
          //this.onGetLesson();
          setTimeout(() => {
            this.initCanvas()
          }, 0)
          //this.initCanvas();
        });
  }

  navbarAnimation() {
    this.isNavigationHidden = !this.isNavigationHidden;
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    this.isFullScreen ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
  }

  onGetLessonImage() {
    return this.project.img;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm modal-dialog-centered'});
  }

  onExitConfirm() {
    this.isExitButtonDisabled = true;
      this.modalRef?.hide();
      this.router.navigate(['home']).then(() => {
        this.isExitButtonDisabled = false;
      });
  }

  onSkip() {
    this.onShowResult('wrong');
  }

  onShowResult(result: 'correct' | 'wrong') {
    result === 'correct' ? ++this.correctAnswers : ++this.wrongAnswers;
    /*let masteryScore = this.dlpcConfiguration.masteryScore;
    this.result = result;
    setTimeout(() => this.playEffect(result), 0)
    result === 'correct' ? ++this.correctAnswers : ++this.wrongAnswers;
    this.onUpdateMastery(result === 'correct' ? masteryScore : Math.abs(masteryScore) * -1)*/
  }

  onChangeSnippet(direction: 'prev' | 'next') {
    this.isLoading = true;
    this.pageIndex = direction === 'prev'
        ? this.pageIndex - 1
        : this.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 0;
      this.isLoading = false;
      return;
    } else if (this.pageIndex > this.project.state.length - 1) {
      this.pageIndex = this.project.state.length - 1;
      this.isLoading = false;
      return;
    }

    // Resolving CANVAS CORS
    this.project.state[this.pageIndex].objects.forEach((item: any) => {
      if (item.src && !item.src.includes('https://satoshi-cors.herokuapp.com') && !item.src.includes('base64')) {
        item.src = `https://satoshi-cors.herokuapp.com/${item.src}`
      }
    })

    this.canvas.loadFromJSON(this.project.state[this.pageIndex], () => {
      console.log('New page loaded');
      this.canvas._objects.forEach(object => {
        //console.log(object)
        if (object.type === 'group' && !this.isSnippetObject(object)) {
          // @ts-ignore
          object._objects!.forEach(obj => {
            this.canvas.add(obj);
          });
          this.canvas.remove(object);
        }
      })

      this.canvas._objects.forEach((object: any) => {
        object.selectable = false;
        // @ts-ignore
        if (this.connectedItems.some((innerArr: any) => innerArr.includes(object.id)) && !this.isSnippetObject(object)) {
          object.set('shadow', new fabric.Shadow(
              {
                blur: 100,
                offsetX: 0,
                offsetY: 0,
                // @ts-ignore
                color: this.highlightColorsPalette[this.colorKeys[this.colorIndex < 13 ? this.colorIndex : this.colorIndex - 13]]
              }));
          this.colorIndex++;
        } else if (this.isSnippetObject(object) && this.connectedItems.some((innerArr: any) => innerArr.includes(object.id))) {
          object.opacity = 0;
        }
      });

      this.canvas.renderAll();
      this.isLoading = false;
    });
  }

  onGoBackToHome() {
    this.router.navigate(['projects']).then();
  }

  isSnippetObject(object: any) {
    if (object.objects) {
      return object.objects?.length === 3
          && object?.type === 'group'
          && object.objects[0]?.type === 'rect'
          && object.objects[1]?.type === 'textbox'
          && object.objects[2]?.type === 'textbox'
    } else if (object._objects) {
      return object._objects?.length === 3
          && object?.type === 'group'
          && object._objects[0]?.type === 'rect'
          && object._objects[1]?.type === 'textbox'
          && object._objects[2]?.type === 'textbox'
    } else {
      return false;
    }
  }

  public playEffect(result: 'correct' | 'wrong') {
    let audio = new Audio();
    audio.src = this.config.answer[result].audio;
    audio.volume = this.audioVolume;
    audio.load();
    audio.play().then();
  }

  onResetProject() {
    this.projectProgress = 0;
    this.connectedItems = JSON.parse(this.project.snippets);
    this.pageIndex = 0;
    this.colorIndex = 0;
    this.time = 0;
    this.isFinished = false;

    this.canvas.loadFromJSON(this.project.state[this.pageIndex], () => {
      this.canvas._objects.forEach(object => {
        if (object.type === 'group' && !this.isSnippetObject(object)) {
          // @ts-ignore
          object._objects!.forEach(obj => {
            this.canvas.add(obj);
          });
          this.canvas.remove(object);
        }
      })

      this.canvas._objects.forEach((object: any) => {
        object.selectable = false;
        // @ts-ignore
        if (this.connectedItems.some((innerArr: any) => innerArr.includes(object.id)) && !this.isSnippetObject(object)) {
          object.set('shadow', new fabric.Shadow(
              {
                blur: 100,
                offsetX: 0,
                offsetY: 0,
                // @ts-ignore
                color: this.highlightColorsPalette[this.colorKeys[this.colorIndex < 13 ? this.colorIndex : this.colorIndex - 13]]
              }));
          this.colorIndex++;
        } else if (this.isSnippetObject(object)) {
          object.opacity = 0;
        }
      });

      this.canvas.renderAll();
    });
  }

  startTimer() {
    setInterval(() => {
      this.time++;

      if (this.time < 60) {
        this.currentTime = `${this.time} sec`;
      } else {
        const minutes = Math.floor(this.time / 60);
        const seconds = this.time % 60;
        this.currentTime = `${minutes} min ${seconds} sec`;
      }
    }, 1000);
  }

  goToEditor() {
    this.router.navigate(['/editor'], {queryParams: {id: this.projectId}})
  }

  createConfetti() {
    const canvas = document.getElementById("supportedCanvas")! as HTMLCanvasElement;
    canvas.style.zIndex = '1000';
    const ctx = canvas.getContext("2d")!;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let confetti: any[] = [];
    const confettiCount = 100;
    const gravity = 2;
    const terminalVelocity = 5;
    const drag = 0.075;
    const colors = [
      { front : 'red', back: 'darkred'},
      { front : 'green', back: 'darkgreen'},
      { front : 'blue', back: 'darkblue'},
      { front : 'yellow', back: 'darkyellow'},
      { front : 'orange', back: 'darkorange'},
      { front : 'pink', back: 'darkpink'},
      { front : 'purple', back: 'darkpurple'},
      { front : 'turquoise', back: 'darkturquoise'},
    ];

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    function randomRange(min: any, max: any) {
      return  Math.random() * (max - min) + min
    }

    function initConfetti() {
      for (let i = 0; i < confettiCount; i++) {
        confetti.push({
          color      : colors[Math.floor(randomRange(0, colors.length))],
          dimensions : {
            x: randomRange(10, 20),
            y: randomRange(10, 30),
          },
          position   : {
            x: randomRange(0, canvas.width),
            y: canvas.height - 1,
          },
          rotation   : randomRange(0, 2 * Math.PI),
          scale      : {
            x: 1,
            y: 1,
          },
          velocity   : {
            x: randomRange(-25, 25),
            y: randomRange(0, -50),
          },
        });
      }
    }

    function render() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      confetti.forEach((confetto, index) => {
        let width = (confetto.dimensions.x * confetto.scale.x);
        let height = (confetto.dimensions.y * confetto.scale.y);

        ctx.translate(confetto.position.x, confetto.position.y);
        ctx.rotate(confetto.rotation);

        confetto.velocity.x -= confetto.velocity.x * drag;
        confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
        confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

        confetto.position.x += confetto.velocity.x;
        confetto.position.y += confetto.velocity.y;

        if (confetto.position.y >= canvas.height && confetti.length > 0) confetti.splice(index, 1);

        if (confetto.position.x > canvas.width) confetto.position.x = 0;
        if (confetto.position.x < 0) confetto.position.x = canvas.width;

        confetto.scale.y = Math.cos(confetto.position.y * 0.1);
        ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

        ctx.fillRect(-width / 2, -height / 2, width, height);

        // Reset transform matrix
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      });

      /*if (confetti.length <= 10) {
          initConfetti();
      }*/
      if (confetti.length <= 10) {
        canvas.style.zIndex = '-1';
      } else {
        window.requestAnimationFrame(render);
      }
    }

    initConfetti();
    render();

    window.addEventListener('resize', function () {
      resizeCanvas();
    });

    /*window.addEventListener('click', function() {
      initConfetti();
    });*/
  }

  public playEffectFinal() {
    let audio = new Audio();
    audio.src = 'assets/sound-effects/tada-fanfare-f.mp3';
    audio.volume = 0.2;
    audio.autoplay = true;
    audio.load();
    audio.play().then();
  }
}
