<app-header></app-header>
<div class="displayTable">
  <div class="text" style="color: white;font-weight: 500;line-height: 1.8rem;padding-top: 60px;">

    <div class="row" >

      <div class="row" style="margin-top: 0px;">
        <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;margin-bottom: 0px;">Vocabulary creating a plot twist in your Spanish-learning story?</h5>
        <h2 style="font-weight: 600;margin-bottom: 0px;">Master 300 Spanish words in 30 days</h2>
        <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;">with Homi's silly stories</h5>
      </div>

      <div style="margin-top: 30px;">
        <img src="assets/img/silly-stories.png" alt="">
      </div>


      <div class="row" style="margin-top: 60px;">
        <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;font-weight: 600;margin-bottom: 10px;">The quickest and funnest Spanish vocabulary learning ever!
        </h5>
        <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;font-weight: 300;">Most suited for visual learners.</h5>
      </div>
      
      
      <hr style="margin: 60px 0;">

      <div class="row" style="position: relative;">
        <div class="col-7" style="text-align: left;">
          <h4 style="font-weight: 300;margin-top: 15%;">
            <span style="font-weight: 700;">Spanish Words are embarking on exciting adventures.</span>
          </h4>
          <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;text-align: left;">
            Befriend them as you help them navigate difficult obstacles in <span style="font-weight: 700;">interactive stories</span>. 
          </h5>
        </div>
        <div class="col-5">
          <img src="assets/img/spanish-homi-activity-1.jpg" alt="" style="width: 100%;border-radius: 50px; box-shadow: 2px 3px 15px #212c46;">
        </div>        
      </div>


      <hr style="margin: 60px 0;">


      <div class="row" style="position: relative;">
        <div class="col-6">
          <img src="assets/img/spanish-quantum-riddle.png" alt="" style="width: 100%;">
        </div>        
        <div class="col-6" style="text-align: left;">
          <h4 style="font-weight: 300;">
            You may forget a word you read; <span style="font-weight: 700;">you won't forget one that made you a riddle!</span>
          </h4>
          <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;text-align: left;">
            Meet more words as strange characters on the way. <span style="font-weight: 700;">They will battle you</span> with wit, riddles and challenges to rhyme and make phonetic associations.
          </h5>
        </div>
      </div>


      <hr style="margin: 60px 0;">

      <div class="row" style="position: relative;">
        <div class="col-6" style="text-align: left;">
          <h4 style="font-weight: 600;">
            If it made you smile, you will remember it.
          </h4>
          <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;text-align: left;">
            Memorizing happens when you engage with the subject triggering a memory process called <span style="font-weight: 700;">encoding</span>. Our story mnemonics are designed to achieve that for you.
          </h5>
        </div>
        <div class="col-6">
          <img src="assets/img/spanish-gracias.png" alt="" style="width: 100%;">
        </div>        
      </div>


      <hr style="margin: 60px 0;">

      <div class="row">
        <h4 style="font-weight: 600;">The Method: Story Mnemonics</h4>
        <h5 style="font-weight: 300;line-height: 1.8rem;color: #ddd;"><span style="font-weight: 700;">Age-old</span> and <span style="font-weight: 700;">scientifically-certified</span> memory techniques called <span style="font-weight: 700;">mnemonics</span> are at the root of it.</h5>
      </div>

      <div class="row">
        <div class="col-4">
          <img src="assets/img/spanish-medical.png" alt="" style="width: 160px;">
          <h5 style="font-weight: 600;margin: 15px 0 5px;">Medical and Nursing students use them</h5>
          <p style="color: #ddd;line-height: 1.4rem;margin-top: 10px;">Millions of medical students rely on mnemonics to master difficult medical vocabulary.</p>
        </div>
        <div class="col-4">
          <img src="assets/img/spanish-aristotle.png" alt="" style="width: 90px;">
          <h5 style="font-weight: 600;margin: 15px 0 5px;">Ancient Greek senators</h5>
          <p style="color: #ddd;line-height: 1.4rem;margin-top: 10px;">used them to master the craft of oratory. The Method of Loci has survived from that time.
          </p>
        </div>
        <div class="col-4">
          <img src="assets/img/spanish-memory-champion.png" alt="" style="width: 190px;">
          <h5 style="font-weight: 600;margin: 15px 0 5px;">Memory championship winners</h5>
          <p style="color: #ddd;line-height: 1.4rem;margin-top: 10px;">use them today to achieve jaw-dropping memory feats.</p>
        </div>
      </div>


      <hr style="margin: 60px 0;">

      <div style="text-align: left;">
        <h4 style="font-weight: 600;">If it’s so effective, why haven’t I heard of this technique before?</h4>
        <h5 style="font-weight: 300;line-height: 1.8rem;text-align: left;color: #ddd;">The technique has been around thousands of years and currently employed by millions of people. Homi is the first to apply it to <span style="font-weight: 700;">vocabulary learning</span>.
        </h5>

        <h4 style="font-weight: 600;">Is it only for vocabulary?</h4>
        <h5 style="font-weight: 300;line-height: 1.8rem;text-align: left;color: #ddd;">This is a memory technique and best suited to memorize words and phrases. 
        </h5>
      </div>

      <hr style="margin: 60px 0;">
      
      <div class="row" style="margin: 0 0 60px;">
        <img src="assets/img/spanish-ship.png" alt="" style="width: 150px;margin: 0 auto;">
        <h2 style="font-weight: 600;margin-top: 15px;">Ready to set sail on this Spanish-learning <br>adventure of silliness?</h2>
      </div>


    </div>
  </div>
  
  
  <div class="displayTableCell">
    
    <div class="authBlock mt-5">
      <h4 style="color:white;font-weight: 500;">Sign up to begin</h4>
      <hr>

      <!-- Continue with Google -->
      <div class="formGroup">
        <button
          type="button"
          class="btn googleBtn"
          (click)="authService.GoogleAuth('sign-up')"
        >
          <i class="fab fa-google-plus-g d-none"></i>
          Sign up with Google
        </button>
      </div>

      <div class="formGroup d-none">
        <button
                type="button"
                class="btn googleBtn"
                (click)="authService.FacebookAuth('sign-up')"
        >
          <i class="fab fa-google-plus-g d-none"></i>
          Sign up with Facebook
        </button>
      </div>

      <div class="formGroup">
        <span class="or"><span class="orInner">Or</span></span>
      </div>


      <h6 style="font-weight: 500;">Sign up with email</h6>

      <div class="formGroup d-none">
        <input
          type="email"
          class="formControl"
          placeholder="First Name"
          #userFirstName
          required
        />
      </div>

      <div class="formGroup d-none">
        <input
          type="email"
          class="formControl"
          placeholder="Last Name"
          #userLastName
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="email"
          class="formControl"
          placeholder="email"
          #userEmail
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="password"
          class="formControl"
          placeholder="Password"
          #userPwd
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="button"
          class="btn btnPrimary"
          value="Sign Up"
          (click)="authService.SignUp(userFirstName.value, userLastName.value, userEmail.value, userPwd.value)"
        />
      </div>
    </div>

    <div class="redirectToLogin">
      <span
        >Already have an account?
        <span class="redirect" routerLink="/sign-in">Sign in</span></span
      >
    </div>
  </div>
</div>
