<app-loader [isBlurred]="!isFirst" *ngIf="isLoading"></app-loader>
<app-header></app-header>
<div class="container-fluid" *ngIf="user">
  <div class="row">
    <div class="col-md-3 d-md-block sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="view-wrapper">
        <h1 class="page-title">Settings</h1>

        <ng-container *ngIf="user">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-4">
              <div class="user-info" >
                <!--<p>User ID: <strong>{{user.uid}}</strong></p>-->
                <label class="hoverable" for="fileInput">
                  <img [src]="user.photoURL ? user.photoURL : '../../assets/img/homi-logo.png'">
                  <div class="hover-text">Choose New Photo</div>
                  <div class="background"></div>
                </label>
                <br/>
                <input id="fileInput" type='file' (change)="onSelectPhoto($event)">
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="user-info__item">
                <label>
                  First Name:
                  <input type="text"
                         #userFirstName
                         [value]="user.firstName"
                         (change)="user.firstName = userFirstName.value"
                         (keyup)="isInformationUpdated = true">
                </label>
              </div>
              <div class="user-info__item">
                <label>
                  Last Name:
                  <input type="text"
                         #userLastName
                         [value]="user.lastName"
                         (change)="user.lastName = userLastName.value"
                         (keyup)="isInformationUpdated = true">
                </label>
              </div>
              <div class="user-info__item">
                <label>
                  Email:
                  <input type="text"
                         #userEmail
                         [value]="user.email"
                         (change)="user.email = userEmail.value"
                         (keyup)="isInformationUpdated = true">
                </label> <!--{{user.emailVerified}}-->
              </div>
              <div class="user-info__item">
                <!--TODO: UserName should be unique. This check should be added-->
                <label>
                  Username:
                  <input type="text"
                         #userName
                         [value]="user.userName ? user.userName : 'N/A'"
                         (change)="user.userName = userName.value"
                         (keyup)="isInformationUpdated = true">
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="user-info" >
                <!--<p>User ID: <strong>{{user.uid}}</strong></p>-->
                <div class="user-info__item">
                  <label>
                    Timezone
                    <span class="select-wrapper">
                      <img src="../../assets/icons/chevron-down.svg">
                      <select #userTimezone
                              (change)="user.settings.timezone = userTimezone.value; isInformationUpdated = true">
                        <option *ngFor="let item of timezonesList"
                                [selected]="user.settings?.timezone === item.value"
                                [value]="item.value">{{item.text}}
                        </option>
                      </select>
                    </span>
                  </label>
                </div>
                <div class="user-info__item">
                  <label>
                    Sound effects
                    <span class="select-wrapper">
                      <img src="../../assets/icons/chevron-down.svg">
                      <select #userAudio
                              (change)="user.settings.audio = userAudio.value; isInformationUpdated = true">
                        <option value="Enabled"
                                [selected]="user.settings?.audio === 'Enabled'">
                          Enabled
                        </option>
                        <option value="Only important"
                                [selected]="user.settings?.audio === 'Only important'">
                          Only important
                        </option>
                        <option value="Disabled"
                                [selected]="user.settings?.audio === 'Disabled'">
                          Disabled
                        </option>
                      </select>
                    </span>
                  </label>
                </div>

                <div class="user-info__item">
                  <label>
                    Data collection
                    <span class="select-wrapper">
                      <img src="../../assets/icons/chevron-down.svg">
                      <select #userData
                              (change)="user.settings.dataCollecting = userData.value === 'true'; isInformationUpdated = true">
                        <option value="true"
                                [selected]="user.settings?.dataCollecting">Allow</option>
                        <option value="false"
                                [selected]="!user.settings?.dataCollecting">Disallow</option>
                      </select>
                    </span>
                  </label>
                </div>
              </div>
              <!--<div class="user-info__item">
                <label>
                  Leaderboard participation
                  <span class="select-wrapper">
                      <img src="../../assets/icons/chevron-down.svg">
                    <select #userLeaderboard
                            (change)="user.settings.leaderboard = userLeaderboard.value === 'true'; isInformationUpdated = true">
                      <option value="true"
                              [selected]="user.settings?.leaderboard">Enabled</option>
                      <option value="false"
                              [selected]="!user.settings?.leaderboard">Disabled</option>
                    </select>
                  </span>
                </label>
              </div>
              <div class="user-info__item" *ngIf="userLeaderboard.value === 'true'">
                <label>
                  Real name on the Leaderboard
                  <span class="select-wrapper">
                      <img src="../../assets/icons/chevron-down.svg">
                    <select #userLeaderboardName
                            (change)="user.settings.leaderboardName = userLeaderboardName.value === 'true'; isInformationUpdated = true">
                      <option value="true"
                              [selected]="user.settings?.leaderboardName">Show</option>
                      <option value="false"
                              [selected]="!user.settings?.leaderboardName">Hide</option>
                    </select>
                  </span>
                </label>
              </div>-->
            </div>
          </div>
          <p class="error" *ngIf="error">Something went wrong, please try later...</p>
          <p class="success" *ngIf="success">Your information was successfully updated!</p>
          <div class="changes" *ngIf="isInformationUpdated">
            <p class="changes__title">Information has been updated</p>
            <p class="changes__text">Please save your changes before leaving this page</p>
            <div class="row">
              <div class="col">
                <button class="btn btn_remove" (click)="onCancel()">Cancel</button>
                <button class="btn btn_select" (click)="onSave()">Save</button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
