import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SignInComponent} from './components/authentication/sign-in/sign-in.component';
import {SignUpComponent} from './components/authentication/sign-up/sign-up.component';
import {ForgotPasswordComponent} from './components/authentication/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from './components/authentication/verify-email/verify-email.component';
import {AuthGuard} from './shared/guard/auth.guard';
import {HelpComponent} from './components/help/help.component';
import {HomeComponent} from './components/home/home.component';
import {LeaderboardComponent} from './components/leaderboard/leaderboard.component';
import {SettingsComponent} from './components/settings/settings.component';
import {AchievementsComponent} from './components/achievements/achievements.component';
import {LessonComponent} from './components/lesson/lesson.component';
import {LoginGuard} from "./shared/guard/login.guard";
import {AdminComponent} from "./admin/admin.component";
import {AdminGuard} from "./shared/guard/admin.guard";
import {PricingComponent} from "./components/pricing/pricing.component";
import {BillingComponent} from "./components/billing/billing.component";
import {EditorComponent} from "./components/editor/editor.component";
import {ProjectsComponent} from "./components/projects/projects.component";
import {ProjectComponent} from "./components/project/project.component";
import {LeavePageGuard} from "./shared/guard/leave-page.guard";
import {OnboardingComponent} from "./components/onboarding/onboarding.component";

const routes: Routes = [
  {path: '', redirectTo: '/register-user', pathMatch: 'full'},
  {path: 'sign-in', component: SignInComponent, canActivate: [LoginGuard]},
  {path: 'register-user', component: SignUpComponent, canActivate: [LoginGuard]},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LoginGuard]},
  {path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [LoginGuard]},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'leaderboard', component: LeaderboardComponent, canActivate: [AuthGuard]},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  {path: 'help', component: HelpComponent, canActivate: [AuthGuard]},
  {path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard]},
  {path: 'billing', component: BillingComponent, canActivate: [AuthGuard]},
  {path: 'editor', component: EditorComponent, canActivate: [AuthGuard], canDeactivate: [LeavePageGuard]},
  {path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard]},
  {path: 'project', component: ProjectComponent, canActivate: [AuthGuard], children: [
      {path: '', component: ProjectComponent},
      {path: ':id', component: ProjectComponent},
    ]},
  {path: 'pricing', component: PricingComponent, canActivate: [AuthGuard]},
  {path: 'achievements', component: AchievementsComponent, canActivate: [AuthGuard]},
  {path: 'lesson', component: LessonComponent, canActivate: [AuthGuard], children: [
      {path: '', component: LessonComponent},
      {path: ':id', component: LessonComponent},
  ]},
  {path: 'admin', component: AdminComponent, canActivate: [AdminGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, anchorScrolling: 'enabled'})],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
