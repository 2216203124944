import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {SignInComponent} from './components/authentication/sign-in/sign-in.component';
import {SignUpComponent} from './components/authentication/sign-up/sign-up.component';
import {ForgotPasswordComponent} from './components/authentication/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from './components/authentication/verify-email/verify-email.component';
import {HelpComponent} from './components/help/help.component';
import {BillingComponent} from './components/billing/billing.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthService} from './shared/services/auth.service';
import {AchievementsComponent} from './components/achievements/achievements.component';
import {SettingsComponent} from './components/settings/settings.component';
import {HomeComponent} from './components/home/home.component';
import {LeaderboardComponent} from './components/leaderboard/leaderboard.component';
import {HeaderComponent} from './components/structural/header/header.component';
import {FooterComponent} from './components/structural/footer/footer.component';
import {SidebarComponent} from './components/structural/sidebar/sidebar.component';
import {LessonComponent} from './components/lesson/lesson.component';
import {LearnComponent} from './components/lesson/learn/learn.component';
import {WordBankComponent} from './components/lesson/word-bank/word-bank.component';
import {FillComponent} from './components/lesson/fill/fill.component';
import {WriteComponent} from './components/lesson/write/write.component';
import {SpeakComponent} from './components/lesson/speak/speak.component';
import {FormsModule} from '@angular/forms';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {LoaderComponent} from './components/structural/loader/loader.component';
import {AdminComponent} from './admin/admin.component';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {ModalModule} from 'ngx-bootstrap/modal';
import {GsPlayerModule} from 'gs-player';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgAudioRecorderModule} from 'ng-audio-recorder';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {EllipsisScrollDirective} from './shared/directives/ellipsis-scroll.directive';
import {PricingComponent} from './components/pricing/pricing.component';
import {PricingCardsComponent} from './components/pricing-cards/pricing-cards.component';
import {NotLessonComponent} from './components/lesson/not-lesson/not-lesson.component';
import { MobileDisablerComponent } from './components/structural/mobile-disabler/mobile-disabler.component';
import { EditorComponent } from './components/editor/editor.component';
import {NgxFileDropModule} from "ngx-file-drop";
import { DndDirective } from './shared/directives/dnd.directive';
import 'fabric-history';
import {ColorPickerModule} from "ngx-color-picker";
import {Storage, StorageModule} from "@angular/fire/storage";
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectComponent } from './components/project/project.component';
import {LeavePageGuard} from "./shared/guard/leave-page.guard";
import { LeavePageModalComponent } from './shared/modals/leave-page-modal/leave-page-modal.component';
import {OpenAiService} from "./shared/services/open-ai.service";
import { AiMessageContainerComponent } from './components/editor/ai-message-container/ai-message-container.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import {QuillModule} from "ngx-quill";
import {environment} from "../environments/environment.prod";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        VerifyEmailComponent,
        HelpComponent,
        BillingComponent,
        AchievementsComponent,
        SettingsComponent,
        HomeComponent,
        LeaderboardComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        LessonComponent,
        LearnComponent,
        NotLessonComponent,
        WordBankComponent,
        FillComponent,
        WriteComponent,
        SpeakComponent,
        LoaderComponent,
        AdminComponent,
        EllipsisScrollDirective,
        PricingComponent,
        PricingCardsComponent,
        MobileDisablerComponent,
        EditorComponent,
        DndDirective,
        ProjectsComponent,
        ProjectComponent,
        LeavePageModalComponent,
        AiMessageContainerComponent,
        OnboardingComponent
    ],
    imports: [
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebase),
        LottieModule.forRoot({player: playerFactory}),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        AppRoutingModule,
        FormsModule,
        NgxJsonViewerModule,
        ModalModule.forRoot(),
        GsPlayerModule,
        LazyLoadImageModule,
        NgAudioRecorderModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        NgxFileDropModule,
        ColorPickerModule,
        StorageModule,
        QuillModule.forRoot(),
        BsDropdownModule
    ],
    providers: [
        AuthService,
        HttpClientModule,
        EllipsisScrollDirective,
        LeavePageGuard,
        OpenAiService
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
}
