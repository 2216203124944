<app-loader *ngIf="isLoading" [isBlurred]="true"></app-loader>
<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div *ngIf="user" class="col-md-3 d-md-block sidebar">
      <div class="sidebar-sticky">
        <ul class="nav flex-column">
          <ng-container *ngFor="let item of navigation">
            <li class="nav-item" *ngIf="item.isVisible" [ngClass]="{'active': activeView === item.view}">
              <a class="nav-link" (click)="onChangeView(item.view)">
                <img class="nav-link__icon" [src]="'assets/icons/navigation/' + item.img + '.png'" alt="">
                {{item.title}}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-9 px-4" style="position: relative">
      <div class="view-wrapper">
        <ng-container *ngIf="activeView === 'settings'">
          <h2 class="page-sub-title">Feature Switcher</h2>
          <p class="page-description">
            Here you can activate/deactivate pages by pressing on toggle:
          </p>
          <ng-container *ngIf="features && getObjectKeys(features).length > 0"></ng-container>
          <p class="setting"
             *ngFor="let item of getObjectKeys(features)"
             [ngClass]="{'off': !isFeatureActivated(item)}">
             {{item}} - <span (click)="onToggleFeature(item)">{{isFeatureActivated(item) ? 'on' : 'off'}}</span>
          </p>
        </ng-container>
        <ng-container *ngIf="activeView === 'users'">
          <div class="header">
            <h2 class="page-sub-title">Users List</h2>
            <div class="download" (click)="downloadUserInfo()"></div>
          </div>
          <p class="page-description">
            We can add some functionality for User Information updating/editing in future
          </p>
          <table class="table" *ngIf="users && users.length > 0">
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">ID</th>
              <th scope="col">Action</th>
              <th scope="col">SA</th>
              <th scope="col">Editor</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of users">
                <td>{{user.displayName}}</td>
                <td>{{user.email}}</td>
                <td>{{user.uid}}</td>
                <td><div class="download download-small" (click)="downloadUserInfo(user)"></div></td>
                <td>
                  <div class="form-check form-switch">
                    <input (change)="onAccessChange($event, user)"
                           [checked]="user.specialAccess"
                           class="form-check-input"
                           type="checkbox"
                           role="switch"
                           id="specialAccess"
                    >
                  </div>
                </td>
                <td>
                  <div class="form-check form-switch">
                    <input (change)="onEditorRoleChange($event, user)"
                           [checked]="user.isEditor"
                           class="form-check-input"
                           type="checkbox"
                           role="switch"
                           id="a=editorRole"
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="activeView === 'lessons'">
          <h2 class="page-sub-title">Lessons</h2>
          <p class="page-description">
            You can upload new lesson here by pressing <strong>Select File</strong> button. <br>
            The file should be in a <strong>JSON</strong> format and should have the same structure as an example in a <strong>ReadMe</strong> file.
          </p>
          <input style="display: none"
                 type="file" (change)="onFileChanged($event)"
                 #fileInput>

          <div *ngIf="lessonResult" class="json-viewer">
            <ngx-json-viewer [json]="lessonResult" [expanded]="false"></ngx-json-viewer>
          </div>

          <button class="btn btn_select" *ngIf="!lessonResult"
                  (click)="fileInput.click()">Select File
          </button>
          <ng-container *ngIf="lessonResult">
            <button class="btn btn_remove" (click)="onRemoveFile()">Remove File</button>
            <button class="btn btn_upload" (click)="onUpload()">Add To FireBase</button>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="activeView === 'files'">
          <h2 class="page-sub-title">Firebase Files</h2>
          <p class="page-description">
            <strong>Lesson Images</strong> and <strong>lesson narrations</strong> are stored in Firebase Storage and
            they don't have proper caching settings, they are not caching by default, so we should make them cached...
            <br>Just press the button below and we will update cache settings for all the images and narrations in use automatically
          </p>
          <button class="btn btn btn_select" *ngIf="!updatingStarted"
                  (click)="onUpdateFilesCacheSettings()">Start Updating</button>
          <div class="updating-progress"
               *ngIf="updatingStarted"
               [innerHTML]="cacheUpdateProgress"></div>

        </ng-container>
        <ng-container *ngIf="activeView === 'library'">
          <h2 class="page-sub-title">Library</h2>
          <div class="library">
            <div class="row">
              <div class="col-4 d-flex flex-column justify-content-between">
                <div class="library__item">
                  <label>
                    Name:
                    <input type="text"
                           #name
                           [value]="libraryImage.name"
                           (change)="libraryImage.name = name.value"
                    >
                  </label>
                </div>
                <div class="library__item">
                  <label>
                    Category:
                  </label>
                  <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-outline btn-outline-light library__button dropdown-toggle" aria-controls="dropdown-animated">
                      {{libraryImage.category ? libraryImage.category : 'Select category'}} <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
                      <li class="dropdown-item" (click)="onSelectCategory('')" role="menuitem">None</li>
                      <li class="dropdown-item" (click)="onSelectCategory('backgrounds')" role="menuitem">Backgrounds</li>
                      <li class="dropdown-item" (click)="onSelectCategory('graphics')" role="menuitem">Graphics</li>
                    </ul>
                  </div>
                </div>
                <div class="library__item">
                  <label>
                    Keyword:
                    <input type="text"
                           #keywordField
                           [value]="keyword"
                           (change)="keyword = keywordField.value"
                           (keyup.enter)="addKeyword()"
                    >
                    <button (click)="addKeyword()">+</button>
                  </label>
                </div>

                <div *ngIf="libraryImage.keywords.length" class="library__keywords">
                  <div *ngFor="let item of libraryImage.keywords; let i = index" class="library__keyword">
                    <div>{{item}}</div>
                    <div class="delete" (click)="onDeleteKeyword(i)">+<!--<img src="assets/icons/trash.svg" alt="delete">--></div>
                  </div>
                </div>

                <div class="library__item">
                  <label class="textarea">
                    Description:
                    <textarea
                           #description
                           [value]="libraryImage.description"
                           (change)="libraryImage.description = description.value"
                           (keydown)="onCheckHeight()"
                    ></textarea>
                  </label>
                </div>

                <div class="d-flex flex-column gap-2">
                  <button (click)="onSaveNewImage()" class="btn btn_select w-100" type="button">{{imageUpdateIndex !== null ? 'Update image' : 'Add new image'}}</button>
                  <button *ngIf="imageUpdateIndex !== null" (click)="removeImageSelection()" class="btn btn_remove w-100" type="button">Reset</button>
                </div>
              </div>
              <div class="col-8 library__wrapper">
                <ng-container *ngIf="!previewImage">
                  <div class="dragAndDrop" appDnd (fileDropped)="onFileDropped($event)">
                    <div class="text">
                      Drag & drop your files here or
                    </div>
                    <input #file type="file" accept="image/*" (change)="downLoadFile($event)">
                    <button (click)="file.click()" class="button" type="button">Browse Files</button>
                  </div>
                </ng-container>
                <ng-container *ngIf="previewImage">
                  <div class="library__preview" [style.background-image]="'url(' + previewImage + ')'">
                    <img src="assets/icons/placeholder.svg" alt="placeholder">
                  </div>
                  <div class="delete" (click)="previewImage = ''"><img src="assets/icons/plus.png" alt="delete"></div>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container *ngIf="libraryImages.length || libraryImagesBackgrounds.length">
            <div class="btn-group mb-3" dropdown>
              <button id="button-animated" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-animated">
                {{imageCategory}} <span class="caret"></span>
              </button>
              <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
                <li class="dropdown-item" (click)="onFilterImages('backgrounds')" role="menuitem">Backgrounds</li>
                <li class="dropdown-item" (click)="onFilterImages('graphics')" role="menuitem">Graphics</li>
              </ul>
            </div>
          </ng-container>

          <div class="images">
            <ng-container *ngIf="imageCategory === 'graphics'">
              <div *ngFor="let image of libraryImages; let i = index"
                   [style.background-image]="'url('+ image.url +')'"
                   class="images__item"
                   (click)="onSelectImage(image, i)"
              >
                <img src="assets/icons/placeholder.svg" alt="placeholder">
                <div class="delete" (click)="openConfirmationToDelete($event, i)"><img src="assets/icons/plus.png" alt="delete"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="imageCategory === 'backgrounds'">
              <div *ngFor="let image of libraryImagesBackgrounds; let i = index"
                   [style.background-image]="'url('+ image.url +')'"
                   class="images__item"
                   (click)="onSelectImage(image, i)"
              >
                <img src="assets/icons/placeholder.svg" alt="placeholder">
                <div class="delete" (click)="openConfirmationToDelete($event, i)"><img src="assets/icons/plus.png" alt="delete"></div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="(imageCategory === 'graphics' && !libraryImages.length) || (imageCategory === 'backgrounds' && !libraryImagesBackgrounds.length)">
            <div class="library__empty">
              <img src="assets/icons/empty-space.png" alt="empty">
              <h4>No images found</h4>
            </div>
          </ng-container>

        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<ng-template #imageDeletionConfirm>
  <div class="modal-body text-center">
    <p class="modal__title">You sure You want to delete this image?</p>
    <div>
      <button type="button" class="btn btn-primary" (click)="deleteImage()">Delete</button>
      <button type="button" class="btn danger" (click)="modalRef?.hide()">Cancel</button>
    </div>
  </div>
</ng-template>
