<img alt="" src="assets/img/homi-logo.png" style="width:40px;">
<small [innerHTML]="item.instruction"></small>
<p class="color-composition" [innerHTML]="item.shortCue"></p>

<div class="recording">
  <p class="loading" *ngIf="isProcessing">
    Processing in progress
  </p>
  <p class="ready" *ngIf="audio && !isProcessing">
    Your answer: {{text}}
  </p>
  <button class="recording__btn recording__btn_start"
          *ngIf="!isRecording && !isProcessing"
          (click)="startRecording()">
    <i class="fa fa-microphone"></i>
    {{audio ? 'New' : 'Start'}} Recording
  </button>
  <button class="recording__btn recording__btn_stop"
          *ngIf="isRecording && !audio"
          (click)="stopRecording()">
    <i class="fa fa-microphone"></i>
    Stop Recording
  </button>
</div>
