import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import firebase from "firebase/compat/app";
import {environment} from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})

export class LoginGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): Promise<boolean> {
    return new Promise(async (resolve) => {
      firebase.initializeApp(environment.firebase);
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          this.router.navigate(['home']).then();
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
}

