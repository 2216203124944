import { Component, OnInit } from '@angular/core';
import { IAchievement } from '../../shared/models/achievement.model';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {

  achievements: IAchievement[] = [
    { title: 'Self Starter', description: '2% users have this badge', img: '1.png', received: false },
    { title: 'Self Starter', description: '2% users have this badge', img: '2.png', received: true },
    { title: 'Self Starter', description: '2% users have this badge', img: '3.png', received: false },
    { title: 'Self Starter', description: '2% users have this badge', img: '4.png', received: false },
    { title: 'Self Starter', description: '2% users have this badge', img: '5.png', received: false },
    { title: 'Self Starter', description: '2% users have this badge', img: '6.png', received: true },
    { title: 'Self Starter', description: '2% users have this badge', img: '7.png', received: true },
    { title: 'Self Starter', description: '2% users have this badge', img: '8.png', received: false }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
